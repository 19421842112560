import React, { Component, Fragment } from 'react'
import {
  NavLink,
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import Dashboard from './Dashboard'
import Guest from './Guests/Guest'
import LoginPage from './LoginPage'
import Reports from './Reports/Reports'
import MasterDetail from './MasterDetail/MasterDetails'
import AddNewUser from './AddNewUser'
import AddNewGuest from './Guests/AddGuest/AddNewGuestPage'
import EditGuest from './Guests/EditGuest/EditGuestPage'
import Category from './Category/Category'
import VehiclePooling from './VehiclePooling/VehiclePooling'
import Group from './Groups/Group'
import Hotel from './Hotels/Hotel'
import Messages from './Messages/Msgs'
import ArrivalDetails from './Reports/ArrivalDetails'
import DepartureDetails from './Reports/DepartureDetails'
import HotelDetails from './Reports/HotelDetails'
import VehicleDetails from './Reports/VehicleDetails';
import Transport from './Transports/Transport'
import PageNotFound from './PageNotFound'
import LogoutPage from './LogoutPage'
import CSVImport from './Imports'
import { AUTH_TOKEN } from '../constant'
import { isTokenExpired } from '../helper/jwtHelper'

import Logo from '../assets/icon.png'

import {
  Alignment,
  Button,
  Classes,
  H5,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Menu, MenuDivider, MenuItem, Popover, Position
} from "@blueprintjs/core";

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
  return token ? (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  ) : (
      <Redirect to="/login" />
    )
}

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 30,
    height: 30,
  },
  logo: {
    display: 'inline-flex'
  },
  logoText: {
    marginTop: 15
  }
};

class RootContainer extends Component {
  constructor(props) {
    super(props)
    this.refreshTokenFn = this.refreshTokenFn.bind(this)

    this.state = {
      token: props.token,
      isAdmin: props.isAdmin
    }
  }

  refreshTokenFn(data = {}) {
    const token = data.AUTH_TOKEN

    if (token) {
      localStorage.setItem(AUTH_TOKEN, token)
    } else {
      localStorage.removeItem(AUTH_TOKEN)
    }

    this.setState({
      token: data.AUTH_TOKEN,
    })
  }

  bootStrapData() {
    try {
      const token = localStorage.getItem(AUTH_TOKEN)
      if (token !== null && token !== undefined) {
        const expired = isTokenExpired(token)
        if (!expired) {
          this.setState({ token: token })
        } else {
          localStorage.removeItem(AUTH_TOKEN)
          this.setState({ token: null })
        }
      }
    } catch (e) {
      console.log('')
    }
  }

  //verify localStorage check
  componentDidMount() {
    this.bootStrapData()
  }

  render() {
    return (
      <Router>
        <Fragment>
          {this.renderNavBar()}
          {this.renderRoute()}
        </Fragment>
      </Router>
    )
  }

  renderNavBar() {
    const { classes } = this.props;
    return (
      this.props.data &&
      this.props.data.me &&
      this.props.data.me.email &&
      this.state.token && (
        <Navbar className="navbar">
          <NavbarGroup>
            <NavbarHeading className={classes.logo}>
              <img src={Logo} className={classNames(classes.avatar, classes.bigAvatar)} />
            </NavbarHeading>
            <NavbarDivider />
            <NavLink exact={true} to="/">
              <Button className={Classes.MINIMAL} intent="primary" icon="dashboard" text="Dashboard" />
            </NavLink>
            <NavLink exact={true} to="/guest">
              <Button className={Classes.MINIMAL} intent="primary" icon="people" text="Guests" />
            </NavLink>
            <NavLink exact={true} to="/vehiclePooling">
              <Button className={Classes.MINIMAL} intent="primary" icon="drive-time" text="Cab Pooling" />
            </NavLink>
            <NavLink exact={true} to="/masterdetail/group/">
              <Button className={Classes.MINIMAL} intent="primary" icon="list-detail-view" text="Master Lists" />
            </NavLink>
            <NavLink exact={true} to="/">
              <Button className={Classes.MINIMAL} intent="primary" icon="envelope" text="Mail" />
            </NavLink>
            <NavLink exact={true} to="/messages">
              <Button className={Classes.MINIMAL} intent="primary" icon="send-to" text="SMS" />
            </NavLink>
            <NavLink exact={true} to="/report/arrivalDetails">
              <Button className={Classes.MINIMAL} intent="primary" intent="primary" icon="cloud-download" text="Reports" />
            </NavLink>
            <NavLink exact={true} to="/import">
              <Button className={Classes.MINIMAL} intent="primary" intent="primary" icon="import" text="Imports" />
            </NavLink>
          </NavbarGroup>
          <Navbar.Group align={Alignment.RIGHT}>
            {(this.props.data.me.isAdmin) && (
              <NavLink exact={true} to="/addnewuser">
                <Button className={Classes.MINIMAL} intent="primary" icon="new-object" text="Add Users" />
              </NavLink>)
            }
            <Navbar.Divider />
            <Button className="bp3-minimal" intent="primary" icon="notifications" />
            {this.state.token ? (
              <Button
                onClick={() => {
                  this.refreshTokenFn &&
                    this.refreshTokenFn({
                      [AUTH_TOKEN]: null,
                    })
                  window.location.href = '/'
                }}
                className="bp3-minimal" intent="primary" icon="log-out" />
            ) : (
                <Button className="bp3-minimal" intent="primary" icon="log-in" />
              )}
          </Navbar.Group>
        </Navbar>)
    )
  }

  renderRoute() {
    return (
      <div className="fl w-100 pl4 pr4">
        <Switch>
          <ProtectedRoute
            token={this.state.token}
            exact path="/" component={Dashboard}
          />
          <Route
            path="/guest"
            component={Guest}
          />
          <Route
            exact
            path="/editguest/:guestId"
            component={EditGuest}
          />
          <Route
            path="/addnewguest/"
            component={AddNewGuest}
          />
          <Route
            token={this.state.token}
            path="/login"
            render={props => <LoginPage refreshTokenFn={this.refreshTokenFn} />}
          />
          <Route
            token={this.state.token}
            path="/addnewuser"
            component={AddNewUser}
          />
          <Route
            token={this.state.token}
            path="/import"
            component={CSVImport}
          />
          <Route
            token={this.state.token}
            path="/report"
            component={Reports}
          />
            <Route
            token={this.state.token}
            path="/messages"
            component={Messages}
          />
           <Route
            token={this.state.token}
            path="/vehiclePooling"
            component={VehiclePooling}
          />
          <Route
            path="/report/arrivalDetails/"
            component={ArrivalDetails}
          />
          <Route
            path="/report/departureDetails/"
            component={DepartureDetails}
          />
          <Route
            path="/report/hotelDetails/"
            component={HotelDetails}
          />
            <Route
            path="/report/vehicleDetails/"
            component={VehicleDetails}
          />
          <Route
            path="/masterdetail/"
            component={MasterDetail}
          />
          <Route
            path="/masterdetail/group"
            component={Group}
          />
          <Route
            path="/masterdetail/category"
            component={Category}
          />
          <Route
            path="/masterdetail/hotel"
            component={Hotel}
          />
          <ProtectedRoute
            token={this.state.token}
            path="/masterdetail/transport"
            component={Transport}
          />
          <Route path="/logout" component={LogoutPage} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    )
  }
}

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      name
      isAdmin
    }
  }
`
RootContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};



const RootContainerMutation = graphql(ME_QUERY, {
  options: {
    errorPolicy: 'all',
  },
})(RootContainer)

export default withStyles(styles)(RootContainerMutation);