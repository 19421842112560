import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Spinner } from "@blueprintjs/core";
import MaterialTable from 'material-table'
import moment from 'moment'

class HotelDetails extends Component {

    constructor(props) {
        super(props)
        this.refresh = this.refresh.bind(this)

        this.state = {
            arrivalDetails: []
        }
    }


    refresh() {
        this.props.allGuestVehicleDetails.refetch()
    }

    render() {
        if (this.props.allGuestVehicleDetails.loading) {
            return (
                <div className="flex w-100 h-100 items-center justify-center pt7">
                    <Spinner intent="primary" size={100} />
                </div>
            )
        }

        return (
            <div>
                <MaterialTable
                    columns={[
                        { title: 'Guests', field: 'guestIDS' },
                        { title: 'Vehicle Count', field: 'vehicleCount' },
                        { title: 'Operator', field: 'vehicleDetailsId' },
                        { title: 'Vehicle', field: 'vehicleTypeId' },
                        { title: 'Operator Number', field: 'opNumber' },
                        { title: 'Vehicle Number', field: 'vNumber' },
                        { title: 'Driver Number', field: 'dNumber' },
                        { title: 'Comments', field: 'comments' },
                    ]}
                    data={this.props.allGuestVehicleDetails.allGuestVehicleDetails && this.props.allGuestVehicleDetails.allGuestVehicleDetails.map(item => {
                        const { guestId, vehicleTypeId, vehicleNumberId } = item;
                        return {
                            guestIDS: item.guestId ? guestId.fullName: "",

                            vehicleCount: item.vehicleCount,

                            vehicleDetailsId: item.vehicleTypeId ? vehicleTypeId.vehicleDetailsId.name: "",

                            vehicleTypeId: item.vehicleTypeId ? vehicleTypeId.name: "",

                            opNumber: item.vehicleTypeId ? vehicleTypeId.vehicleDetailsId.mobile: "",

                            vNumber: item.vehicleNumberId ? vehicleNumberId.number: "",

                            dNumber: item.vehicleNumberId ? vehicleNumberId.mobile: "",

                            comments: item.comments,
                        };
                    })}
                    options={{
                        columnsButton: true,
                        exportButton: true,
                        filtering: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 100, 250],
                        showEmptyDataSourceMessage: true,
                    }}
                    localization={{
                        emptyDataSourceMessage: 'No records to display'
                    }}
                    title={"Cab Details"}
                />
            </div>
        )
    }
}

const GUEST_VEHICLES_DETAILS_QUERY = gql`
  query GuestVehiclesQuery {
    allGuestVehicleDetails {
      id
      vehicleCount
      guestId{
        id
        fullName
      }
      vehicleTypeId{
          id
          name
          totalCount
          vehicleDetailsId{
            id
            name
            mobile
          }
          comments
        }
        vehicleNumberId{
          id
          number
          mobile
      }
      comments
     
    }
  }
`

export default compose(
    graphql(GUEST_VEHICLES_DETAILS_QUERY, {
        name: 'allGuestVehicleDetails',
        options: () => ({
            fetchPolicy: 'network-only',
        }),
    }),
)(HotelDetails)

