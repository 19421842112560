import React, { Component } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MUIDataTable from "mui-datatables";
import { Spinner } from "@blueprintjs/core";
import CustomAddIcon from '../../constants/CustomAddIcon'
import CustomToolbarSelect from '../../constants/CustomToolbarSelect'
import { confirmAlert } from 'react-confirm-alert'
import AddNewFlightPage from './AddNewFlightPage'
import EditNewFlightPage from './EditNewFlightPage'
import moment from 'moment'

const columns = [
  {
    name: "Name",
    key: "name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Number",
    key: "number",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Source",
    key: "source",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Destination",
    key: "destination",
    options: {
      filter: true,
      sort: true,
      display: false,
    }
  },
  {
    name: "Start Date",
    key: "startDate",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Arrival Date",
    key: "arrivalDate",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Mobile",
    key: "mobile",
    options: {
      filter: true,
      sort: true,
      display: false,
    }
  },
  {
    name: "Actions",
    key: "action",
    options: {
      filter: false,
      sort: false,
    }
  },
];


class Flight extends Component {

  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
    this.goBack = this.goBack.bind(this)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isFlight: false,
      isFlightEditing: false,
      id: "",
      name: "",
      number: 0,
      source: "",
      destination: "",
      startDate: Date.now(),
      arrivalDate: Date.now(),
      mobile: "",
      transportId: ""
    }
  }

  goBack() {
    this.props.flightQuery.refetch()
    this.setState({
      isFlight: false,
      isFlightEditing: false,
    })
  }

  refresh() {
    this.props.flightQuery.refetch()
  }

  handleEdit(name, id, number, source, destination, startDate, arrivalDate, mobile,
    transportId) {
    this.setState({
      isFlightEditing: true,
      id: id,
      name: name,
      number: number,
      source:source,
      destination: destination,
      startDate: startDate,
      arrivalDate: arrivalDate,
      mobile: mobile,
      transportId: transportId.id
    })
  }

  async handleDelete(id) {
    await this.props.deleteFlightMutation({
      variables: { id },
    })
    this.props.flightQuery.refetch()
  }

  handleConfirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      isFlight: true
    })
  }

  render() {
    const { name, id, number, source, destination, startDate, arrivalDate, mobile,
      transportId } = this.state
    if (this.props.flightQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      <div>
        {this.state.isFlight ?
          <AddNewFlightPage goBack={this.goBack} />
          :
          this.state.isFlightEditing ?
            <EditNewFlightPage id={id} name={name}
              number={number} source={source} destination={destination} startDate={startDate} arrivalDate={arrivalDate} mobile={mobile}
              transportId={transportId}
              goBack={this.goBack} />
            :
            <MUIDataTable
              title={"Flights"}
              data={this.props.flightQuery.flights && this.props.flightQuery.flights.map(flight => {
                const { name, id, number, source, destination, startDate, arrivalDate, mobile,
                transportId} = flight;
                return [name.toString(),
                  number.toString(),
                  source.toString(),
                  destination.toString(),
                  moment(startDate).format('D MMMM YYYY'),
                  moment(arrivalDate).format('D MMMM YYYY'),
                  mobile.toString(),
                  <CustomToolbarSelect
                  handleEdit={() => this.handleEdit(name, id, number, source, destination, startDate, arrivalDate, mobile,
                  transportId)}
                  handleDelete={() => this.handleConfirmDelete(id)}
                />];
              })}
              columns={columns}
              options={{
                filter: true,
                selectableRows: false,
                filter: false,
                filterType: 'dropdown',
                responsive: 'stacked',
                rowsPerPage: 5,
                customToolbar: () => <CustomAddIcon title="ADD Flight" refresh={this.refresh} handler={this.handler} />
              }}
            />
        }
      </div>
    )
  }
}
const DELETE_FLIGHT_MUTATION = gql`
  mutation DeleteFlightMutation($id: ID!) {
    deleteTransportDetails(id: $id) {
      id
    }
  }
`
const FLIGHT_QUERY = gql`
  query FlightQuery{
    flights {
      id
      name
      number
      source
      destination
      startDate
      arrivalDate
      mobile
      transportId {
        id
        name
      }
    }
  }
`

export default compose(
  graphql(FLIGHT_QUERY, {
    name: 'flightQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(DELETE_FLIGHT_MUTATION, {
    name: 'deleteFlightMutation', // name of the injected prop: this.props.deleteFlightMutation...
  }),
)(Flight)