import React, { Component, Fragment } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles';
import { gql } from 'apollo-boost'
import _ from 'lodash'
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
	Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
	FormGroup, InputGroup, Spinner, TextArea, Radio, RadioGroup,
} from "@blueprintjs/core";
import { DateTimePicker } from 'material-ui-pickers';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment'
import Toast from '../../../utils/Toast'

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
	buttons: {
		display: 'inline-block',
	},
	travelSelect: {
		marginTop: 30,
	}
});

class TravelForm extends Component {
	constructor(props) {
		super(props)
		this.handleTravelChange = this.handleTravelChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.changeTravelDetails = this.changeTravelDetails.bind(this);

		this.state = {
			id: "",
			guestId: this.props.guestId,
			transportDetailsId: "",
			pnr: "",
			arrivalTime: "",
			comments: "",
			open: false,
			message: "",
			loading: false,
			transportId: "",
			travelDetails: [],
			gTTypeId: "",
			arrivalType: true,
			tabValue: 1,
			depatureTime: "",
			defaultGGtype: "",
		}
	}

	async componentWillMount() {
		const ggtransports = await this.props.client.query({
			query: GUEST_TRANSPORT_TYPES_QUERY,
			options: {
				fetchPolicy: 'network-only',
			}
		})
		var ggtypeID = await _.map(ggtransports.data.guestTransportTypes).map((item) => {
            return item
        }).map((sg) => {
            if (sg.name === "Arrival") {
                return sg.id
            }
        })
        var gTTypeId = _.compact(ggtypeID).toString();
		this.setState({
			gTTypeId, defaultGGtype: gTTypeId
		})
	}
	handleTabChange = (event, tabValue) => {
		this.setState({ tabValue });
	};

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleHtmlSelectChange = (event) => {
		this.setState({ transportDetailsId: event.target.value });
	};
	async handleTravelChange(id) {
		this.setState({ transportId: id, });
		await this.executeTravelDetails(id);
	}
	changeGuestTransportType(item) {
		this.setState({ gTTypeId: item.id, tabValue: item.id });
		if (item.name === "Arrival") {
			this.setState({ arrivalType: true });
		} else {
			this.setState({ arrivalType: false });
		}
	};

	async changeTravelDetails(id) {
		this.setState({ loading: true, transportDetailsId: id })
		const guestTransports = await this.props.client.query({
			query: TRANSPORT_DETAIL,
			variables: { id },
			options: {
				fetchPolicy: 'network-only',
			}
		})
		const gtransportDetails = await guestTransports.data.transportDetail
		console.log("gtransportDetails", gtransportDetails)
		var at = await _.map(gtransportDetails).map((item) => {
            return item.arrivalDate
		})
		var arrivalTime = _.compact(at).toString();
		var dt = await _.map(gtransportDetails).map((item) => {
            return item.startDate
		})
		var depatureTime = _.compact(dt).toString();
		await this.setState({
			loading: false,
			arrivalTime,
			depatureTime,
		})
		console.log("arrivalTime", this.state.arrivalTime)
		console.log("depatureTime", this.state.depatureTime)
	}

	handleTravelEdit = (item) => {
		console.log("item", item)
		this.setState({
			id: item.id,
			transportDetailsId: item.transportDetailsId.id,
			pnr: item.pnr,
			arrivalTime: item.defaultTime,
			depatureTime: item.defaultTime,
			comments: item.comments,
			transportId: item.transportDetailsId.transportId.id,
			guestId: this.props.guestId,
			gTTypeId: item.gTType.id,
		})
		if (item.transportDetailsId) {
			this.executeTravelDetails(item.transportDetailsId.transportId.id);
		}

	}

	async executeTravelDetails(id) {
		this.setState({ loading: true })
		const result = await this.props.client.query({
			query: TRANSPORT_DETAILS,
			variables: { transportId: id },
			options: {
				fetchPolicy: 'cache-and-network',
			}
		})
		const travelDetails = await result.data.transportDetailses
		this.setState({ travelDetails, loading: false, })
	}


	resetForm() {
		const gTTypeId = this.state.defaultGGtype
		this.setState({
			...this.state,
			id: "",
			guestId: this.props.guestId,
			transportDetailsId: "",
			pnr: "",
			arrivalTime: "",
			depatureTime: "",
			comments: "",
			transportId: "",
			travelDetails: [],
			gTTypeId,
			arrivalType: true
		})
	}

	handleSelectChange(values) {
		// send selection options array to state
		this.setState({ ...this.state, values });
		// pull selected values into array
		const vals = _.map(values, 'value');
		// join array into comma separated string for redux form
		this.props.onChange(vals.join(','));
	}

	render() {
		const { id, arrivalTime, comments, open, message, transportId, transportDetailsId,
			loading, pnr, travelDetails, gTTypeId, arrivalType, tabValue, depatureTime } = this.state
		const { classes } = this.props;
		return (
			<div>
				<Toast open={open} message={message} duration={5000} />
				{(loading) && (<Spinner intent="primary" size={50} />)}
				<div className="form-container">
					<Card className="card-global" elevation={Elevation.TWO}>
						<Paper className={classes.root}>
							<Tabs
								value={gTTypeId}
								indicatorColor="primary"
								textColor="primary"
								centered
							>
								{this.props.guesttransporttypesQuery.guestTransportTypes && this.props.guesttransporttypesQuery.guestTransportTypes.map((item, index) => {
									return (
										<Tab label={item.name} onClick={() => this.changeGuestTransportType(item)} value={item.id} />
									)
								})}
							</Tabs>
						</Paper>
						<div className={classes.travelSelect}>
							{
								this.props.guestTransportDetailsQuery.guestTransportDetails && this.props.guestTransportDetailsQuery.guestTransportDetails.map((item) => {
									return (
										<ul className="bp3-list-unstyled">
											<li key={item.id}>
												<div className="families">{item.gTType.name} : {item.transportDetailsId.transportId.name === "Flights" ? "Flight" : "Train"} - {item.transportDetailsId.name}
													<IconButton className="action-icons" onClick={() => this.handleTravelEdit(item)}>
														<EditIcon color="primary" />
													</IconButton>
												</div>
											</li>
										</ul>
									)
								})
							}
						</div>
						<FormGroup
							label="Travel Type "
							labelFor="travelType-input"
						>
							{this.props.transportQuery.transports && this.props.transportQuery.transports.map((item, index) => {
								return (
									<Radio label={item.name} onClick={() => this.handleTravelChange(item.id)} checked={transportId === item.id} value={item.id} />
								)
							})}
						</FormGroup>
						<form onSubmit={this.handleTravelDetails} autocomplete="off">
							<FormGroup
								labelFor="transportDetails-input"
							>
								<Select
									className="formControl"
									value={transportDetailsId}
									name="transportDetails"
									onChange={(e) => this.changeTravelDetails(e.target.value)}
								// onChange={event => this.setState({ transportDetailsId: event.target.value, arrivalTime: event.target.time })}
								>
									{travelDetails && travelDetails.map((item, index) => {
										return (
											<MenuItem  value={item.id}>{item.name.toString()} | {item.number.toString()}</MenuItem>
										)
									})}
								</Select>
								<FormHelperText>Select Master Travel Details</FormHelperText>
							</FormGroup>
							<div>
								<h3>Selected Travel Details</h3>
								<p>
									{travelDetails && travelDetails.map((item, index) => {
										if (item.id === transportDetailsId) {
											return (
												<div>
													<p>{item.name.toString()} | {item.number.toString()}</p>
													<p> From: {item.source.toString()}  </p>
													<p> To:  {item.destination.toString()} </p>
													<p> Start Date: {moment(item.startDate).format("YYYY-MM-DD hh:mm A")}  </p>
													<p> Arrival Date:{moment(item.arrivalDate).format("YYYY-MM-DD hh:mm A")}  </p>
												</div>
											)
										}
									})}
								</p>
							</div>
							{/* <FormGroup
								label="Travel Type "
								labelFor="travelType-input"
							>
								{this.props.guesttransporttypesQuery.guestTransportTypes && this.props.guesttransporttypesQuery.guestTransportTypes.map((item, index) => {
									return (
										<Radio label={item.name} onClick={() => this.changeGuestTransportType(item)} checked={gTTypeId === item.id} value={item.id} />
									)
								})}
							</FormGroup> */}

							<FormGroup
								label="PNR"
								labelFor="pnr-input"
							>
								<InputGroup id="pnr-input" value={pnr}
									name="pnr"
									onChange={this.handleChange}
									placeholder="PNR Number" />
							</FormGroup>

							<FormGroup
								label={arrivalType ? "Arrival Time" : "Depature Time"}
								labelFor="arrivalTime-input"
							>
								<DateTimePicker
									clearable
									value={arrivalType ? arrivalTime : depatureTime}
									onChange={(e) => {
										e !== null && (arrivalType ? this.setState({ arrivalTime: e.toJSON() })
											: this.setState({ depatureTime: e.toJSON() }))
									}}
								/>
							</FormGroup>

							<FormGroup
								label="Comments"
								labelFor="hotel-input"
								className="switchControl"
							>
								<TextArea
									className="bp3-fill"
									large={true}
									intent={Intent.PRIMARY}
									onChange={event => this.setState({ comments: event.target.value })}
									value={comments}
								/>
							</FormGroup>

							<div className={Classes.DIALOG_FOOTER}>
								<div className={Classes.DIALOG_FOOTER_ACTIONS}>
									<Button type="button" onClick={this.resetForm} intent={Intent.WARNING} >
										Reset
                                                     </Button>
									{id ? <Button type="submit" intent={Intent.PRIMARY} >
										Update
                                                     </Button> :
										<Button type="submit" intent={Intent.PRIMARY} >
											ADD
                                                     </Button>
									}
								</div>
							</div>
						</form>
					</Card>
				</div>
			</div>
		)
	}



	handleTravelDetails = async e => {
		e.preventDefault()
		this.setState({ loading: true })
		var defaultTime = ""
		var { id, arrivalTime, comments, transportDetailsId,
			pnr, guestId, gTTypeId, depatureTime, arrivalType } = this.state
		if (!arrivalType) {
			defaultTime = depatureTime
		} else {
			defaultTime = arrivalTime
		}
		if (id) {
			await this.props.editGuestTransportMutation({
				variables: {
					id,
					defaultTime, comments, transportDetailsId: transportDetailsId,
					pnr, gTTypeId
				},
			}).then((data) => {
				this.resetForm();
				this.setState({
					loading: false, open: true,
					message: "Travel Details Updated",
				})
			}).catch(error => {
				this.setState({
					open: true, loading: false,
					message: "Error occured " + error,
					loading: false
				})
			})
			await setTimeout(() => this.setState({ open: false }), 6000);
		} else {
			await this.props.createGuestTransportMutation({
				variables: {
					defaultTime, comments, transportDetailsId: transportDetailsId,
					pnr, guestId, gTTypeId
				},
			}).then((data) => {
				this.resetForm();
				this.setState({
					loading: false, open: true,
					message: "Travel Details Added",
				})
			}).catch(error => {
				this.setState({
					open: true, loading: false,
					message: "Error occured " + error,
					loading: false
				})
			})
			await setTimeout(() => this.setState({ open: false }), 6000);
		}
	}


}


const CREATE_GUEST_TRANSPORT_MUTATION = gql`
          mutation createGuestTransportMutation(
            $guestId: ID!,
            $pnr: String , $comments: String, $transportDetailsId: ID!, $defaultTime: DateTime!,
			$gTTypeId: ID!,
     ){
    createGuestTransportDetails(guestId: $guestId, comments: $comments,
        transportDetailsId: $transportDetailsId,
        pnr: $pnr,
        defaultTime: $defaultTime,
		gTTypeId: $gTTypeId,
      ) {
      id
      guestId {
        id
      }
      pnr
      }
    }
`
const EDIT_GUEST_TRANSPORT_MUTATION = gql`
          mutation editGuestTransportMutation(
            $id: ID!,
            $pnr: String , $comments: String, $transportDetailsId: ID!, $defaultTime: DateTime!,
			$gTTypeId: ID!,
     ){
    updateGuestTransportDetails(id: $id, comments: $comments,
        transportDetailsId: $transportDetailsId,
        pnr: $pnr,
        defaultTime: $defaultTime,
		gTTypeId: $gTTypeId,
      ) {
      id
      guestId {
        id
      }
      pnr
      }
    }
`
const TRANSPORT_QUERY = gql`
  query TransportQuery {
    transports {
          id
          name
        }
    }
`
const GUEST_TRANSPORT_TYPES_QUERY = gql`
		  query TransportQuery {
			guestTransportTypes {
						id
						name
					  }
					}
 `

const GUEST_TRANSPORT_DETAILS_QUERY = gql`
  query guestTransportDetailsQuery($guestId: ID!) {
    guestTransportDetails(guestId: $guestId) {
		id
		defaultTime 
		comments
		gTType{
			id
			name
		}
		transportDetailsId {
        id
        name
        number
        source
        destination
        startDate
        arrivalDate
        mobile
       transportId {
        id
        name
       }
      }
		pnr
    }
  }
`
const TRANSPORT_DETAILS = gql`
  query transportDetailsQuery($transportId: ID!) {
    transportDetailses(transportId: $transportId){
        id
        name
        number
        source
        destination
        startDate
        arrivalDate
        mobile
       transportId {
        id
        name
       }
    }
  }
`
const TRANSPORT_DETAIL = gql`
  query transportDetail($id: ID!) {
    transportDetail(id: $id){
        id
        name
        number
        source
        destination
        startDate
        arrivalDate
        mobile
       transportId {
        id
        name
       }
    }
  }
`

const TravelFormMutation = compose(
	graphql(TRANSPORT_QUERY, {
		name: 'transportQuery',
		options: {
			fetchPolicy: 'cache-and-network',
		},
	}),
	graphql(GUEST_TRANSPORT_TYPES_QUERY, {
		name: 'guesttransporttypesQuery',
		options: {
			fetchPolicy: 'cache-and-network',
		},
	}),
	graphql(GUEST_TRANSPORT_DETAILS_QUERY, {
		name: 'guestTransportDetailsQuery',
		options: (props) => ({
			variables: {
				guestId: props.guestId
			},
			fetchPolicy: 'cache-and-network',
		}),
	}),
	graphql(CREATE_GUEST_TRANSPORT_MUTATION, {
		name: 'createGuestTransportMutation',
		options: {
			refetchQueries: ['guestTransportDetailsQuery', 'transportQuery', 'GuestQuery']
		}
		// name of the injected prop: this.props.createGuestTransportMutation...
	}),
	graphql(EDIT_GUEST_TRANSPORT_MUTATION, {
		name: 'editGuestTransportMutation',
		options: {
			refetchQueries: ['guestTransportDetailsQuery', 'transportQuery', 'GuestQuery']
		}
		// name of the injected prop: this.props.editGuestTransportMutation...
	}),
)(TravelForm)

const TravelFormStyles = withStyles(styles)(TravelFormMutation)

export default withApollo(TravelFormStyles)