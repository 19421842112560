import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MaterialTable from 'material-table'
import { Spinner } from "@blueprintjs/core";
import CustomAddIcon from '../../constants/CustomAddIcon'
import CustomToolbarSelect from '../../constants/CustomToolbarSelect'
import { confirmAlert } from 'react-confirm-alert'
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import Icon from '@material-ui/core/Icon';
import BButton from '@material-ui/core/Button';
// import AddNewVehiclePoolingPage from './AddNewVehiclePoolingPage'
import EditNewVehiclePoolingPage from './EditNewVehiclePoolingPage'

class VehiclePooling extends Component {

  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
    this.goBack = this.goBack.bind(this)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isVehiclePooling: false,
      isVehiclePoolingEditing: false,
      id: "",
      name: ""
    }
  }

  goBack() {
    this.props.vehiclePoolQuery.refetch()
    this.setState({
      isVehiclePooling: false,
      isVehiclePoolingEditing: false,
    })
  }

  refresh() {
    this.props.vehiclePoolQuery.refetch()
  }

  handleEdit(id) {
    this.setState({
      isVehiclePoolingEditing: true,
      id: id,
    })
  }

  async handleDelete(id, guestIDS) {
    console.log("guestIDS", guestIDS)
    await this.props.deleteVehiclePool({
      variables: { id },
    }).then(async () => {
      for (var i = 0; i < guestIDS.length; i++) {
        await this.props.deleteGuestVehicleDetails({
          variables: { id: guestIDS[i] },
        }).then(async () => {
          this.setState({
            open: true,
            message: "Deleted pooling and Guest Vehicle realtions"
          })
        })
      }

    }).catch(error => {
      this.setState({
        open: true, loading: false,
        message: "Error occured " + error,
        loading: false
      })
    })
    this.props.vehiclePoolQuery.refetch()
  }

  handleConfirmDelete = (id, guestIDS) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id, guestIDS)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      isVehiclePooling: true
    })
  }

  render() {
    const { id, guestIDS } = this.state
    if (this.props.vehiclePoolQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      <div>
        {this.state.isVehiclePooling ?
          <EditNewVehiclePoolingPage goBack={this.goBack} />
          :
          this.state.isVehiclePoolingEditing ?
            <EditNewVehiclePoolingPage id={id} goBack={this.goBack} />
            :
            <div>
              <BButton style={{ float: 'right', margin: 5 }} variant="contained" color="primary" onClick={this.refresh}>
                <Icon>
                  <RefreshIcon> </RefreshIcon>
                </Icon>
              </BButton>
              <BButton style={{ float: 'right', margin: 5 }} variant="contained" color="primary" onClick={this.handler}>
                <Icon>
                  <AddIcon> </AddIcon>
                </Icon>
              </BButton>
              <MaterialTable
                columns={[
                  { title: 'Guests', field: 'guestIDS' },
                  { title: 'Vehicle Count', field: 'vehicleCount' },
                  { title: 'Operators', field: 'vehicleDetailsId' },
                  { title: 'Vehicles', field: 'vehicleTypeId' },
                  { title: 'Comments', field: 'comments' },
                  { title: 'Actions', field: 'actions' },

                ]}
                data={this.props.vehiclePoolQuery.vehiclePools && this.props.vehiclePoolQuery.vehiclePools.map(vehiclePool => {
                  var names = vehiclePool.guestIDS ? vehiclePool.guestIDS.map((item) => {
                    return `${item.fullName}`
                  }) : ""
                  var operators = vehiclePool.totalVehiclesId ? vehiclePool.totalVehiclesId.map((item) => {
                    return item.vehicleTypeId.vehicleDetailsId
                  }).map((sg) => {
                    return `${sg.name}`
                  }) : ""

                  var vehicles = vehiclePool.totalVehiclesId ? vehiclePool.totalVehiclesId.map((item) => {
                    return item.vehicleTypeId
                  }).map((sg) => {
                    return `${sg.name}`
                  }) : ""

                  return {
                    guestIDS: vehiclePool.guestIDS ? names.join(' | ') : "",

                    vehicleCount: vehiclePool.totalVehiclesId.length > 0 ? vehiclePool.totalVehiclesId.map((item) => {
                      return item.vehicleCount
                    }).reduce((x, y) => x + y) : "",

                    vehicleDetailsId: vehiclePool.totalVehiclesId ? operators.join(' | ') : "",

                    vehicleTypeId: vehiclePool.totalVehiclesId ? vehicles.join(' | ') : " ",

                    comments: vehiclePool.totalVehiclesId ? vehiclePool.totalVehiclesId.map((item) => {
                      return item.comments
                    }) : "",

                    actions: <CustomToolbarSelect
                      handleEdit={() => this.handleEdit(vehiclePool.id)}
                      handleDelete={() => this.handleConfirmDelete(vehiclePool.id, vehiclePool.guestIDS.map((item) => item.vehicleDetails? item.vehicleDetails.id: ""))}
                    />
                  };
                })}
                options={{
                  columnsButton: true,
                  exportButton: true,
                  filtering: true,
                  paging: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 100, 250],
                  showEmptyDataSourceMessage: true,
                }}
                localization={{
                  emptyDataSourceMessage: 'No records to display'
                }}
                title={"Vehicle Pooling"}
              />
            </div>
        }
      </div>
    )
  }
}
const DELETE_VEHICLE_POOL = gql`
  mutation DeleteVehiclePoolingMutation($id: ID!) {
    deleteVehiclePool(id: $id) {
      id
    }
  }
`
const VEHICLE_POOL_QUERY = gql`
  query VehiclePoolingQuery {
    vehiclePools {
      id
      guestIDS{
        id
        fullName
        vehicleDetails{
          id
        }
      }
      totalVehiclesId{
        id
        vehicleCount
        vehicleTypeId{
          id
          name
          totalCount
          vehicleDetailsId{
            id
            name
          }
          comments
        }
      }
    }
  }
`
const DELETE_GUEST_VEHICLE_DETAILS = gql`
  mutation DeleteGuestVehicleDetails($id: ID!) {
    deleteGuestVehicleDetails(id: $id) {
        id
    }
}
`

export default compose(
  graphql(VEHICLE_POOL_QUERY, {
    name: 'vehiclePoolQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(DELETE_GUEST_VEHICLE_DETAILS, {
    name: 'deleteGuestVehicleDetails', // name of the injected prop: this.props.deleteFamilyMutation...
  }),
  graphql(DELETE_VEHICLE_POOL, {
    name: 'deleteVehiclePool', // name of the injected prop: this.props.deleteVehiclePoolingMutation...
  }),
)(VehiclePooling)

