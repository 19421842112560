import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import _ from 'lodash'
import {
    Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
    FormGroup, InputGroup, Radio, RadioGroup, Spinner
} from "@blueprintjs/core";
import Toast from '../../../utils/Toast'

class FamilyForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gfirstName: "",
            glastName: "",
            gphone: "",
            govtIdType: "",
            ggovtID: "",
            gpnr: "",
            isInformed: false,
            isInvited: false,
            guestId: this.props.guestId,
            open: false,
            loading: false,
            message: "",
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleHtmlSelectChange = (event) => {
        this.setState({ govtIdType: event.target.value });
    };

    resetForm() {
        this.setState({
            ...this.state,
            id: "",
            gfirstName: "",
            glastName: "",
            gphone: "",
            govtIdType: "",
            ggovtID: "",
            gpnr: "",
            isInformed: false,
            isInvited: false,
        })
    }


    handleSelectChange(values) {
        // send selection options array to state
        this.setState({ ...this.state, values });
        // pull selected values into array
        const vals = _.map(values, 'value');
        // join array into comma separated string for redux form
        this.props.onChange(vals.join(','));
    }

    render() {
        const { gfirstName, glastName, govtIdType,
            gphone, ggovtID, gpnr, message,
            isInformed, isInvited, open, loading } = this.state
        return (
            <div>
                <Toast open={open} message={message} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <div className="form-container">

                    <Card className="card-global" elevation={Elevation.TWO}>
                        <h4>Family Members</h4>
                        {
                            this.props.guestFamilyQuery.families && this.props.guestFamilyQuery.families.map((item) => {
                                return (
                                    <ul className="bp3-list-unstyled">
                                        <li key={item.id}>
                                            <p> {item.firstName} </p>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        <form onSubmit={this.handleFamily} autocomplete="off">
                            <div className="form-groups">
                                <FormGroup
                                    label="Family Name"
                                    labelFor="gfirstName-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="gfirstName-input" value={gfirstName}
                                        required="required"
                                        name="gfirstName"
                                        autoComplete="new-fname"
                                        onChange={this.handleChange}
                                        placeholder="Enter family name" />
                                </FormGroup>
                                {/* <FormGroup
                                    label="Last Name"
                                    labelFor="glastName-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="glastName-input" value={glastName}
                                        required="required"
                                        name="glastName"
                                        autoComplete="new-flname"
                                        onChange={this.handleChange}
                                        placeholder="Last Name of FAMILY" />
                                </FormGroup> */}
                                <FormGroup
                                    label="Phone"
                                    labelFor="gphone-input"
                                >
                                    <InputGroup id="gphone-input" value={gphone}
                                        name="gphone"
                                        autoComplete="new-fphone"
                                        onChange={this.handleChange}
                                        placeholder="Enter phone number" />
                                </FormGroup>
                            </div>
                            <div className="form-groups">
                                <FormGroup
                                    label="PNR"
                                    labelFor="gpnr-input"
                                >
                                    <InputGroup id="gpnr-input" value={gpnr}
                                        name="gpnr"
                                        autoComplete="new-fpnr"
                                        onChange={this.handleChange}
                                        placeholder="PNR Number" />
                                </FormGroup>

                                <RadioGroup
                                    label="Informed"
                                    inline={true}
                                    onChange={event => this.setState({ isInformed: event.target.value })}
                                    selectedValue={isInformed}
                                >
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>

                                <RadioGroup
                                    label="Invited"
                                    inline={true}
                                    onChange={event => this.setState({ isInvited: event.target.value })}
                                    selectedValue={isInvited}
                                >
                                    <Radio label="Yes" value="true" />
                                    <Radio label="No" value="false" />
                                </RadioGroup>

                            </div>

                            <FormGroup
                                label="Government ID"
                                labelFor="ggovtID-input"
                            >
                                <ControlGroup>
                                    <HTMLSelect
                                        options={this.props.govtIdTypesQuery.govtIdTypes && this.props.govtIdTypesQuery.govtIdTypes.map((item) => {
                                            return { 'label': item.name, 'value': item.id }
                                        })}
                                        value={govtIdType}
                                        onChange={this.handleHtmlSelectChange}
                                    />
                                    <InputGroup
                                        id="ggovtID-input"
                                        value={ggovtID}
                                        name="ggovtID"
                                        autoComplete="new-fgovtId"
                                        placeholder="Enter identity number"
                                        onChange={this.handleChange} />
                                </ControlGroup>

                            </FormGroup>



                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        ADD
                </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        )
    }

    handleFamily = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        if (this.state.govtIdType === "") {
            var govtType = await _.map(this.props.govtIdTypesQuery.govtIdTypes).map((item) => {
                return item
            }).map((pr) => {
                if (pr.name === "Select") {
                    return pr.id
                }
            })[0];
            this.setState({ govtIdType: govtType })
            // govtIdType = govtType;
            console.log("GovtIDTYPE", this.state.govtIdType)
        }
        console.log("Out GovtIDTYPE", this.state.govtIdType)
        const { gfirstName, glastName, govtIdType,
            gphone, ggovtID, gpnr,
            isInformed, isInvited, guestId } = this.state
        await this.props.createFamilyMutation({
            variables: {
                firstName: gfirstName, lastName: glastName, govtIdType,
                phone: gphone, govtID: ggovtID, pnr: gpnr,
                isInformed, isInvited, guestId
            },
        }).then((data) => {
            this.setState({
                loading: false, open: true,
                message: "Family " + data.data.createFamily.firstName + " created",
            })
            this.resetForm()
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }
}


const CREATE_FAMILY_MUTATION = gql`
  mutation CreateFamilyMutation(
    $guestId: ID!,
    $firstName: String!, $lastName: String,
    $phone: String, $govtID: String, $govtIdType: ID,
    $isInformed: Boolean!, $isInvited: Boolean!, $pnr: String 
     ) {
    createFamily(guestId: $guestId, firstName: $firstName, lastName: $lastName, pnr: $pnr,
        phone: $phone, govtID: $govtID, isInformed: $isInformed,
        govtIdType: $govtIdType, isInvited: $isInvited,
      ) {
      id
      firstName, lastName, phone
    }
  }
`
const GOVTIDTYPES_QUERY = gql`
  query govtIdTypesQuery {
    govtIdTypes {
      id
      name
    }
  }
`
const FAMALIES_QUERY = gql`
  query guestFamilyQuery($guestId: ID!) {
    families(guestId: $guestId) {
         id
         firstName
         lastName
    }
  }
`
const STATUS_QUERY = gql`
  query StatusQuery {
    attending {
      id
      name
    }
  }
`
export default compose(

    graphql(GOVTIDTYPES_QUERY, {
        name: 'govtIdTypesQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(STATUS_QUERY, {
        name: 'statusQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(FAMALIES_QUERY, {
        name: 'guestFamilyQuery',
        options: (props) => ({
            variables: {
                guestId: props.guestId
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(CREATE_FAMILY_MUTATION, {
        name: 'createFamilyMutation',
        options: {
            refetchQueries: ['guestFamilyQuery']
        }
        // name of the injected prop: this.props.createFamilyMutation...
    }),
)(FamilyForm)
