import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const divStyle = {
  iconButton: {
    top: "50%",
    display: "inline-flex",
    position: "relative",
  },
};

class CustomToolbarSelect extends React.Component {

  render() {
    return (
      <div style={divStyle.iconButton}>
        <IconButton onClick={this.props.handleEdit}>
          <EditIcon color="primary" />
        </IconButton>
        <IconButton onClick={this.props.handleDelete}>
          <DeleteIcon color="primary" />
        </IconButton>
      </div>
    );
  }

}

export default CustomToolbarSelect;