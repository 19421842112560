import React from 'react';
import axios from 'axios';
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
    FormGroup, Spinner, TextArea, InputGroup
} from "@blueprintjs/core";
import _, { mapKeys } from 'lodash'
import moment from 'moment'
import MultiSelect from "@kenshooui/react-multi-select";
import Toast from '../../utils/Toast'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

axios.defaults.headers.post['authkey'] = '252639AOCsd04d75c1a81d7'
axios.defaults.headers.post['Content-Type'] = 'application/json'

class Messages extends React.Component {
    constructor(props) {
        super(props)
        this.sendMessage = this.sendMessage.bind(this)
        this.updateState = this.updateState.bind(this)
        this.handleGuestIDS = this.handleGuestIDS.bind(this)
        this.state = {
            message: "",
            from: "KANKTL",
            guests: [],
            guestIDS: [],
            selectedGuestIDS: [],
            bulkSms: true,
            hotelSend: false,
            cabSend: false,
            open: false,
            loading: false,
            toastMessage: ""
        };

    }

    async componentDidMount() {
        this.updateState();
    }

    handleGuestIDS(selectedGuestIDS) {
        this.setState({ selectedGuestIDS });
    }

    async updateState() {
        var guests = await this.props.client.query({
            query: GUEST_QUERY,
            options: {
                fetchPolicy: 'network-only',
            }
        })
        console.log("guests.data.guestPhoneNumbers", guests.data.guestPhoneNumbers)
        var GuestPhones = await _.map(guests.data.guestPhoneNumbers).map((item) => {
            return {
                'label': item.fullName,
                'id': item.id,
                'mobile': item.phone,
                'hotel': item.hotelDetails,
                'cab': item.vehicleDetails,
            }
        })

        var selectedGuestIDS = _.flatten(GuestPhones);

        this.setState({
            guestIDS: selectedGuestIDS
        })
    }

    async sendMessage() {
        const { from, message, selectedGuestIDS, bulkSms, hotelSend, cabSend } = this.state;
        console.log("selectedGuestIDS", selectedGuestIDS)
        this.setState({ loading: true });
        for (var i = 0; i < selectedGuestIDS.length; i++) {

            const { mobile, hotel, cab } = selectedGuestIDS[i]
            const hotelMessage = `Hotel Details - Room Number: ${hotel.roomNumber ? hotel.roomNumber : ""} Room Type: ${hotel.roomType ? hotel.roomType : ""} Check In: ${hotel.checkIn ? moment(hotel.checkIn).format("YYYY-MM-DD hh:mm A") : ""} Check Out: ${hotel.checkOut ? moment(hotel.checkOut).format("YYYY-MM-DD hh:mm A") : ""} Comments: ${hotel.comments ? hotel.comments : ""}`
            var cabMessage = "";
            if (cab.cabPoolId === null) {
                cabMessage = `Car Details - Car Number: ${cab.vehicleNumberId ? cab.vehicleNumberId.number : ""} Driver Number: ${cab.vehicleNumberId.mobile ? cab.vehicleNumberId.mobile : ""}`
            } else {
                var cabDetails = await _.map(cab.cabPoolId.totalVehiclesId).map((item) => {
                    const cab = item.vehicleNumberIDS;
                    return cab
                })
                var cabNumber = _.map(cabDetails).map((item) => {
                    return item.map((gd) => {
                        return gd.number
                    }
                    )
                }).toString()

                var driverNumber = _.map(cabDetails).map((item) => {
                    return item.map((gd) => {
                        return gd.mobile
                    }
                    )
                }).toString()
                cabMessage = `Car Details - Car Number: ${cabNumber ? cabNumber : ""} Driver Number: ${driverNumber ? driverNumber : ""}`
            }

            if (mobile && hotelSend) {
                axios.post(`http://api.msg91.com/api/v2/sendsms?message=${hotelMessage}&sender=${from}&route=4&mobiles=${mobile}&country=91`)
                    .then(response => {
                        this.setState({
                            open: true,
                            toastMessage: "Hotel details sent",
                            loading: false
                        })
                    }).catch(error => {
                        this.setState({
                            open: true, loading: false,
                            toastMessage: "Error occured " + error,
                            loading: false
                        })
                    })
            }
            if (mobile && bulkSms) {
                axios.post(`http://api.msg91.com/api/v2/sendsms?message=${message}&sender=${from}&route=4&mobiles=${mobile}&country=91`)
                    .then(response => {
                        this.setState({
                            open: true,
                            toastMessage: "Bulk messages sent",
                            loading: false
                        })
                    }).catch(error => {
                        this.setState({
                            open: true, loading: false,
                            toastMessage: "Error occured " + error,
                            loading: false
                        })
                    })
            }

            if (mobile && cabSend) {
                axios.post(`http://api.msg91.com/api/v2/sendsms?message=${cabMessage}&sender=${from}&route=4&mobiles=${mobile}&country=91`)
                    .then(response => {
                        this.setState({
                            open: true,
                            toastMessage: "Cab details sent",
                            loading: false
                        })
                    }).catch(error => {
                        this.setState({
                            open: true, loading: false,
                            toastMessage: "Error occured " + error,
                            loading: false
                        })
                    })
            }
            await setTimeout(() => this.setState({ open: false }), 2000);
        }
    }


    render() {
        const { guestIDS, selectedGuestIDS, message, bulkSms, hotelSend, cabSend, toastMessage, open, loading } = this.state;
        return (
            <div>
                <Toast open={open} message={toastMessage} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <h2 style={{ textAlign: 'center' }}>Messages</h2>
                <div className="form-container">
                    <Card className="card-messages" elevation={Elevation.TWO}>
                        <Paper style={{ flexGrow: '1' }}>
                            <Tabs
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                value={bulkSms || cabSend || hotelSend}
                            >

                                <Tab label="Bulk SMS" value={bulkSms} onClick={() => this.setState({ bulkSms: true, hotelSend: false, cabSend: false })} />
                                <Tab label="Hotel Details" value={hotelSend} onClick={() => this.setState({ hotelSend: true, bulkSms: false, cabSend: false })} />
                                <Tab label="Cab Details" value={cabSend} onClick={() => this.setState({ cabSend: true, bulkSms: false, hotelSend: false })} />
                            </Tabs>
                        </Paper>

                        {(bulkSms) && (
                            <div className="form-groups" style={{ marginTop: 25 }}>
                                <FormGroup
                                    label="Message"
                                    labelFor="message-input"
                                >
                                    <TextArea
                                        className="bp3-fill"
                                        large={true}
                                        style={{ width: 450, height: 150 }}
                                        intent={Intent.PRIMARY}
                                        onChange={event => this.setState({ message: event.target.value })}
                                        value={message}
                                    />
                                </FormGroup>
                            </div>
                        )}
                        <div style={{ marginTop: 25 }}>
                            <FormGroup
                                label="Select Guests"
                                labelFor="sguest-input"
                            >
                                <MultiSelect
                                    items={guestIDS}
                                    selectedItems={selectedGuestIDS}
                                    onChange={this.handleGuestIDS}
                                />
                            </FormGroup>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button intent={Intent.PRIMARY} onClick={this.sendMessage}>Send SMS</Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}


const GUEST_QUERY = gql`
  query GuestQuery {
                        guestPhoneNumbers{
                    id, fullName, phone,
       hotelDetails{
                    id
                    roomNumber
                    roomType
                    checkIn
                    checkOut
                    comments
                }
        vehicleDetails {
            id
            vehicleCount
            vehicleNumberId{
                id
                number
                mobile
            }
            comments
            cabPoolId{
                id
                totalVehiclesId{
                    id
                    vehicleCount
                    vehicleNumberIDS{
                        id
                        number
                        mobile
                    }
                }
            }
            }
           }
           }
         `

const MessageMutation = compose(
    graphql(GUEST_QUERY, {
        name: 'guestQuery',
        options: () => ({
            fetchPolicy: 'cache-and-network',
        }),
    }),
)(Messages)

export default withApollo(MessageMutation)