import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button, Intent
} from "@blueprintjs/core";

const PageNotFound = ({ location }) => (
  <div className="w-100 h-100 items-center justify-center pt7">
    <center><p>Sorry, no page found at {location.pathname}</p>
      <Link to="/">
      <Button intent={Intent.PRIMARY} >
       Go Home
      </Button>
      </Link>
    </center>
  </div>
)

export default PageNotFound
