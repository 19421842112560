import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import {
    Card, Elevation,
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import GuestDetail from './GuestDetail'

const styles = theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    backButton: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
      },
    completed: {
        display: 'inline-block',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },

});

class EditGuestPage extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.state = {
            guestId: ""
        }
    }
    componentDidMount() {
        const { guestId } = this.props.match.params
        this.setState({ guestId })
    }
    goBack() {
        this.props.history.goBack()
    }
    render() {
        const { classes } = this.props;
        const { guestId } = this.state;
        return (
            <div className="stepper-content">
                <Button variant="contained" color="primary" className={classes.button} onClick={this.goBack}>
                    <Icon className={classes.leftIcon}>
                        <BackIcon> </BackIcon>
                    </Icon>
                    Back
                    </Button>
                <div>
                    <Card className="card-guests" elevation={Elevation.ONE}>
                        <center><h3>Guest Details</h3></center>
                        <GuestDetail id={guestId}/>
                    </Card>
                </div>
            </div>
        );
    }
}

EditGuestPage.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(styles)(EditGuestPage);