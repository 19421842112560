import React, { Component, Fragment } from 'react'
import ArrivalDetails from './ArrivalDetails'
import DepartureDetails from './DepartureDetails'
import HotelDetails from './HotelDetails'
import {
    NavLink,
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom'
import { AUTH_TOKEN } from '../../constant'
import { isTokenExpired } from '../../helper/jwtHelper'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import VehicleDetails from './VehicleDetails';

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
    return token ? (
        <Route {...rest} render={matchProps => <Component {...matchProps} />} />
    ) : (
            <Redirect to="/login" />
        )
}

class MasterDetails extends Component {
    constructor(props) {
        super(props)
        this.refreshTokenFn = this.refreshTokenFn.bind(this)

        this.state = {
            token: props.token,
            isAdmin: props.isAdmin
        }
    }

    refreshTokenFn(data = {}) {
        const token = data.AUTH_TOKEN

        if (token) {
            localStorage.setItem(AUTH_TOKEN, token)
        } else {
            localStorage.removeItem(AUTH_TOKEN)
        }

        this.setState({
            token: data.AUTH_TOKEN,
        })
    }

    bootStrapData() {
        try {
            const token = localStorage.getItem(AUTH_TOKEN)
            if (token !== null && token !== undefined) {
                const expired = isTokenExpired(token)
                if (!expired) {
                    this.setState({ token: token })
                } else {
                    localStorage.removeItem(AUTH_TOKEN)
                    this.setState({ token: null })
                }
            }
        } catch (e) {
            console.log('')
        }
    }

    //verify localStorage check
    componentDidMount() {
        this.bootStrapData()
    }

    render() {

        return (
            <Router>
                <Fragment>
                    {this.renderReports()}
                    {this.renderRoute()}
                </Fragment>
            </Router>
        )
    }

    renderReports() {
        return (
            this.props.data &&
            this.props.data.me &&
            this.props.data.me.email &&
            this.state.token && (
                <div className="sidebar-menu">
                    <ul className="bp3-menu bp3-elevation-1">
                        <li className="bp3-menu-header"><h6 className="bp3-heading">Reports</h6></li>
                        <NavLink exact={true} to="/report/arrivalDetails/">
                            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-direction-left">Arrival Details</button></li>
                        </NavLink>
                        <NavLink exact={true} to="/report/departureDetails/">
                            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-direction-right">Departure Details</button></li>
                        </NavLink>
                        <NavLink exact={true} to="/report/hotelDetails/">
                            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-office">Hotel Details</button></li>
                        </NavLink>
                        <NavLink exact={true} to="/report/vehicleDetails/">
                            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-drive-time">Vehicle Details</button></li>
                        </NavLink>
                        
                    </ul>
                </div>
            )
        )
    }

    renderRoute() {
        return (
            <div className="fl w-100 pl4 pr4">
                <div className="main-content">
                    <Switch>
                        <Route
                            path="/report/arrivalDetails/"
                            component={ArrivalDetails}
                        />
                        <Route
                            path="/report/departureDetails/"
                            component={DepartureDetails}
                        />
                         <Route
                            path="/report/hotelDetails/"
                            component={HotelDetails}
                        />
                        <Route
                            path="/report/vehicleDetails/"
                            component={VehicleDetails}
                        />
                        {/* <ProtectedRoute
              token={this.state.token}
              path="/masterdetail/transport"
              component={Transport}
            /> */}
                    </Switch>
                </div>
            </div>
        )
    }
}


const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      name
      isAdmin
    }
  }
`

export default graphql(ME_QUERY, {
    options: {
        errorPolicy: 'all',
    },
})(MasterDetails)

