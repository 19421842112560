import React from 'react';
import { graphql, withApollo, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import CsvParse from '@vtex/react-csv-parse'
import _ from 'lodash'

class CSVImport extends React.Component {
    state = {
        groupData: [],
        subGroupData: [],
        categoryData: [],
        eventsData: [],
        guestData: [],
        cityData: [],
        areaData: [],
        guestCategoryData: [],
        transportDetails: [],
    }

    handleGroupData = data => {
        this.setState({ groupData: data })
        console.log("data", this.state.groupData)
    }

    handleGuestData = data => {
        this.setState({ guestData: data })
        console.log("data", this.state.guestData)
    }

    handleSubGroupData = data => {
        this.setState({ subGroupData: data })
        console.log("data", this.state.subGroupData)
    }

    handleCityData = data => {
        this.setState({ cityData: data })
        console.log("city data", this.state.cityData)
    }

    handleGuestCatData = data => {
        this.setState({ guestCategoryData: data })
        console.log("guest cat", this.state.guestCategoryData)
    }

    handleTransportData = data => {
        this.setState({ transportDetails: data })
        console.log("data", this.state.transportDetails)
    }

    updateGuestCategory = async () => {
        const data = this.state.guestCategoryData
        for (var i = 0; i < data.length; i++) {
            const { phone, categoryId } = data[i]
            await this.props.updateGuestCategoryMutation({
                variables: { phone, categoryId },
            }).then(({ data }) => {
                const gid = data.updateGuestCategory.id;
                const cname = data.updateGuestCategory.category.name;
                console.log("ID:" + gid + cname +"Updated" )
            }).catch(error => {
                console.log("error", error)
            })
        }
    }

    addNewTravelDetails = async () => {
        const data = this.state.transportDetails
        for (var i = 0; i < data.length; i++) {
            const { name, number, source, destination, startDate, arrivalDate, mobile, transportId, sno } = data[i]
        await this.props.createFlightMutation({
            variables: { name, number, source, destination, startDate, arrivalDate, mobile, transportId, guestSNO: sno },
        }).then(({ data }) => {
            const id = data.createTransportDetails.id;
            const name = data.createTransportDetails.name;
            console.log("ID:" + id + name +"added" )
        }).catch(error => {
            console.log("error", error)
        })
    }
    }

    uploadGuestData = async () => {
        var govtIdType = "cjo3opcos008d0758gogyuo3b"
        const data = this.state.guestData
        const arrivalDate = new Date().toISOString()
        for (var i = 0; i < data.length; i++) {
            const { fullName,
                family_fullname,
                cardName,
                phone,
                Phone_2,
                city,
                serialNo,
                printOver,
                majorArea,
                minorArea,
                address,
                noOfPersons,
                status,
                category,
                group,
                subGroup,
                inviteMode,
                hotelRequired,
                comments,
            } = data[i]
            await this.props.createGuestMutation({
                variables: {
                    fullName, cardName, groupId: group, phone, city,
                    noOfPersons, statusId: status, categoryId: category, comments,
                    hotelRequired, subGroupId: subGroup,
                    serialNo, printOver, majorAreaId: majorArea, minorAreaId: minorArea, address, inviteMode,
                    isInformed: false, isInvited: false, vehicleRequired: false, arrivalDate,
                },
            }).then(async ({ data }) => {
                const gid = data.createGuest.id;
                const fullName = data.createGuest.fullName;
                console.log("Guest", gid + " " + fullName + "" + "Added")
                if (family_fullname !== "") {
                    await this.props.createFamilyMutation({
                        variables: {
                            firstName: family_fullname, phone: Phone_2, guestId: gid, isInformed: false,
                            isInvited: false, govtIdType,
                        },
                    }).then(({ data }) => {
                        const fid = data.createFamily.id;
                        const firstName = data.createFamily.firstName;
                        console.log("Family", fid + " " + firstName + "" + "Added")
                    }).catch(error => {
                        console.log("error", error)
                    })
                }
            }).catch(error => {
                console.log("error", error)
            })
            await setTimeout((500));
        }
    }

    uploadCityData = async () => {
        const data = this.state.cityData
        for (var i = 0; i < data.length; i++) {
            const { id, name } = data[i]
            await this.props.createCityMutation({
                variables: { id, name },
            }).then(({ data }) => {
                const cid = data.createCity.id;
                const cname = data.createCity.name;
                console.log("cid", cid + cname)
            }).catch(error => {
                console.log("error", error)
            })
        }
    }

    handleAreaData = data => {
        this.setState({ areaData: data })
        console.log("area data", this.state.areaData)
    }

    uploadAreaData = async () => {
        const data = this.state.areaData
        for (var i = 0; i < data.length; i++) {
            const { id, name } = data[i]
            await this.props.createAreaMutation({
                variables: { id, name },
            }).then(({ data }) => {
                const aid = data.createArea.id;
                const aname = data.createArea.name;
                console.log("aid", aid + aname)
            }).catch(error => {
                console.log("error", error)
            })
        }
    }

    uploadGroupData = async () => {
        const data = this.state.groupData
        for (var i = 0; i < data.length; i++) {
            const { id, name } = data[i]
            await this.props.createGroupMutation({
                variables: { id, name },
            }).then(({ data }) => {
                const gid = data.createGroup.id;
                console.log("gid", gid)
            }).catch(error => {
                console.log("error", error)
            })
        }
    }

    uploadSubData = async () => {
        const data = this.state.subGroupData
        for (var i = 0; i < data.length; i++) {
            const { name, gid } = data[i]
            await this.props.createSubGroupMutation({
                variables: { groupId: gid, name },
            }).then(({ data }) => {
                const sgid = data.createSubGroup.id;
                console.log("subgroupid", sgid)
            }).catch(error => {
                console.log("error", error)
            })
        }
    }

    render() {
        const { groupData, eventsData, subGroupData, transportDetails,
             categoryData, guestData, cityData, areaData, guestCategoryData } = this.state
        const gkeys = [
            "id",
            "name",
        ]
        const sGkeys = [
            "name",
            "gid",
        ]
        const citykeys = [
            "id",
            "name",
        ]
        const areakeys = [
            "id",
            "name",
        ]
        const guestcatkeys = [
            "phone",
            "categoryId"
        ]
        const transportkeys = [
            "name", "number", "source", "destination", "startDate", "arrivalDate", "mobile",
            "transportId",
            "sno"
        ]
        const guestkeys = [
            "fullName",
            "family_fullname",
            "cardName",
            "phone",
            "Phone_2",
            "city",
            "serialNo",
            "printOver",
            "majorArea",
            "minorArea",
            "address",
            "noOfPersons",
            "status",
            "category",
            "group",
            "subGroup",
            "inviteMode",
            "hotelRequired",
            "comments",
        ]
        return (
            <div className="pa4 flex justify-center bg-white">
                <div>
                    <center>
                        <h4>Group Upload</h4>
                        <div>
                            <CsvParse
                                keys={gkeys}
                                onDataUploaded={this.handleGroupData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.uploadGroupData}>Upload </button>
                        </div>
                        <div>
                            {groupData.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.id}</p>
                                        <p>{item.name}</p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>
                    <center>
                        <h4>Sub Group Upload</h4>
                        <div>
                            <CsvParse
                                keys={sGkeys}
                                onDataUploaded={this.handleSubGroupData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.uploadSubData}>Upload </button>
                        </div>
                        <div>
                            {subGroupData.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.id}</p>
                                        <p>{item.name}</p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>
                    <center>
                        <h4>Cities Upload</h4>
                        <div>
                            <CsvParse
                                keys={citykeys}
                                onDataUploaded={this.handleCityData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.uploadCityData}>Upload </button>
                        </div>
                        <div>
                            {cityData.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.id}</p>
                                        <p>{item.name}</p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>

                    <center>
                        <h4>Areas Upload</h4>
                        <div>
                            <CsvParse
                                keys={areakeys}
                                onDataUploaded={this.handleAreaData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.uploadAreaData}>Upload </button>
                        </div>
                        <div>
                            {areaData.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.id}</p>
                                        <p>{item.name}</p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>

                    <center>
                        <h4>Guest Upload</h4>
                        <div>
                            <CsvParse
                                keys={guestkeys}
                                onDataUploaded={this.handleGuestData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.uploadGuestData}>Guest Upload </button>
                        </div>
                        <div>
                            {guestData.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.fullName} - {item.family_fullname} - {item.phone} - {item.serialNo} </p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>

                    <center>
                        <h4>Guest Category Update</h4>
                        <div>
                            <CsvParse
                                keys={guestcatkeys}
                                onDataUploaded={this.handleGuestCatData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.updateGuestCategory}>Guest Upload </button>
                        </div>
                        <div>
                            {guestCategoryData.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.categoryId} - {item.phone} </p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>

                    <center>
                        <h4>Transport Creation</h4>
                        <div>
                            <CsvParse
                                keys={transportkeys}
                                onDataUploaded={this.handleTransportData}
                                onError={this.handleError}
                                render={onChange => <input type="file" onChange={onChange} />}
                            />
                            <button name="upload" onClick={this.addNewTravelDetails}>Travle Details Upload </button>
                        </div>
                        <div>
                            {transportDetails.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <p>{item.id} - {item.name} </p>
                                    </li>
                                )
                            })}
                        </div>
                    </center>

                </div>
            </div >
        )
    }
}


const CREATE_GUEST_MUTATION = gql`
  mutation CreateGuestMutation(
    $fullName: String!, $cardName: String!, $groupId: ID, $arrivalDate: DateTime, 
    $phone: String, $city: ID, $govtID: String, $govtIdType: ID, $comments: String,
     $noOfPersons: Int!, $statusId: ID!, $categoryId: ID, $isInformed: Boolean!,
     $hotelRequired: Boolean!, $vehicleRequired: Boolean!, $email: String, $subGroupId: ID,
     $modeOfInvitation: ID, $genderId: ID, $serialNo: String,
     $gifts: [ID], $printOver: Boolean!, $majorAreaId: ID, $minorAreaId: ID, $address: String,
     $inviteMode: String
     ) {
    createGuest(fullName: $fullName, cardName: $cardName, groupId: $groupId,
        phone: $phone, city: $city, govtID: $govtID, noOfPersons: $noOfPersons,
        statusId: $statusId, categoryId: $categoryId, arrivalDate: $arrivalDate,
        isInformed: $isInformed, govtIdType: $govtIdType, comments: $comments,
        hotelRequired: $hotelRequired, vehicleRequired: $vehicleRequired,
        email: $email, subGroupId: $subGroupId, modeOfInvitation: $modeOfInvitation,
        genderId: $genderId, serialNo: $serialNo,
        gifts: $gifts, printOver: $printOver, majorAreaId: $majorAreaId, minorAreaId: $minorAreaId,
        address: $address, inviteMode: $inviteMode,
      ) {
      id
       fullName,
       cardName, 
       group{
           id
           name
       }, 
       phone, 
       city {
           id
           name
       }
       modeOfInvitation{
           id
           name
       }
       hotelRequired,
       vehicleRequired,
       isInformed, 
       comments
       govtID,
       govtIDType{
        id
        name
       }, 
       noOfPersons, 
       status{
           id
           name
       }, 
       category{
           id
           name
       }
    }
  }
`

const CREATE_FAMILY_MUTATION = gql`
  mutation CreateFamilyMutation(
    $guestId: ID!,
    $firstName: String!, $lastName: String,
    $phone: String, $govtID: String, $govtIdType: ID,
    $isInformed: Boolean!, $isInvited: Boolean!, $pnr: String 
     ) {
    createFamily(guestId: $guestId, firstName: $firstName, lastName: $lastName, pnr: $pnr,
        phone: $phone, govtID: $govtID, isInformed: $isInformed,
        govtIdType: $govtIdType, isInvited: $isInvited,
      ) {
      id
      firstName, lastName, phone
    }
  }
`
const UPDATE_GUEST_MUTATION = gql`
  mutation updateGuestCategory(
    $phone: String, $categoryId: ID
     ) {
    updateGuestCategory(
        phone: $phone, categoryId: $categoryId
      ) {
      id
      fullName, phone, category{
          id
          name
      }
    }
  }
`

const CREATE_FLIGHT_MUTATION = gql`
  mutation CreateFlightMutation($name: String!, $number: String!, $source: String!, $destination: String!, $startDate: DateTime!, $arrivalDate: DateTime!, $mobile: String, $transportId: String!) {
    createTransportDetails(name: $name, number: $number,
    source: $source, destination: $destination, startDate: $startDate, 
    arrivalDate: $arrivalDate, mobile: $mobile, transportId: $transportId
     ) {
      id
      name
      number
      source
      destination
      startDate
      arrivalDate
      mobile
      transportId {
        id
      }
    }
  }
`

const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroupMutation($name: String!) {
    createGroup(name: $name) {
      id
      name
    }
  }
`
const CREATE_CITY_MUTATION = gql`
  mutation CreateCityMutation($name: String!) {
    createCity(name: $name) {
      id
      name
    }
  }
`

const CREATE_AREA_MUTATION = gql`
  mutation CreateAreaMutation($name: String!) {
    createArea(name: $name) {
      id
      name
    }
  }
`
const GOVTIDTYPES_QUERY = gql`
  query govtIdTypesQuery {
    govtIdTypes {
      id
      name
    }
  }
`

const CREATE_SUBGROUP_MUTATION = gql`
  mutation CreateSUbGroupMutation($name: String!, $groupId: ID!) {
    createSubGroup(name: $name, groupId: $groupId) {
      id
      name
    }
  }
`

const ImportsMutation = compose(
    graphql(CREATE_GROUP_MUTATION, {
        name: 'createGroupMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(UPDATE_GUEST_MUTATION, {
        name: 'updateGuestCategoryMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(CREATE_CITY_MUTATION, {
        name: 'createCityMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(CREATE_AREA_MUTATION, {
        name: 'createAreaMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(CREATE_SUBGROUP_MUTATION, {
        name: 'createSubGroupMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(CREATE_GUEST_MUTATION, {
        name: 'createGuestMutation',
        // name of the injected prop: this.props.createGuestMutation...
    }),
    graphql(CREATE_FAMILY_MUTATION, {
        name: 'createFamilyMutation',
        // name of the injected prop: this.props.createFamilyMutation...
    }),
    graphql(GOVTIDTYPES_QUERY, {
        name: 'govtIdTypesQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
)(CSVImport)

export default withApollo(ImportsMutation)