import React, { Component, Fragment } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import _ from 'lodash'
import {
    Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
    FormGroup, Spinner, TextArea, InputGroup
} from "@blueprintjs/core";
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from 'react-confirm-alert'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import Toast from '../../../utils/Toast'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 400,
    },
});

class VehicleForm extends Component {
    constructor(props) {
        super(props)
        this.handleVehicleEdit = this.handleVehicleEdit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.changeVehicleType = this.changeVehicleType.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.updateState = this.updateState.bind(this)
        this.getVehicleNumbers = this.getVehicleNumbers.bind(this)

        this.state = {
            id: "",
            guestId: this.props.guestId,
            vehicleTypeId: "",
            vehicleCount: 1,
            comments: "",
            open: false,
            message: "",
            loading: false,
            isPooling: false,
            vehicleTypes: [],
            vehicleDetailsId: "",
            updatedCount: "",
            availableCount: "",
            initialVehicleCount: "",
            GuestVehicleDetails: [],
            deleteVehicleTypeId: "",
            randomVehicleNumberId: "",
            vehicleNumbers: []
        }
    }

    async componentDidMount() {
        this.updateState();
    }

    async updateState() {
        const GuestVDetails = await this.props.client.query({
            query: VEHICLE_DETAILS_QUERY,
            variables: { guestId: this.props.guestId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        console.log("uestVDetails.data.guestVehicleDetails", GuestVDetails.data.guestVehicleDetails)
        var GuestVehicleDetails = await GuestVDetails.data.guestVehicleDetails.map((item) => {
            return item
        })

        var initialVehicleCount = await GuestVDetails.data.guestVehicleDetails.map((item) => {
            return item.vehicleCount
        })[0]

        var vehicleTypeId = await GuestVDetails.data.guestVehicleDetails.map((item) => {
            return item.vehicleTypeId
        }).map((item) => { return item.id })[0]

        var vehicleNumberId =  await GuestVDetails.data.guestVehicleDetails.map((item) => {
            return item.vehicleNumberId
        }).map((item) => { return item.id })[0]

        if (vehicleTypeId) {
            const result = await this.props.client.query({
                query: VEHICLE_TYPE_QUERY,
                variables: { id: vehicleTypeId },
                options: {
                    fetchPolicy: 'network-only',
                }
            })
            var availableCount = await result.data.vehicleTypesById.map((item) => {
                return item.availableCount
            })[0]
            this.setState({
                availableCount,
            })
        }

        this.setState({
            GuestVehicleDetails,
            randomVehicleNumberId: vehicleNumberId,
            initialVehicleCount,
            deleteVehicleTypeId: vehicleTypeId
        })
    }

    async changeOperator(e) {
        this.setState({ vehicleDetailsId: e.target.value });

        const result = await this.props.client.query({
            query: VEHICLE_TYPES_QUERY,
            variables: { vehicleDetailsId: e.target.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var vehicleTypes = await _.map(result.data.vehicleTypes).map((item) => {
            return item
        })

        this.setState({ vehicleTypes })
    }


    async changeVehicleType(e) {
        const result = await this.props.client.query({
            query: VEHICLE_TYPE_QUERY,
            variables: { id: e.target.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var availableCount = await result.data.vehicleTypesById.map((item) => {
            return item.availableCount
        })[0]

        this.setState({ vehicleTypeId: e.target.value, availableCount })
        this.getVehicleNumbers()
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    //Getting vehicle Number by vehicleTypedId and assigning a random vehicle number to guest
    async getVehicleNumbers() {
        const vehicleTypeId = this.state.vehicleTypeId;
        const result = await this.props.client.query({
            query: VEHICLE_NUMBERS_QUERY,
            variables: { vehicleTypeId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        const VNS = result.data.vehicleNumbers
        const vNRandomId = VNS.map(item => {
            return item.id
        })
        var vehicleNumbers = VNS.map(item => {
            return { 'label': item.number, 'value': item.id }
        })
        const randomVehicleNumberId = await _.sample(vNRandomId);
        console.log("randomVehicleNumberId", randomVehicleNumberId)
        console.log("vehicleNumbers", vehicleNumbers)
        this.setState({ vehicleNumbers, randomVehicleNumberId });
    }


    handleVehicleEdit = async (item) => {
        const result = await this.props.client.query({
            query: VEHICLE_TYPES_QUERY,
            variables: { vehicleDetailsId: item.vehicleTypeId.vehicleDetailsId.id },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var vehicleTypes = await _.map(result.data.vehicleTypes).map((item) => {
            return item
        })

        this.setState({ vehicleTypes })
        this.setState({
            ...this.state,
            id: item.id,
            vehicleTypeId: item.vehicleTypeId.id,
            guestId: this.props.guestId,
            isPooling: item.isPooling,
            comments: item.comments,
            vehicleCount: item.vehicleCount,
            vehicleDetailsId: item.vehicleTypeId.vehicleDetailsId.id,
            vehicleTypes,
            isEditing: true,
            initialVehicleCount: item.vehicleCount,
        })
    }

    handleConfirmDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.handleDelete(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async handleDelete(id) {
        this.setState({ loading: true })
        const { deleteVehicleTypeId, initialVehicleCount, availableCount, randomVehicleNumberId } = this.state;
        var updatedCount = availableCount + initialVehicleCount;
        await this.props.deleteGuestVehicleDetails({
            variables: { id },
        }).then(async (data) => {
            await this.props.editVehicleTypeMutation({
                variables: {
                    id: deleteVehicleTypeId, availableCount: updatedCount
                },
            }).then(async (data) => {
                await this.props.editVehicleNumberMutation({
                    variables: {
                        id: randomVehicleNumberId, isSingle: false
                    },
                }).catch(error => {
                    this.setState({
                        open: true, loading: false,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
            }).catch(error => {
                this.setState({
                    open: true, loading: false,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            this.props.guestVehicleDetails.refetch();
            await this.resetForm();
            console.log("coming here")
            await this.updateState();
            console.log("coming here again")
            this.setState({
                loading: false, open: true,
                message: "Vehicle Details Updated",
                isEditing: false
            })
        }).catch(error => {
            this.setState({
                open: true, loading: false,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 2000);
        window.location.reload()
    }

    resetForm() {
        this.setState({
            ...this.state,
            id: "",
            vehicleTypeId: "",
            guestId: this.props.guestId,
            vehicleCount: 1,
            comments: "",
            isPooling: false,
            vehicleTypes: [],
            vehicleDetailsId: "",
            GuestVehicleDetails: []
        })
    }
    handleSwitch = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { classes } = this.props;
        const { id, comments, open, message, vehicleTypeId, vehicleCount, vehicleDetailsId,
            loading, vehicleTypes, GuestVehicleDetails } = this.state
        return (
            <div>
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <div className="form-container">

                    <Card className="card-global" elevation={Elevation.TWO}>
                        <h4>Guest Vehicle Details</h4>
                        {
                            this.props.guestVehicleDetails.guestVehicleDetails && this.props.guestVehicleDetails.guestVehicleDetails.map((item) => {
                                return (
                                    <ul key={item.id} className="bp3-list-unstyled">
                                        <li key={item.id}>
                                            <div className="families"> {item.vehicleTypeId ? item.vehicleTypeId.name : ""}
                                                <IconButton className="action-icons" onClick={() => this.handleConfirmDelete(item.id)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                                {/* <IconButton className="action-icons" onClick={() => this.handleVehicleEdit(item)}>
                                                    <EditIcon color="primary" />
                                                </IconButton> */}

                                            </div>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        {(GuestVehicleDetails.length !== 0) && (
                            <div>
                                <h3>Cab Details:</h3>
                                <div>
                                    <Paper className={classes.root}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Operator Name</TableCell>
                                                    <TableCell>Cab Type</TableCell>
                                                    <TableCell>Cab Number</TableCell>
                                                    <TableCell numeric>No Of Cabs</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {GuestVehicleDetails && GuestVehicleDetails.map((item, index) => {
                                                    return (
                                                        <TableRow key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.vehicleTypeId.vehicleDetailsId.name}
                                                            </TableCell>
                                                            <TableCell>{item.vehicleTypeId.name}</TableCell>
                                                            <TableCell>{item.vehicleNumberId.number}</TableCell>
                                                            <TableCell numeric>{item.vehicleCount}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </div>
                            </div>

                        )}
                        {(GuestVehicleDetails.length === 0) && (
                            <form onSubmit={this.handleVehicleDetails} autocomplete="off">
                                <FormGroup
                                    label="Select Operator"
                                    labelFor="selectOperator-input"
                                >
                                    <Select
                                        className="formControl"
                                        value={vehicleDetailsId || ""}
                                        name="selectOperator"
                                        onChange={e => this.changeOperator(e)}
                                    >
                                        {this.props.vehicleDetailses.vehicleDetailses && this.props.vehicleDetailses.vehicleDetailses.map(Vehicle => {
                                            const { id, name } = Vehicle;
                                            return [
                                                <MenuItem value={id.toString()}>{name.toString()} </MenuItem>]
                                        })}
                                    </Select>
                                    <FormHelperText>Select Operator</FormHelperText>
                                </FormGroup>
                                <FormGroup
                                    label="Select Cab"
                                    labelFor="selectCab-input"
                                >
                                    <Select
                                        className="formControl"
                                        value={vehicleTypeId || ""}
                                        name="selectCab"
                                        onChange={e => this.changeVehicleType(e)}
                                    >
                                        {vehicleTypes && vehicleTypes.map(VehicleType => {
                                            const { id, name, availableCount } = VehicleType;
                                            return [
                                                <MenuItem value={id.toString()}>{name ? name.toString() : ""} | {availableCount ? availableCount.toString() : ""}</MenuItem>]
                                        })}}
                                </Select>
                                    <FormHelperText>Select Cab</FormHelperText>
                                </FormGroup>
                                {/* <FormGroup
                                    label="No Of Vehicles"
                                    labelFor="vehicleCount-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="vehicleCount-input" value={vehicleCount}
                                        required="required"
                                        name="vehicleCount"
                                        onChange={event => this.setState({ vehicleCount: event.target.value })}
                                        placeholder="Vehicle Count" />
                                </FormGroup> */} 
                                <FormGroup
                                    label="Comments"
                                    labelFor="hotel-input"
                                    className="switchControl"
                                >
                                    <TextArea
                                        className="bp3-fill"
                                        large={true}
                                        intent={Intent.PRIMARY}
                                        onChange={event => this.setState({ comments: event.target.value })}
                                        value={comments}
                                    />
                                </FormGroup>

                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        {id ? <Button type="submit" intent={Intent.PRIMARY} >
                                            Update
                                        </Button> :
                                            <Button type="submit" intent={Intent.PRIMARY} >
                                                Save
                                    	</Button>
                                        }
                                    </div>
                                </div>
                            </form>
                        )}
                    </Card>
                </div>
            </div>
        )
    }

    handleVehicleDetails = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        const { id, comments, vehicleTypeId, vehicleCount,
            guestId, isPooling, availableCount, randomVehicleNumberId } = this.state
        var updatedCount = availableCount - vehicleCount
        if (id) {
            await this.props.editGuestVehicleMutation({
                variables: {
                    id, comments, vehicleTypeId, vehicleCount,
                    isPooling
                },
            }).then(async (data) => {
                await this.props.editVehicleTypeMutation({
                    variables: {
                        id: vehicleTypeId, availableCount: updatedCount
                    },
                }).catch(error => {
                    this.setState({
                        open: true, loading: false,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
                this.props.guestVehicleDetails.refetch();
                await this.resetForm();
                console.log("coming here")
                await this.updateState();
                this.setState({
                    loading: false, open: true,
                    message: "Vehicle Details Updated",
                    isEditing: false
                })
            }).catch(error => {
                this.setState({
                    open: true, loading: false,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 2000);
            window.location.reload()

        } else {
            await this.props.createGuestVehicleMutation({
                variables: {
                    comments, vehicleTypeId, vehicleCount,
                    guestId, isPooling, vehicleNumberId: randomVehicleNumberId
                },
            }).then(async (data) => {
                await this.props.editVehicleTypeMutation({
                    variables: {
                        id: vehicleTypeId, availableCount: updatedCount
                    },
                }).then(async (data) => {
                    await this.props.editVehicleNumberMutation({
                        variables: {
                            id: randomVehicleNumberId, isSingle: true
                        },
                    }).catch(error => {
                        this.setState({
                            open: true, loading: false,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                }).catch(error => {
                    this.setState({
                        open: true, loading: false,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
                await this.resetForm();
                console.log("coming here")
                await this.updateState();
                this.props.guestVehicleDetails.refetch();
                this.setState({
                    loading: false, open: true,
                    message: "Vehicle Details Added",
                    isEditing: false
                })
            }).catch(error => {
                this.setState({
                    open: true, loading: false,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 2000);
            window.location.reload()

        }
    }
}


const VEHICLE_DETAILS_QUERY = gql`
  query GuestVehicleDetails($guestId: ID!) {
    guestVehicleDetails(guestId: $guestId){
        id
        guestId{ 
            id 
          fullName
        }
        vehicleNumberId{
            id
            number
        }
        vehicleTypeId {
            id
            name
            vehicleDetailsId{
                id
                name
            }
        }
        vehicleCount
        isPooling
        comments
    }
  }
`
const CREATE_GUEST_VEHICLE_MUTATION = gql`
          mutation createGuestVehicleMutation(
            $guestId: ID!,
            $comments: String, $vehicleTypeId: ID!, $isPooling: Boolean!,
            $vehicleCount: Int, $vehicleNumberId: ID
     ){
    createGuestVehicleDetails(guestId: $guestId, comments: $comments, vehicleNumberId: $vehicleNumberId,
        vehicleTypeId: $vehicleTypeId, isPooling: $isPooling, vehicleCount: $vehicleCount
      ) {
      id
    }
  }
`
const EDIT_GUEST_VEHICLE_MUTATION = gql`
          mutation editGuestVehicleMutation(
            $id: ID!,
            $comments: String, $vehicleTypeId: ID!, $isPooling: Boolean!,
            $vehicleCount: Int, $vehicleNumberId: ID
     ){
    updateGuestVehicleDetails(id: $id, comments: $comments,
        vehicleTypeId: $vehicleTypeId, isPooling: $isPooling,
        vehicleCount: $vehicleCount, vehicleNumberId: $vehicleNumberId,
      ) {
      id
    }
  }
`

const EDIT_VEHICLE_TYPE_MUTATION = gql`
  mutation EditVehicleTypes($id: ID!, $availableCount: Int) {
    updateVehicleType(id: $id, availableCount: $availableCount) {
      id
      name
    }
  }
`


const VEHICLE_TYPES_QUERY = gql`
  query VehicleTypesQuery($vehicleDetailsId: ID!) {
    vehicleTypes(vehicleDetailsId: $vehicleDetailsId) {
      id
      name
      totalCount
      availableCount
      comments
    }
  }
`
//Vehicle Numbers Query
const VEHICLE_NUMBERS_QUERY = gql`
  query VehicleNumbers($vehicleTypeId: ID!) {
    vehicleNumbers(vehicleTypeId: $vehicleTypeId) {
      id
     number
    }
  }
`

//Vehicle Numbers Editing for single 
const EDIT_VEHICLE_NUMBER_MUTATION = gql`
  mutation EditVehicleTypes($id: ID!, $isSingle: Boolean) {
    updateVehicleNumbers(id: $id, isSingle: $isSingle,) {
      id
      number
    }
  }
`

const VEHICLE_TYPE_QUERY = gql`
 query VehicleTypeQuery($id: ID!) {
    vehicleTypesById(id: $id) {
      id
      name
      totalCount
      availableCount
    }
  }
`
const VEHICLE_QUERY = gql`
  query vehicleDetailses{
    vehicleDetailses{
      id
      name
      mobile
      comments
    }
  }
`
const DELETE_GUEST_VEHICLE_DETAILS = gql`
  mutation DeleteGuestVehicleDetails($id: ID!) {
    deleteGuestVehicleDetails(id: $id) {
        id
    }
}
`

const VehiclFormMutation = compose(
    graphql(VEHICLE_QUERY, {
        name: 'vehicleDetailses',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(EDIT_VEHICLE_TYPE_MUTATION, {
        name: 'editVehicleTypeMutation', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(EDIT_VEHICLE_NUMBER_MUTATION, {
        name: 'editVehicleNumberMutation', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(DELETE_GUEST_VEHICLE_DETAILS, {
        name: 'deleteGuestVehicleDetails', // name of the injected prop: this.props.deleteFamilyMutation...
    }),
    graphql(VEHICLE_DETAILS_QUERY, {
        name: 'guestVehicleDetails',
        options: (props) => ({
            variables: {
                guestId: props.guestId
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(CREATE_GUEST_VEHICLE_MUTATION, {
        name: 'createGuestVehicleMutation',
        options: {
            refetchQueries: ['vehicleDetailsQuery', 'guestVehicleDetails']
        }
        // name of the injected prop: this.props.createVehicleMutation...
    }),
    graphql(EDIT_GUEST_VEHICLE_MUTATION, {
        name: 'editGuestVehicleMutation',
        options: {
            refetchQueries: ['vehicleDetailsQuery', 'guestVehicleDetails']
        }
        // name of the injected prop: this.props.createVehicleMutation...
    }),
)(VehicleForm)

const VehicleFormStyles = withStyles(styles)(VehiclFormMutation);

export default withApollo(VehicleFormStyles)