import React, { Component } from "react"
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button, Intent, Classes, FormGroup, InputGroup,
    Card, Elevation, TextArea, NumericInput
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import moment from 'moment'
import { DateTimePicker } from 'material-ui-pickers'

class EditNewHotelPage extends Component {

    constructor(props) {
        super(props)
        this.handleHotel = this.handleHotel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            name: this.props.name,
            id: this.props.id,
            checkIn: this.props.checkIn,
            checkOut: this.props.checkOut,
            totalRooms: this.props.totalRooms,
            address: this.props.address,
            email: this.props.email
        };
    }

    handleDateChange = (date) => {
        this.setState({ checkOut: date });
    }

    handleValueChange = (_valueAsNumber, valueAsString) => {
        this.setState({ totalRooms: valueAsString });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { id, name, checkIn, checkOut, totalRooms, address, email } = this.state
        return (
            <div className="form-content">
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        EDIT Hotel
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                    <form onSubmit={this.handleHotel}>
                    <input type="hidden" value={this.state.id || ''} />
                            <FormGroup
                                label="Name"
                                labelFor="name-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="name-input" value={name}
                                    required="required"
                                    name="name"
                                    onChange={this.handleChange}
                                    placeholder="Name of Hotel" />
                            </FormGroup>
                            <FormGroup
                                label="Email"
                                labelFor="email-input"
                            >
                                <InputGroup id="email-input" value={email}
                                    name="email"
                                    onChange={this.handleChange}
                                    placeholder="Email of Hotel" />
                            </FormGroup>
                            <FormGroup
                                label="Address"
                                labelFor="address-input"
                                labelInfo="(required)"
                            >
                                <TextArea
                                    large={true}
                                    name="address"
                                    intent={Intent.PRIMARY}
                                    onChange={this.handleChange}
                                    value={address}
                                    rows="10"
                                />
                            </FormGroup>
                            <FormGroup
                                label="Rooms"
                                labelFor="room-input"
                                labelInfo="(required)"
                            >
                                <NumericInput
                                    value={totalRooms}
                                    name="totalrooms"
                                    placeholder="Enter a number..."
                                    onValueChange={this.handleValueChange} />
                            </FormGroup>
                            <FormGroup
                                label="Check In"
                                labelFor="checkin-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={checkIn}
                                    onChange={(e) => { this.setState({ checkIn: moment(e).toJSON() }) }}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Check Out"
                                labelFor="checkout-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={checkOut}
                                    onChange={(e) => { this.setState({ checkOut: moment(e).toJSON() }) }}
                                />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

    handleHotel = async e => {
        e.preventDefault()
        const { id, name, checkIn, checkOut, totalRooms, address, email } = this.state
        console.log("Editing", this.state)
        await this.props.editHotelMutation({
            variables: { id, name, checkIn, checkOut, totalRooms, address, email },
        })
        this.props.goBack();
    }
}

const EDIT_Hotel_MUTATION = gql`
  mutation EditHotelMutation($id: ID!, $name: String!,
    $checkIn: DateTime!, $checkOut: DateTime!, $totalRooms: Int!, $address: String!, $email: String
  ) {
    updateHotelDetails(id: $id, name: $name, checkIn: $checkIn, checkOut: $checkOut,
     totalRooms: $totalRooms, address: $address, email: $email) {
      id
      name
      checkIn
      checkOut
      totalRooms
      address
      email
    }
  }
`
const EditHotelWithMutation = graphql(EDIT_Hotel_MUTATION, {
    name: 'editHotelMutation', // name of the injected prop: this.props.editHotelMutation...
})(EditNewHotelPage)

export default withRouter(EditHotelWithMutation)