import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MUIDataTable from 'mui-datatables'
import { Spinner } from "@blueprintjs/core"
import AddNewHotelPage from './AddNewHotelPage'
import EditNewHotelPage from './EditNewHotelPage'
import CustomAddIcon from '../../constants/CustomAddIcon'
import CustomToolbarSelect from '../../constants/CustomToolbarSelect'
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'

const columns = [
  {
    name: "Name",
    key: "name",
    options: {
      filter: true,
      sort: true, 
    }
  },
  {
    name: "Address",
    key: "address",
    options: {
      filter: true,
      sort: true, 
    }
  },
  {
    name: "Total Rooms",
    key: "totalRooms",
    options: {
      filter: true,
      sort: true, 
    }
  },
  {
    name: "Check In",
    key: "checkIn",
    options: {
      filter: true,
      sort: true, 
    }
  },
  {
    name: "Check Out",
    key: "checkOut",
    options: {
      filter: true,
      sort: true, 
    }
  },
  {
    name: "Actions",
    key: "action",
    options: {
      filter: false,
      sort: false,
    }
  },
];

class Hotel extends Component {
  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
    this.goBack = this.goBack.bind(this)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isHotel: false,
      isHotelEditing: false,
      id: "",
      name: "",
      checkIn: "",
      checkOut: "",
      totalRooms: "",
      address: ""
    }
  }

  goBack() {
    this.props.hotelQuery.refetch()
    this.setState({
      isHotel: false,
      isHotelEditing: false,
    })
  }

  refresh() {
    this.props.hotelQuery.refetch()
  }

  handleEdit(name, id, checkIn, checkOut, totalRooms, address) {
    this.setState({
      isHotelEditing: true,
      id: id,
      name: name,
      checkIn: checkIn, 
      checkOut: checkOut, 
      totalRooms: totalRooms,
      address: address
    })
  }

  async handleDelete(id) {
    await this.props.deleteHotelMutation({
      variables: { id },
    })
    this.props.hotelQuery.refetch()
  }

  handleConfirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      isHotel: true
    })
  }


  render() {
    const { id, name, checkIn, checkOut, totalRooms, address } = this.state
    if (this.props.hotelQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      <div>
        {this.state.isHotel ?
          <AddNewHotelPage goBack={this.goBack} />
          :
          this.state.isHotelEditing ?
            <EditNewHotelPage id={id} name={name} checkIn={checkIn}
            checkOut={checkOut} address={address} totalRooms={totalRooms}
            goBack={this.goBack} />
            :
            <MUIDataTable
              title={"Hotels"}
              data={this.props.hotelQuery.hotelDetailses && this.props.hotelQuery.hotelDetailses.map(hotel => {
                const { name, id, checkIn, checkOut, totalRooms, address } = hotel;
                return [name.toString(), address.toString(),
                   totalRooms.toString(),
                   moment(checkIn).format('D MMMM YYYY'),
                   moment(checkOut).format('D MMMM YYYY'),
                  <CustomToolbarSelect
                  handleEdit={() => this.handleEdit(name, id, checkIn, checkOut, totalRooms, address)}
                  handleDelete={() => this.handleConfirmDelete(id)}
                />];
              })}
              columns={columns}
              options={{
                filter: true,
                selectableRows: false,
                filter: false,
                filterType: 'dropdown',
                responsive: 'stacked',
                rowsPerPage: 5,
                customToolbar: () => <CustomAddIcon title="ADD Hotel" refresh={this.refresh} handler={this.handler} />
              }}
            />
        }
      </div>
    )

  }
}

const DELETE_HOTEL_MUTATION = gql`
  mutation DeleteHotelMutation($id: ID!) {
    deleteHotelDetails(id: $id) {
      id
    }
  }
`

const HOTEL_QUERY = gql`
  query HotelQuery {
    hotelDetailses {
      id
      name
      checkIn
      checkOut
      totalRooms
      address
    }
  }
`
export default compose(
  graphql(HOTEL_QUERY, {
    name: 'hotelQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(DELETE_HOTEL_MUTATION, {
    name: 'deleteHotelMutation', // name of the injected prop: this.props.deleteHotelMutation...
  }),
)(Hotel)

