import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MUIDataTable from 'mui-datatables'
import { Spinner } from "@blueprintjs/core"
import CustomAddIcon from '../../constants/CustomAddIcon'
import { confirmAlert } from 'react-confirm-alert'
import AddNewGuestPage from './AddGuest/AddNewGuestPage'
import GuestDetail from './EditGuest/GuestDetail'
import moment from 'moment'
import { browserHistory } from 'react-router-dom'
import Toast from '../../utils/Toast'
import GuestToolbar from '../../constants/GuestToolbar';

const columns = [
    {
        name: "Serial No",
        key: "serialNo",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Guest Name",
        key: "fullName",
        options: {
            filter: true,
            sort: true,
        }
    },
    // {
    //     name: "Spouse Name",
    //     key: "family.firstName",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    {
        name: "Card Name",
        key: "cardName",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Phone Number",
        key: "phone",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Persons",
        key: "noOfPersons",
        options: {
            filter: true,
            sort: true,
            display: false,
        }
    },
    {
        name: "City",
        key: "city",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Group",
        key: "group.name",
        options: {
            filter: true,
            sort: true,
        }
    },
    // {
    //     name: "Sub Group",
    //     key: "subGroup.name",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    {
        name: "Status",
        key: "status.name",
        options: {
            filter: true,
            sort: true,
        }
    },

    // {
    //     name: "Informed",
    //     key: "isInformed",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Print Over",
    //     key: "printOver",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Category",
    //     key: "category.name",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Invite Mode",
    //     key: "inviteMode",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Hotel Required",
    //     key: "hotelRequired",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Minor Area",
    //     key: "minorArea.name",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Major Area",
    //     key: "majorArea.name",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Address",
    //     key: "address",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    // {
    //     name: "Comments",
    //     key: "comments",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         display: false,
    //     }
    // },
    {
        name: "Actions",
        key: "action",
        options: {
            filter: false,
            sort: false,
            download: false,
        }
    },

];

class Guest extends Component {
    constructor(props) {
        super(props)
        this.handler = this.handler.bind(this)
        // this.handleEdit = this.handleEdit.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.goBack = this.goBack.bind(this)
        this.refresh = this.refresh.bind(this)

        this.state = {
            isGuest: false,
            isGuestEditing: false,
            id: "",
            open: false,
            message: "",
            loading: false,
        }
    }

    goBack() {
        this.props.guestQuery.refetch()
        this.setState({
            isGuest: false,
            isGuestEditing: false,
        })
    }

    refresh() {
        this.props.guestQuery.refetch()
    }

    handleEdit(id) {
        this.props.history.push(`/editguest/${id}`)
    }

    handleView(id) {
        this.setState({
            isGuestEditing: true,
            id: id,
        })
    }

    async handleDelete(id) {
        this.setState({ loading: true })
        await this.props.deleteGuestMutation({
            variables: { id },
        }).then((data) => {
            this.setState({
                loading: false, open: true,
                message: "Guest deleted",
            })
        }).catch(error => {
            this.setState({
                open: true, loading: false,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
        this.props.guestQuery.refetch()
    }

    handleConfirmDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.handleDelete(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    handler(e) {
        this.props.history.push("/addnewguest")
    }

    render() {
        const { id, open, message, loading } = this.state
        if (this.props.guestQuery.loading) {
            return (
                <div className="flex w-100 h-100 items-center justify-center pt7">
                    <Spinner intent="primary" size={100} />
                </div>
            )
        }

        return (
            <div>
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                {this.state.isGuest ?
                    <AddNewGuestPage goBack={this.goBack} />
                    :
                    <MUIDataTable
                        title={"Guests"}
                        data={this.props.guestQuery.guests && this.props.guestQuery.guests.map(guest => {
                            const { id, fullName, cardName, noOfPersons, isInformed, city, group, comments,
                                status, category, serialNo, phone, printOver, family,
                                majorArea, minorArea, address, subGroup, inviteMode, hotelRequired
                            } = guest;
                            return [
                                (serialNo ? serialNo.toString() : ""),
                                (fullName ? fullName.toString() : ""),
                                // (family ? family.map((item)=> item.firstName).toString() : ""),
                                (cardName ? cardName.toString() : ""),
                                (phone ? phone.toString() : ""),
                                (noOfPersons ? noOfPersons.toString() : ""),
                                (city ? city.name.toString() : ""),
                                (group ? group.name.toString() : ""),
                                // (subGroup ? subGroup.name.toString() : ""),
                                (status ? status.name.toString() : ""),
                                // (isInformed === false? "No" : "Yes"),
                                // (printOver === false? "No" : "Yes"),
                                // (category? category.name.toString(): ""),
                                // (inviteMode? inviteMode.toString(): ""),
                                // (hotelRequired === false? "No" : "Yes"),
                                // (minorArea ? minorArea.name.toString() : ""),
                                // (majorArea ? majorArea.name.toString() : ""),
                                // (address? address.toString(): ""),
                                // (comments? comments.toString(): ""),
                                <GuestToolbar
                                    // handleView={() => this.handleView(id)}
                                    handleEdit={() => this.handleEdit(id)}
                                    handleDelete={() => this.handleConfirmDelete(id)}
                                />,

                            ];
                        })}
                        columns={columns}
                        options={{
                            filter: true,
                            download: true,
                            print: false,
                            selectableRows: false,
                            filterType: 'dropdown',
                            responsive: 'scroll',
                            rowsPerPageOptions: [25, 50, 100],
                            rowsPerPage: 25,
                            customToolbar: () => <CustomAddIcon title="ADD Guest" refresh={this.refresh} handler={this.handler} />
                        }}
                    />
                }
            </div>
        )

    }
}

const DELETE_GUEST_MUTATION = gql`
  mutation DeleteGuestMutation($id: ID!) {
    deleteGuest(id: $id) {
      id
    }
  }
`

const GUEST_QUERY = gql`
  query GuestQuery($orderBy: GuestOrderByInput) {
    guests(orderBy: $orderBy) {
       id, fullName, cardName, comments, phone,
       noOfPersons, 
    #    isInformed,
       serialNo,
        # printOver,
        # inviteMode, hotelRequired, 
        # address
    city{
        id
        name
       }
    status{
      id
      name
    }
    # family{
    #     id
    #     firstName
    # }
    group{ 
        id
        name 
    }
    # subGroup{
    #     id
    #     name
    # },
    # category{
    #     id
    #     name
    # }
    # majorArea{
    #     id
    #     name
    # }
    # minorArea{
    #     id
    #     name
    # }
    }
  }
`

export default compose(
    graphql(GUEST_QUERY, {
        name: 'guestQuery',
        options: () => ({
            variables: { orderBy: "createdAt_DESC" },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(DELETE_GUEST_MUTATION, {
        name: 'deleteGuestMutation', // name of the injected prop: this.props.deleteGuestMutation...
    }),
)(Guest)

