import React from 'react'
import ReactDOM from 'react-dom'
import { HttpLink, InMemoryCache, ApolloClient } from 'apollo-client-preset'
import { ApolloLink, split } from 'apollo-link'
import { AUTH_TOKEN } from './constant'
import RootContainer from './components/RootContainer'
import { ApolloProvider } from 'react-apollo'
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import 'tachyons'
import 'react-confirm-alert/src/react-confirm-alert.css'
import './index.css'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: { "common": { "black": "#000", "white": "#fff" }, "background": { "paper": "#fff", "default": "#fafafa" }, "primary": { "light": "rgba(76, 154, 202, 1)", "main": "rgba(16, 107, 163, 1)", "dark": "rgba(4, 85, 136, 1)", "contrastText": "#fff" }, "secondary": { "light": "rgba(92, 88, 89, 1)", "main": "rgba(74, 74, 74, 1)", "dark": "rgba(22, 5, 13, 1)", "contrastText": "#fff" }, "error": { "light": "#e57373", "main": "rgba(220, 18, 41, 1)", "dark": "rgba(240, 7, 7, 1)", "contrastText": "#fff" }, "text": { "primary": "rgba(0, 0, 0, 0.87)", "secondary": "rgba(0, 0, 0, 0.54)", "disabled": "rgba(0, 0, 0, 0.38)", "hint": "rgba(0, 0, 0, 0.38)" } }
});

const httpLink = new HttpLink({ uri: 'https://blockofcha.in/' })

const middlewareLink = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const tokenValue = localStorage.getItem(AUTH_TOKEN)
  // return the headers to the context so httpLink can read them
  operation.setContext({
    headers: {
      Authorization: tokenValue ? `Bearer ${tokenValue}` : '',
    },
  })
  return forward(operation)
})

// authenticated httplink
const httpLinkAuth = middlewareLink.concat(httpLink)

// const wsLink = new WebSocketLink({
//   uri: `ws://localhost:4000/`,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
//     },
//   },
// })

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query)
//     return kind === 'OperationDefinition' && operation === 'subscription'
//   },
//   wsLink,
//   httpLinkAuth,
// )

// apollo client setup
const client = new ApolloClient({
  link: ApolloLink.from([httpLinkAuth]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})
LogRocket.init('bhc8ou/kms')
setupLogRocketReact(LogRocket);
const token = localStorage.getItem(AUTH_TOKEN)


ReactDOM.render(
  <ApolloProvider client={client}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <RootContainer token={token} />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)