import React, { Component, Fragment } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import _, { mapKeys } from 'lodash'
import Select from 'react-select';
import {
    Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
    FormGroup, Spinner, TextArea, InputGroup
} from "@blueprintjs/core";
import MultiSelect from "@kenshooui/react-multi-select";
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from 'react-confirm-alert'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import BackIcon from "@material-ui/icons/ArrowBack";
import BButton from '@material-ui/core/Button';


import Toast from '../../utils/Toast'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 400,
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    backButton: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    completed: {
        display: 'inline-block',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
});

class VehicleForm extends Component {
    constructor(props) {
        super(props)
        this.handleVehicleEdit = this.handleVehicleEdit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.resetForm = this.resetForm.bind(this)
        this.changeVehicleType = this.changeVehicleType.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.updateState = this.updateState.bind(this)
        this.handleSubGroupChange = this.handleSubGroupChange.bind(this)
        this.handleGuestIDS = this.handleGuestIDS.bind(this)
        this.handleGuestPool = this.handleGuestPool.bind(this)
        this.goBack = this.goBack.bind(this)
        this.getVehicleNumbers = this.getVehicleNumbers.bind(this)
        this.updateRandomVehicleNumber = this.updateRandomVehicleNumber.bind(this)

        // this.toogleGroups = this.toogleGroups.bind(this)
        this.state = {
            id: this.props.id,
            guestIDS: [],
            selectedGuestIDS: [],
            vehicleTypeId: "",
            vehicleCount: "",
            comments: "",
            open: false,
            message: "",
            loading: false,
            isPooling: false,
            vehicleTypes: [],
            vehicleDetailsId: "",
            updatedCount: "",
            availableCount: "",
            initialVehicleCount: "",
            GuestVehicleDetails: [],
            deleteVehicleTypeId: "",
            guestDetails: [],
            groupId: "",
            subGroupId: "",
            subGroups: [],
            vehiclePoolId: "",
            selectedFlightsId: "",
            flights: [],
            randomVehicleNumberIDS: [],
        }
    }

    async componentDidMount() {
        this.updateState();
    }

    async updateState() {
        if (this.props.id) {
            const PoolDetails = await this.props.client.query({
                query: VEHICLE_POOL_QUERY,
                variables: { id: this.props.id },
                options: {
                    fetchPolicy: 'network-only',
                }
            })

            var guestDetails = await _.map(PoolDetails.data.vehiclePoolById).map((item) => {
                const guest = item.guestIDS;
                return guest
            })

            var GuestIDS = await _.map(guestDetails).map((item) => {
                return item.map((gd) => {
                    return {
                        'label': gd.fullName,
                        'id': gd.id
                    }
                }
                )
            })
            var selectedGuestIDS = _.flatten(GuestIDS);

            var GuestVehicleDetails = await PoolDetails.data.vehiclePoolById.map((item) => {
                return item.totalVehiclesId
            })



            this.setState({
                selectedGuestIDS,
                GuestVehicleDetails,
                // initialVehicleCount,
                // deleteVehicleTypeId: vehicleTypeId
            })
        }
    }

    async changeOperator(e) {
        this.setState({ vehicleDetailsId: e, vehicleTypes: [], vehicleTypeId: "" });

        const result = await this.props.client.query({
            query: VEHICLE_TYPES_QUERY,
            variables: { vehicleDetailsId: e.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var vehicleTypes = await _.map(result.data.vehicleTypes).map((item) => {
            return item
        })

        this.setState({ vehicleTypes })
    }


    async changeVehicleType(e) {
        const result = await this.props.client.query({
            query: VEHICLE_TYPE_QUERY,
            variables: { id: e.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var availableCount = await result.data.vehicleTypesById.map((item) => {
            return item.availableCount
        })[0]

        this.setState({ vehicleTypeId: e, availableCount })
        this.getVehicleNumbers()
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleGuestIDS(selectedGuestIDS) {
        this.setState({ selectedGuestIDS });
    }

    handleVehicleEdit = async (item) => {
        const result = await this.props.client.query({
            query: VEHICLE_TYPES_QUERY,
            variables: { vehicleDetailsId: item.vehicleTypeId.vehicleDetailsId.id },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var vehicleTypes = await _.map(result.data.vehicleTypes).map((item) => {
            return item
        })

        this.setState({ vehicleTypes })
        this.setState({
            ...this.state,
            id: item.id,
            vehicleTypeId: item.vehicleTypeId.id,
            guestId: this.props.guestId,
            isPooling: item.isPooling,
            comments: item.comments,
            vehicleCount: item.vehicleCount,
            vehicleDetailsId: item.vehicleTypeId.vehicleDetailsId.id,
            vehicleTypes,
            isEditing: true,
            initialVehicleCount: item.vehicleCount,
        })
    }

    handleConfirmDelete = (id, vehicleTypeId, vehicleNumbers, vehicleCount) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.handleDelete(id, vehicleTypeId, vehicleNumbers, vehicleCount)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async handleGroupChange(e) {
        var groupId = ""
        if (e) {
            groupId = e.value
            this.setState({ groupId: e, subGroupId: [] });
        } else {
            this.setState({ groupId: "", subGroupId: [] });
        }
        const result = await this.props.client.query({
            query: SUB_GROUP_QUERY,
            variables: { groupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var subGroups = await _.map(result.data.subGroups).map((item) => {
            return item
        }).map((sg) => {
            return { 'value': sg.id, 'label': sg.name }
        })
        this.setState({ subGroups })


        const GuestDetails = await this.props.client.query({
            query: GUEST_GROUP_DETAILS_QUERY,
            variables: { groupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var guestDetails = await _.map(GuestDetails.data.guestByGroupId).map((item) => {
            return {
                'label': item.fullName, 'id': item.id,
            }
        })
        console.log("guestDetails", guestDetails)
        this.setState({ guestIDS: guestDetails })
    }

    async handleSelectedFlights(e) {
        var selectedFlightsId = ""
        if (e) {
            selectedFlightsId = e.value
            this.setState({ selectedFlightsId: e, guestDetails: [] });
        } else {
            this.setState({ selectedFlightsId: "", guestDetails: [] });
        }
        const result = await this.props.client.query({
            query: GUEST_FLIGHT_DETAILS_QUERY,
            variables: { flightId: selectedFlightsId },
            options: {
                fetchPolicy: 'network-only',
            }
        })

        var guestDetails = await _.map(result.data.guestByFlightId).map((item) => {
            return item.guestId
        }).map((gt) => {
            return {
                'label': gt.fullName, 'id': gt.id,
            }
        })

        this.setState({ guestIDS: guestDetails })
    }

    async handleSubGroupChange(e) {
        var subGroupId = ""
        if (e) {
            subGroupId = e.value
            this.setState({ subGroupId: e, guestDetails: [] });
        } else {
            this.setState({ subGroupId: "", guestDetails: [] });
        }
        const result = await this.props.client.query({
            query: GUEST_SUBGROUP_DETAILS_QUERY,
            variables: { subGroupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })

        var guestDetails = await _.map(result.data.guestBySubGroupId).map((item) => {
            return {
                'label': item.fullName, 'id': item.id,
            }
        })
        this.setState({ guestIDS: guestDetails })
    }

    //Getting vehicle Number by vehicleTypedId and assigning a random vehicle number to guest
    async getVehicleNumbers() {
        const vehicleTypeId = this.state.vehicleTypeId.value;

        const result = await this.props.client.query({
            query: VEHICLE_NUMBERS_QUERY,
            variables: { vehicleTypeId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        const VNS = result.data.vehicleNumbers
        const vNRandomId = VNS.map(item => {
            return item.id
        })
        this.setState({ vehicleNumbers: vNRandomId });
    }

    async updateRandomVehicleNumber(e) {
        this.setState({ vehicleCount: e.target.value })
        const vehicleCount = e.target.value;
        var vNRandomId = this.state.vehicleNumbers;
        const randomVehicleNumberIDS = await _.sampleSize(vNRandomId, vehicleCount);
        console.log("randomVehicleNumberIDS", randomVehicleNumberIDS)
        this.setState({ randomVehicleNumberIDS });
    }

    handleGuestPool = async e => {
        e.preventDefault()
        console.log("coming to pool")
        this.setState({ loading: true })
        const { id, selectedGuestIDS, } = this.state

        var guestIDS = selectedGuestIDS.map(item => {
            return item.id
        })
        console.log("guestIDS", guestIDS)
        if (id) {
            await this.props.updateVehiclePoolGuests({
                variables: {
                    id
                },
            }).then(async ({ data }) => {
                await this.props.updateVehiclePool({
                    variables: {
                        guestIDS,
                        id,
                    },
                }).then(async ({ data }) => {
                    this.setState({
                        open: true,
                        vehiclePoolId: data.updateVehiclePool.id,
                        id: data.updateVehiclePool.id,
                        message: "Guests pool updated",
                        loading: false
                    })
                }).catch(error => {
                    this.setState({
                        open: true,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })

        } else {
            await this.props.createVehiclePool({
                variables: {
                    guestIDS
                },
            }).then(async ({ data }) => {
                //Create Guest vehicles loop guestIDS.lenght < 1
                for (var i = 0; i < selectedGuestIDS.length; i++) {
                    const { id } = selectedGuestIDS[i]
                    await this.props.createGuestVehicleMutation({
                        variables: {
                            guestId: id, isPooling: true, cabPoolId: data.createVehiclePool.id
                        },
                    }).then(async (data) => {
                        this.setState({
                            open: true,
                            message: "Added Vehicle Pooling for Guest"
                        })
                    }).catch(error => {
                        this.setState({
                            open: true, loading: false,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                }
                this.setState({
                    open: true,
                    vehiclePoolId: data.createVehiclePool.id,
                    id: data.createVehiclePool.id,
                    message: "Guests are pooled",
                    loading: false
                })
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
        }
        await setTimeout(() => this.setState({ open: false }), 2000);
    }


    async handleDelete(id, vehicleTypeId, vehicleNumbers, vehicleCount) {
        this.setState({ loading: true })
        console.log("vehicleTypeId from delete", vehicleTypeId)
        console.log("vehicleNumbers", vehicleNumbers)
        if (vehicleTypeId) {
            const result = await this.props.client.query({
                query: VEHICLE_TYPE_QUERY,
                variables: { id: vehicleTypeId },
                options: {
                    fetchPolicy: 'network-only',
                }
            })
            var availableCount = await result.data.vehicleTypesById.map((item) => {
                return item.availableCount
            })[0]
        }
        var updatedCount = availableCount + vehicleCount;
        await this.props.deleteTotalVehicles({
            variables: { id },
        }).then(async (data) => {
            await this.props.editVehicleTypeMutation({
                variables: {
                    id: vehicleTypeId, availableCount: updatedCount
                },
            }).then(async (data) => {
                for (var i = 0; i < vehicleNumbers.length; i++) {
                    const { id } = vehicleNumbers[i]
                    await this.props.editVehicleNumberMutation({
                        variables: {
                            id, isPooled: false
                        },
                    }).catch(error => {
                        this.setState({
                            open: true, loading: false,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                }
            }).catch(error => {
                this.setState({
                    open: true, loading: false,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            this.props.totalVehiclesQuery.refetch();
            await this.resetForm();
            await this.updateState();
            this.setState({
                loading: false, open: true,
                message: "Vehicle Details Updated",
                isEditing: false
            })
        }).catch(error => {
            this.setState({
                open: true, loading: false,
                message: "Error occured " + error,
                loading: false
            })
        })
        window.location.reload();
        await setTimeout(() => this.setState({ open: false }), 2000);
    }

    resetForm() {
        this.setState({
            ...this.state,
            id: "",
            vehicleTypeId: "",
            guestId: this.props.guestId,
            vehicleCount: "",
            comments: "",
            isPooling: false,
            vehicleTypes: [],
            vehicleDetailsId: "",
            GuestVehicleDetails: []
        })
    }
    handleSwitch = name => event => {
        this.setState({ [name]: event.target.checked });
    };
    goBack() {
        this.props.goBack()
    }

    render() {
        const { classes } = this.props;
        const { id, comments, open, message, vehicleTypeId, vehicleCount, vehicleDetailsId, guestIDS, selectedGuestIDS,
            loading, vehicleTypes, GuestVehicleDetails, guestDetails,
            selectedFlightsId, flights,
            groupId, subGroupId, subGroups } = this.state
        return (
            <div className="stepper-content">
                <BButton variant="contained" color="primary" className={classes.button} onClick={this.goBack}>
                    <Icon className={classes.leftIcon}>
                        <BackIcon> </BackIcon>
                    </Icon>
                    Back
                    </BButton>
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <div className="form-container">

                    <Card className="card-guests" elevation={Elevation.ONE}>
                        <h2 style={{ textAlign: "center" }}>Cab Pooling</h2>
                        <form onSubmit={this.handleGuestPool}>
                            <div>
                                <div className="form-groups">
                                    <div className="form-selects">
                                        <FormGroup
                                            label="Group"
                                            labelFor="group-input"
                                        >
                                            <Select
                                                className="basic-single select-control"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                name="group"
                                                isClearable={true}
                                                value={groupId}
                                                onChange={(e) => { this.handleGroupChange(e) }}
                                                options={this.props.groupQuery.groups && this.props.groupQuery.groups.map((item) => {
                                                    return { 'value': item.id, 'label': item.name }
                                                })}
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            label="Sub Group"
                                            labelFor="subgroup-input"
                                        >
                                            <Select
                                                className="basic-single select-control"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                name="subGroup"
                                                isClearable={true}
                                                value={subGroupId}
                                                onChange={(e) => this.handleSubGroupChange(e)}
                                                options={subGroups}
                                            />
                                        </FormGroup>

                                        <FormGroup
                                            label="Select Flights"
                                            labelFor="selflights-input"
                                        >
                                            <Select
                                                className="basic-single select-control"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                name="selflights"
                                                isClearable={true}
                                                value={selectedFlightsId}
                                                onChange={(e) => this.handleSelectedFlights(e)}
                                                options={this.props.flightQuery.flights && this.props.flightQuery.flights.map((item) => {
                                                    return { 'value': item.id, 'label': item.name }
                                                })}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                                <FormGroup
                                    label="Select Guests"
                                    labelFor="sguest-input"
                                >
                                    <MultiSelect
                                        items={guestIDS}
                                        selectedItems={selectedGuestIDS}
                                        onChange={this.handleGuestIDS}
                                    />
                                </FormGroup>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        {id ? <Button type="submit" intent={Intent.PRIMARY} >
                                            Update Pool
                                        </Button> :
                                            <Button type="submit" intent={Intent.PRIMARY} >
                                                Pool
                                    	</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr />
                        {(this.props.totalVehiclesQuery.totalVehiclesById && this.props.totalVehiclesQuery.totalVehiclesById.lenght !== 0) && (
                            <div>
                                <h3>Cabs Assigned:</h3>
                                <div>
                                    <Paper className={classes.root}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Operator Name</TableCell>
                                                    <TableCell>Cab Type</TableCell>
                                                    <TableCell>Cab Numbers</TableCell>
                                                    <TableCell numeric>No Of Cabs</TableCell>
                                                    <TableCell numeric>Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.totalVehiclesQuery.totalVehiclesById && this.props.totalVehiclesQuery.totalVehiclesById.map((item, index) => {
                                                    return (
                                                        <TableRow key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.vehicleTypeId? item.vehicleTypeId.vehicleDetailsId.name: ""}
                                                            </TableCell>
                                                            <TableCell>{item.vehicleTypeId? item.vehicleTypeId.name: ""}</TableCell>
                                                            <TableCell>{item.vehicleNumberIDS.map((item) => `${item.number} | `)}</TableCell>
                                                            <TableCell numeric>{item.vehicleCount}</TableCell>
                                                            <TableCell>
                                                                <IconButton className="action-icons" onClick={() => this.handleConfirmDelete(item.id,
                                                                    item.vehicleTypeId.id, item.vehicleNumberIDS,
                                                                    item.vehicleCount
                                                                )}>
                                                                    <DeleteIcon color="error" /></IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </div>
                            </div>

                        )}
                        <form onSubmit={this.handleVehicleDetails} autocomplete="off">
                            <div style={{ paddingTop: 50 }} className="form-groups">
                                <FormGroup
                                    label="Select Operator"
                                    labelFor="selectOperator-input"
                                >
                                    <Select
                                        className="formControl"
                                        value={vehicleDetailsId || ""}
                                        name="selectOperator"
                                        onChange={e => this.changeOperator(e)}
                                        options={this.props.vehicleDetailses.vehicleDetailses && this.props.vehicleDetailses.vehicleDetailses.map((item) => {
                                            return { 'value': item.id, 'label': item.name }
                                        })}
                                    >
                                    </Select>
                                </FormGroup>
                                <FormGroup
                                    label="Select Cab"
                                    labelFor="selectCab-input"
                                >
                                    <Select
                                        className="formControl"
                                        value={vehicleTypeId || ""}
                                        name="selectCab"
                                        onChange={e => this.changeVehicleType(e)}
                                        options={vehicleTypes && vehicleTypes.map((item) => {
                                            return { 'value': item.id, 'label': `${item.name} | ${item.availableCount}` }
                                        })}
                                    >
                                    </Select>
                                </FormGroup>
                            </div>
                            <div className="form-groups">
                                <FormGroup
                                    label="No Of Vehicles"
                                    labelFor="vehicleCount-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="vehicleCount-input" value={vehicleCount}
                                        required="required"
                                        name="vehicleCount"
                                        onChange={event => this.updateRandomVehicleNumber(event)}
                                        placeholder="Vehicle Count" />
                                </FormGroup>
                                <FormGroup
                                    label="Comments"
                                    labelFor="hotel-input"
                                    className="switchControl"
                                >
                                    <TextArea
                                        className="bp3-fill"
                                        large={true}
                                        intent={Intent.PRIMARY}
                                        onChange={event => this.setState({ comments: event.target.value })}
                                        value={comments}
                                    />
                                </FormGroup>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                                    	</Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        )
    }


    handleVehicleDetails = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        const { id, comments, vehicleTypeId, vehicleCount, selectedGuestIDS,
            availableCount, randomVehicleNumberIDS, } = this.state
            if (id && randomVehicleNumberIDS.length > 0) {
            var updatedCount = availableCount - vehicleCount
            console.log("randomVehicleNumberIDS.length", randomVehicleNumberIDS.length)
            console.log("vehicleCount", vehicleCount)
            if(randomVehicleNumberIDS.length == vehicleCount) {
                console.log("coming here")
            await this.props.createTotalVehicles({
                variables: {
                    vehicleTypeId: vehicleTypeId.value, vehicleCount: vehicleCount, VehiclePoolId: id,
                    vehicleNumberIDS: randomVehicleNumberIDS,
                }
            }).then(async ({ data }) => {

                await this.props.editVehicleTypeMutation({
                    variables: {
                        id: vehicleTypeId.value, availableCount: updatedCount
                    },
                }).then(async (data) => {
                    for (var i = 0; i < randomVehicleNumberIDS.length; i++) {
                        const { id } = randomVehicleNumberIDS[i]
                        await this.props.editVehicleNumberMutation({
                            variables: {
                                id: randomVehicleNumberIDS[i], isPooled: true
                            },
                        }).catch(error => {
                            this.setState({
                                open: true, loading: false,
                                message: "Error occured " + error,
                                loading: false
                            })
                        })
                    }
                }).catch(error => {
                    console.log("error", error)
                    this.setState({
                        open: true, loading: false,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
                this.setState({
                    loading: false, open: true,
                    message: "Vehicle Details Added",
                    isEditing: false
                })

            }).catch(error => {
                console.log("error", error)
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            this.resetForm();
            this.props.totalVehiclesQuery.refetch();
            window.location.reload();
        } else {
            this.setState({
                open: true,
                message: "Reduce the vehicle count",
                loading: false
            })
        }
        } else {
            this.setState({
                open: true,
                message: "Please pool the guests selected above first or check vehicles are available to pool",
                loading: false
            })
        }
        await setTimeout(() => this.setState({ open: false }), 2000);
    }
}

//VehiclePool Mutations
const CREATE_VEHICLEPOOL_MUTATION = gql`
  mutation CreateVehiclePool($guestIDS: [ID]!, $totalVehiclesId: [ID]) {
    createVehiclePool(guestIDS: $guestIDS, totalVehiclesId: $totalVehiclesId) {
      id
      guestIDS{
          id
          fullName
      }
    }
  }
`

const UPDATE_VEHICLEPOOL_GUESTS_MUTATION = gql`
  mutation UpdateVehiclePoolGuests($id: ID!) {
    updateVehiclePoolGuests(id: $id) {
      id
    }
  }
`

const EDIT_VEHICLEPOOL_MUTATION = gql`
  mutation UpdateVehiclePool($id: ID!, $guestIDS: [ID], $totalVehiclesId: [ID]) {
    updateVehiclePool(id: $id, guestIDS: $guestIDS, totalVehiclesId: $totalVehiclesId) {
      id
      guestIDS{
          id
          fullName
      }
    }
  }
`
//Total Vehicles Mutations
const CREATE_TOTALVEHICLES_MUTATION = gql`
  mutation CreateTotalVehicles($vehicleTypeId: ID, $vehicleCount: Int, $VehiclePoolId: ID, $vehicleNumberIDS: [ID]) {
    createTotalVehicles(vehicleTypeId: $vehicleTypeId, vehicleCount: $vehicleCount, VehiclePoolId: $VehiclePoolId, vehicleNumberIDS: $vehicleNumberIDS) {
      id
    }
  }
`
const EDIT_TOTALVEHICLES_MUTATION = gql`
  mutation EditTotalVehicles($vehicleTypeId: ID, $vehicleCount: Int, $VehiclePoolId: ID, $vehicleNumberIDS: [ID]) {
    updateTotalVehicles(vehicleTypeId: $vehicleTypeId, vehicleCount: $vehicleCount, VehiclePoolId: $VehiclePoolId, vehicleNumberIDS: $vehicleNumberIDS) {
      id
    }
  }
`
const DELETE_TOTALVEHICLES_MUTATION = gql`
  mutation DeleteTotalVehicles($id: ID!) {
    deleteTotalVehicles(id: $id) {
        id
    }
}
`


const VEHICLE_DETAILS_QUERY = gql`
  query GuestVehicleDetails($guestId: ID!) {
    guestVehicleDetails(guestId: $guestId){
        id
        guestId{ 
            id 
          fullName
        }
        vehicleTypeId {
            id
            name
            vehicleDetailsId{
                id
                name
            }
        }
        vehicleCount
        isPooling
        comments
    }
  }
`

const TOTAL_VEHICLES_QUERY = gql`
  query TotalVehiclesQuery($VehiclePoolId: ID!) {
    totalVehiclesById(VehiclePoolId: $VehiclePoolId) {
      id
      vehicleTypeId{
          id
          name
          vehicleDetailsId{
              id
              name
          }
      }
      vehicleCount
      vehicleNumberIDS{ 
          id
          number
      }
    }
  }
`

const CREATE_GUEST_VEHICLE_MUTATION = gql`
          mutation createGuestVehicleMutation(
            $guestId: ID!,
            $comments: String, $vehicleTypeId: ID, $isPooling: Boolean!,
            $vehicleCount: Int, $cabPoolId: ID
     ){
    createGuestVehicleDetails(guestId: $guestId, comments: $comments,
        vehicleTypeId: $vehicleTypeId, isPooling: $isPooling, vehicleCount: $vehicleCount,
        cabPoolId: $cabPoolId
      ) {
      id
      guestId{
          id
          fullName
      }
    }
  }
`
const EDIT_GUEST_VEHICLE_MUTATION = gql`
          mutation editGuestVehicleMutation(
            $id: ID!,
            $comments: String, $vehicleTypeId: ID, $isPooling: Boolean!,
            $vehicleCount: Int, $cabPoolId: ID
     ){
    updateGuestVehicleDetails(id: $id, comments: $comments,
        vehicleTypeId: $vehicleTypeId, isPooling: $isPooling,
        vehicleCount: $vehicleCount, cabPoolId: $cabPoolId
      ) {
      id
    }
  }
`

const EDIT_VEHICLE_TYPE_MUTATION = gql`
  mutation EditVehicleTypes($id: ID!, $availableCount: Int) {
    updateVehicleType(id: $id, availableCount: $availableCount) {
      id
      name
    }
  }
`

const VEHICLE_TYPES_QUERY = gql`
  query VehicleTypesQuery($vehicleDetailsId: ID!) {
    vehicleTypes(vehicleDetailsId: $vehicleDetailsId) {
      id
      name
      totalCount
      availableCount
      comments
    }
  }
`
const VEHICLE_TYPE_QUERY = gql`
 query VehicleTypeQuery($id: ID!) {
    vehicleTypesById(id: $id) {
      id
      name
      totalCount
      availableCount
    }
  }
`
const VEHICLE_NUMBERS_QUERY = gql`
  query VehicleNumbers($vehicleTypeId: ID!) {
    vehicleNumbers(vehicleTypeId: $vehicleTypeId) {
      id
     number
    }
  }
`


const VEHICLE_QUERY = gql`
  query vehicleDetailses{
    vehicleDetailses{
      id
      name
      mobile
      comments
    }
  }
`

const VEHICLE_POOL_QUERY = gql`
  query vehiclePoolById($id: ID!){
    vehiclePoolById(id: $id){
      id
      guestIDS {
         id
         fullName
     }
     totalVehiclesId {
         id
         vehicleCount
         vehicleTypeId {
             id
             name
             comments
         }
     }
    }
  }
`

const DELETE_GUEST_VEHICLE_DETAILS = gql`
  mutation DeleteGuestVehicleDetails($id: ID!) {
    deleteGuestVehicleDetails(id: $id) {
        id
    }
}
`

const GROUP_QUERY = gql`
  query GroupQuery {
    groups {
      id
      name
    }
  }
`

const FLIGHTS_QUERY = gql`
  query FlightQuery {
    flights {
      id
      name
    }
  }
`
const GUEST_GROUP_DETAILS_QUERY = gql`
  query GuestGroupQuery($groupId: ID!) {
    guestByGroupId(groupId: $groupId) {
      id
      fullName
    }
  }
`
const GUEST_SUBGROUP_DETAILS_QUERY = gql`
  query GuestSubGroupQuery($subGroupId: ID!) {
    guestBySubGroupId(subGroupId: $subGroupId) {
      id
      fullName
    }
  }
`
const GUEST_FLIGHT_DETAILS_QUERY = gql`
  query GuestFlightQuery($flightId: ID!) {
    guestByFlightId(flightId: $flightId) {
      id
      guestId{
          id
      fullName
      }
    }
  }
`

const SUB_GROUP_QUERY = gql`
  query SubGroupQuery($groupId: ID!) {
    subGroups(groupId: $groupId) {
      id
      name
    }
  }
`
//Vehicle Numbers Editing for pooling through loop 
const EDIT_VEHICLE_NUMBER_MUTATION = gql`
  mutation EditVehicleNumbers($id: ID!, $isPooled: Boolean) {
    updateVehicleNumbers(id: $id, isPooled: $isPooled,) {
      id
      number
    }
  }
`

const VehiclFormMutation = compose(
    graphql(VEHICLE_QUERY, {
        name: 'vehicleDetailses',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    //Vehicle Pool
    graphql(CREATE_VEHICLEPOOL_MUTATION, {
        name: 'createVehiclePool', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(EDIT_VEHICLEPOOL_MUTATION, {
        name: 'updateVehiclePool', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(UPDATE_VEHICLEPOOL_GUESTS_MUTATION, {
        name: 'updateVehiclePoolGuests', // name of the injected prop: this.props.editVehicleMutation...
    }),

    //Total Vehicles
    graphql(CREATE_TOTALVEHICLES_MUTATION, {
        name: 'createTotalVehicles', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(EDIT_TOTALVEHICLES_MUTATION, {
        name: 'editTotalVehicles', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(DELETE_TOTALVEHICLES_MUTATION, {
        name: 'deleteTotalVehicles', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(TOTAL_VEHICLES_QUERY, {
        name: 'totalVehiclesQuery',
        options: (props) => ({
            variables: {
                VehiclePoolId: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(EDIT_VEHICLE_NUMBER_MUTATION, {
        name: 'editVehicleNumberMutation', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(VEHICLE_POOL_QUERY, {
        name: 'pooledGuestsQuery',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(EDIT_VEHICLE_TYPE_MUTATION, {
        name: 'editVehicleTypeMutation', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(DELETE_GUEST_VEHICLE_DETAILS, {
        name: 'deleteGuestVehicleDetails', // name of the injected prop: this.props.deleteFamilyMutation...
    }),
    // graphql(VEHICLE_DETAILS_QUERY, {
    //     name: 'guestVehicleDetails',
    //     options: (props) => ({
    //         variables: {
    //             guestId: props.guestId
    //         },
    //         fetchPolicy: 'cache-and-network',
    //     }),
    // }),
    graphql(GROUP_QUERY, {
        name: 'groupQuery',
        options: {
            fetchPolicy: 'network-only',
        },
    }),
    graphql(FLIGHTS_QUERY, {
        name: 'flightQuery',
        options: {
            fetchPolicy: 'network-only',
        },
    }),
    graphql(CREATE_GUEST_VEHICLE_MUTATION, {
        name: 'createGuestVehicleMutation',
        options: {
            refetchQueries: ['vehicleDetailsQuery', 'guestVehicleDetails']
        }
        // name of the injected prop: this.props.createVehicleMutation...
    }),
    graphql(EDIT_GUEST_VEHICLE_MUTATION, {
        name: 'editGuestVehicleMutation',
        options: {
            refetchQueries: ['vehicleDetailsQuery', 'guestVehicleDetails']
        }
        // name of the injected prop: this.props.createVehicleMutation...
    }),
)(VehicleForm)

const VehicleFormStyles = withStyles(styles)(VehiclFormMutation);

export default withApollo(VehicleFormStyles)