import React, { Component, Fragment } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import _ from 'lodash'
import {
    Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
    FormGroup, InputGroup, Radio, RadioGroup, Spinner
} from "@blueprintjs/core";
import IconButton from "@material-ui/core/IconButton";
import Switch from '@material-ui/core/Switch';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from 'react-confirm-alert'
import Toast from '../../../utils/Toast'

class FamilyForm extends Component {
    constructor(props) {
        super(props)
        this.resetForm = this.resetForm.bind(this);
        this.state = {
            id: "",
            gfirstName: "",
            glastName: "",
            gphone: "",
            govtIdType: "",
            ggovtID: "",
            gpnr: "",
            isInformed: "",
            isInvited: "",
            guestId: this.props.guestId,
            open: false,
            loading: false,
            familyDetails: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ id: nextProps.id });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleHtmlSelectChange = (event) => {
        this.setState({ govtIdType: event.target.value });
    };

    handleSwitch = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    handleFamilyEdit = (item) => {
        this.setState({
            id: item.id,
            gfirstName: item.firstName,
            glastName: item.lastName,
            gphone: item.phone,
            govtIdType: item.govtIDType.id,
            ggovtID: item.govtID,
            gpnr: item.pnr,
            isInformed: item.isInformed,
            isInvited: item.isInvited,
            guestId: this.props.guestId,
        })
    }

    resetForm() {
        this.setState({
            ...this.state,
            id: "",
            gfirstName: "",
            glastName: "",
            gphone: "",
            govtIdType: "",
            ggovtID: "",
            gpnr: "",
            isInformed: false,
            isInvited: false,
        })
    }

    handleSelectChange(values) {
        // send selection options array to state
        this.setState({ ...this.state, values });
        // pull selected values into array
        const vals = _.map(values, 'value');
        // join array into comma separated string for redux form
        this.props.onChange(vals.join(','));
    }

    handleConfirmDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.handleDelete(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async handleDelete(id) {
        await this.props.deleteFamilyMutation({
            variables: { id },
        })
    }

    render() {
        const { id, gfirstName, glastName, govtIdType,
            gphone, ggovtID, gpnr, message,
            isInformed, isInvited, open, loading, familyDetails } = this.state
        return (
            <div>
                <Toast open={open} message={message} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <div className="form-container">

                    <Card className="card-global" elevation={Elevation.TWO}>
                        <h4>Family Members</h4>
                        {
                            this.props.guestFamiliesQuery.families && this.props.guestFamiliesQuery.families.map((item) => {
                                return (
                                    <ul className="bp3-list-unstyled">
                                        <li key={item.id}>
                                            <div className="families"> {item.firstName}
                                                <IconButton className="action-icons" onClick={() => this.handleFamilyEdit(item)}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                <IconButton className="action-icons" onClick={() => this.handleConfirmDelete(item.id)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        <center><h4>{id ? "Edit" : "Add"} Family Member</h4></center>
                        <form onSubmit={this.handleFamily} autocomplete="off">
                            <div>
                                <InputGroup type="hidden" id="id-input" value={id}
                                    name="id" />
                                <input type="hidden" value={id} />
                                <div className="form-groups">
                                    <FormGroup
                                        label="Family Name"
                                        labelFor="gfirstName-input"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup id="gfirstName-input" value={gfirstName}
                                            required="required"
                                            name="gfirstName"
                                            autoComplete="new-fname"
                                            onChange={this.handleChange}
                                            placeholder="Enter family name" />
                                    </FormGroup>
                                    {/* <FormGroup
                                        label="Last Name"
                                        labelFor="glastName-input"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup id="glastName-input" value={glastName}
                                            required="required"
                                            name="glastName"
                                            autoComplete="new-flname"
                                            onChange={this.handleChange}
                                            placeholder="Last Name of FAMILY" />
                                    </FormGroup> */}

                                    <FormGroup
                                        label="Phone"
                                        labelFor="gphone-input"
                                    >
                                        <InputGroup id="gphone-input" value={gphone}
                                            name="gphone"
                                            autoComplete="new-fphone"
                                            onChange={this.handleChange}
                                            placeholder="Enter phone number" />
                                    </FormGroup>
                                </div>
                                <div className="form-groups">
                                    <FormGroup
                                        label="PNR"
                                        labelFor="gpnr-input"
                                    >
                                        <InputGroup id="gpnr-input" value={gpnr}
                                            name="gpnr"
                                            autoComplete="new-fpnr"
                                            onChange={this.handleChange}
                                            placeholder="PNR Number" />
                                    </FormGroup>
                                    <div className="switch-groups">
                                        <FormGroup
                                            label="Informed"
                                            labelFor="informed-input"
                                            className="switchControl"
                                        >
                                            <Switch
                                                checked={isInformed}
                                                onChange={this.handleSwitch('isInformed')}
                                                value="isInformed"
                                                color="primary"
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            label="Invited"
                                            labelFor="invited-input"
                                            className="switchControl"
                                        >
                                            <Switch
                                                checked={isInvited}
                                                onChange={this.handleSwitch('isInvited')}
                                                value="isInvited"
                                                color="primary"
                                            />
                                        </FormGroup>
                                    </div>
                                </div>

                                <FormGroup
                                    label="Government ID"
                                    labelFor="ggovtID-input"
                                >
                                    <ControlGroup>
                                        <HTMLSelect
                                            options={this.props.govtIdTypesQuery.govtIdTypes && this.props.govtIdTypesQuery.govtIdTypes.map((item) => {
                                                return { 'label': item.name, 'value': item.id }
                                            })}
                                            value={govtIdType}
                                            onChange={this.handleHtmlSelectChange}
                                        />
                                        <InputGroup
                                            id="ggovtID-input"
                                            value={ggovtID}
                                            name="ggovtID"
                                            placeholder="Enter identity number"
                                            onChange={this.handleChange} />
                                    </ControlGroup>

                                </FormGroup>

                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Button type="button" onClick={this.resetForm} intent={Intent.WARNING} >
                                            Reset
                                                     </Button>
                                        {id ? <Button type="submit" intent={Intent.PRIMARY} >
                                            Update
                                                     </Button> :
                                            <Button type="submit" intent={Intent.PRIMARY} >
                                                ADD
                                                     </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        )
    }

    handleFamily = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        if (this.state.govtIdType === "") {
            var govtType = await _.map(this.props.govtIdTypesQuery.govtIdTypes).map((item) => {
                return item
            }).map((pr) => {
                if (pr.name === "Select") {
                    return pr.id
                }
            })[0];
            this.setState({ govtIdType: govtType })
            // govtIdType = govtType;
        }
        const { id, gfirstName, glastName, govtIdType,
            gphone, ggovtID, gpnr,
            isInformed, isInvited, guestId } = this.state

        if (id) {
            await this.props.editFamilyMutation({
                variables: {
                    id, firstName: gfirstName, lastName: glastName, govtIdType,
                    phone: gphone, govtID: ggovtID, pnr: gpnr,
                    isInformed, isInvited, guestId
                },
            }).then((data) => {
                this.resetForm();
                this.setState({
                    loading: false, open: true,
                    message: "Family " + data.data.createFamily.firstName + " updated",
                })
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 6000);
        } else {
            await this.props.createFamilyMutation({
                variables: {
                    firstName: gfirstName, lastName: glastName, govtIdType,
                    phone: gphone, govtID: ggovtID, pnr: gpnr,
                    isInformed, isInvited, guestId
                },
            }).then((data) => {
                this.resetForm();
                this.setState({
                    loading: false, open: true,
                    message: "Family " + data.data.createFamily.firstName + " created",
                })
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 6000);
        }
    }
}


const CREATE_FAMILY_MUTATION = gql`
          mutation CreateFamilyMutation(
            $guestId: ID!,
            $firstName: String!, $lastName: String,
            $phone: String, $govtID: String, $govtIdType: ID,
            $isInformed: Boolean!, $isInvited: Boolean!, $pnr: String
     ) {
                    createFamily(guestId: $guestId, firstName: $firstName, lastName: $lastName, pnr: $pnr,
                    phone: $phone, govtID: $govtID, isInformed: $isInformed,
                    govtIdType: $govtIdType, isInvited: $isInvited,
      ) {
                    id
      firstName, lastName, phone,
              }
            }
          `

const EDIT_FAMILY_MUTATION = gql`
            mutation EditFamilyMutation(
              $guestId: ID!,
              $id: ID!,
              $firstName: String!, $lastName: String,
              $phone: String, $govtID: String, $govtIdType: ID,
              $isInformed: Boolean!, $isInvited: Boolean!, $pnr: String
     ) {
                    updateFamily(id: $id, guestId: $guestId, firstName: $firstName, lastName: $lastName, pnr: $pnr,
                    phone: $phone, govtID: $govtID, isInformed: $isInformed,
                    govtIdType: $govtIdType, isInvited: $isInvited,
      ) {
                    id
      firstName, lastName, phone
              }
            }
          `
const DELETE_FAMILY_MUTATION = gql`
  mutation DeleteFamilyMutation($id: ID!) {
                    deleteFamily(id: $id) {
                    id
                }
                }
              `

const GOVTIDTYPES_QUERY = gql`
  query govtIdTypesQuery {
                    govtIdTypes {
                id
                name
              }
            }
          `
const FAMALIES_QUERY = gql`
  query guestFamiliesQuery($guestId: ID!) {
                    families(guestId: $guestId) {
                    id, firstName, lastName, govtIDType {
                    id
            name
                },
                phone, govtID, pnr,
                isInformed, isInvited
            }
          }
        `
const STATUS_QUERY = gql`
  query StatusQuery {
                    attending {
                id
                name
              }
            }
          `
const FamilyFormMutation = compose(

    graphql(GOVTIDTYPES_QUERY, {
        name: 'govtIdTypesQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(STATUS_QUERY, {
        name: 'statusQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(FAMALIES_QUERY, {
        name: 'guestFamiliesQuery',
        options: (props) => ({
            variables: {
                guestId: props.guestId
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(CREATE_FAMILY_MUTATION, {
        name: 'createFamilyMutation',
        options: {
            refetchQueries: ['guestFamilyQuery', 'guestFamiliesQuery']
        }
        // name of the injected prop: this.props.createFamilyMutation...
    }),
    graphql(EDIT_FAMILY_MUTATION, {
        name: 'editFamilyMutation',
        options: {
            refetchQueries: ['guestFamilyQuery', 'guestFamiliesQuery']
        }
        // name of the injected prop: this.props.editFamilyMutation...
    }),
    graphql(DELETE_FAMILY_MUTATION, {
        name: 'deleteFamilyMutation', // name of the injected prop: this.props.deleteFamilyMutation...
        options: {
            refetchQueries: ['guestFamilyQuery', 'guestFamiliesQuery']
        }
    }),
)(FamilyForm)

export default withApollo(FamilyFormMutation);