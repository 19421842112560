import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Spinner } from "@blueprintjs/core"
import {
  Button,
  Classes, Intent, Card, Elevation,
  FormGroup, InputGroup
} from "@blueprintjs/core";
import { DateTimePicker } from 'material-ui-pickers';

class CommonDetail extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.handleCheckInDateChange = this.handleCheckInDateChange.bind(this)

    this.state = {
      id: this.props.commondetailQuery.id,
      defaultCheckIn: this.props.commondetailQuery.defaultCheckIn,
      defaultCheckOut: this.props.commondetailQuery.defaultCheckOut,
      marriageDay: this.props.commondetailQuery.marriageDay,
    }
  }

  refresh() {
    this.props.commondetailQuery.refetch()
  }

  handleCheckInDateChange(name, id) {
    this.setState({
      isCategoryEditing: true,
      id: id,
      name: name,
    })
  }

  render() {
    if (this.props.commondetailQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      // this.props.commondetailQuery.commonDetailses && this.props.commondetailQuery.commonDetailses.map(commondetail => {
      //   <div className="form-content">
      //     <div>
      //       <p>{commondetail.defaultCheckOut}</p>
      //       <p>{commondetail.defaultCheckOut}</p>
      //     </div>
      //     <center>
      //       <p className="master-title">
      //         Common Details
      //       </p>
      //     </center>
      //     <div>
      //       <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
      //         <form onSubmit={this.handleCategory}>
      //           <input type="hidden" value={this.state.id || ''} />

      //           <FormGroup
      //             label="Default CheckIn"
      //             labelFor="text-input"
      //             labelInfo="(required)"
      //           >

      //             <DateTimePicker
      //               clearable
      //               value={this.state.defaultCheckIn}
      //               onChange={(e) => { e !== null && this.setState({ defaultCheckIn: e.toJSON() }) }}
      //             />
      //           </FormGroup>
      //           <FormGroup
      //             label="Default Checkout"
      //             labelFor="text-input"
      //             labelInfo="(required)"
      //           >
      //             <InputGroup id="text-input"
      //               value={this.props.commondetailQuery.defaultCheckOut || ''}
      //               required="required"
      //               onChange={this.handleEditChange}
      //               placeholder="Name of category" />
      //           </FormGroup>
      //           <FormGroup
      //             label="Wedding Day"
      //             labelFor="text-input"
      //             labelInfo="(required)"
      //           >
      //             <InputGroup id="text-input"
      //               value={this.state.marriageDay || ''}
      //               required="required"
      //               onChange={this.handleEditChange}
      //               placeholder="Name of category" />
      //           </FormGroup>
      //           <div className={Classes.DIALOG_FOOTER}>
      //             <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      //               <Button type="submit" intent={Intent.PRIMARY} >
      //                 Update
      //               </Button>
      //             </div>
      //           </div>
      //         </form>
      //       </Card>
      //     </div>
      //   </div>
      // })
      <div></div>
    )
  }
}

const COMMONDETAIL_QUERY = gql`
  query CommonDetailQuery($id: ID!){
    commonDetailses(id: $id) {
    defaultCheckIn
    defaultCheckOut
    marriageDay
    }
  }
`
export default compose(
  graphql(COMMONDETAIL_QUERY, {
    name: 'commondetailQuery',
    options: {
      variables: { id: "cjn98e8jfng440b777ede1xv0" },
      fetchPolicy: 'cache-and-network',
    },
  }),
)(CommonDetail)

