import React, { Component } from "react";
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import {
    Card, Elevation, Spinner
} from "@blueprintjs/core";
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import moment from 'moment'
import BackIcon from "@material-ui/icons/ArrowBack";
import Icon from '@material-ui/core/Icon';

import GuestForm from './GuestForm'
import FamilyForm from './FamilyForm'
import TravelForm from "./TravelForm";
import VehicleForm from "./VehicleForm";
import HotelForm from "./HotelForm";

const styles = theme => ({

    content: {
        marginTop: '10px',
    },
    familyName: {
        display: 'block',
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    guestOverview: {
        width: '100%',
        maxWidth: 360,
        float: 'left',
        paddingTop: '60px',
        backgroundColor: theme.palette.background.paper,
    },
    overview: {
        width: '40%',
        float: 'left',
        margin: '20px',
        paddingTop: '60px'
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    button: {
        marginRight: theme.spacing.unit,
    },
    backButton: {
        marginRight: theme.spacing.unit,
    },
    completed: {
        display: 'inline-block',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    guestEdit: {
        width: '50%',
        float: 'right',
    },
});

class GuestDetail extends Component {
    constructor(props) {
        super(props)
        this.guestQuery = this.guestQuery.bind(this)
        this.state = {
            familyOpen: false,
            guestOpen: false,
            travelOpen: false,
            hotelOpen: false,
            guestEdit: true,
            familyEdit: false,
            travelEdit: false,
            vehicleEdit: false,
            hotelEdit: false,
            vehicleOpen: false,
            familyId: "",
            selectedIndex: 0,
        };
    }

    handleGuestClick = (event, index) => {
        this.setState(state => ({ guestOpen: !state.guestOpen, selectedIndex: index }));
    };

    handleFamilyClick = (event, index) => {
        this.setState(state => ({ familyOpen: !state.familyOpen, selectedIndex: index }));
    };
    handleTravelClick = (event, index) => {
        this.setState(state => ({ travelOpen: !state.travelOpen, selectedIndex: index }));
    }
    handleHotelClick = (event, index) => {
        this.setState(state => ({ hotelOpen: !state.hotelOpen, selectedIndex: index }));
    }
    handleVehicleClick = (event, index) => {
        this.setState(state => ({ vehicleOpen: !state.vehicleOpen, selectedIndex: index }));
    }
    guestQuery() {
        this.props.guestQuery.refetch();
    }

    render() {
        const { classes } = this.props;
        const { guestEdit, familyEdit, travelEdit, hotelEdit, vehicleEdit, selectedIndex } = this.state;

        if (this.props.guestQuery.loading) {
            return (
                <Spinner intent="primary" size={50} />
            )
        }
        return (
            <div className={classes.content}>
                <div>
                    {
                        this.props.guestQuery.guest && this.props.guestQuery.guest.map((item) => {
                            return (
                                <div key={item.id}>
                                    <div className={classes.guestOverview}>
                                        <Typography variant="h5" component="h3">
                                            <center>Guest Overview</center>
                                        </Typography>
                                        <List
                                            component="nav"
                                            subheader={<ListSubheader color="primary" component="div">Guest Details</ListSubheader>}
                                        >
                                            <ListItem selected={selectedIndex === 0} button onClick={event => this.handleGuestClick(event, 0)}>
                                                <ListItemText inset primary={`${item.fullName}`} />
                                                <IconButton onClick={() => this.setState({
                                                    guestEdit: true,
                                                    familyEdit: false,
                                                    travelEdit: false,
                                                    vehicleEdit: false,
                                                    hotelEdit: false,
                                                })}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                {this.state.guestOpen ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.guestOpen} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <ListItem button className={classes.nested}> Serial Number:  {`${item.serialNo ? item.serialNo : ""}`}</ListItem>
                                                    <ListItem button className={classes.nested}> No Of Persons:  {`${item.noOfPersons}`}</ListItem>
                                                    <ListItem button className={classes.nested}> City: {`${item.city ? item.city.name : ""}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Government ID: {`${item.govtID ? item.govtID : ""}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Phone Number: {`${item.phone}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Gender: {`${item.gender ? item.gender.name : ""}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Email: {`${item.email}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Attending: {`${item.status ? item.status.name : ""}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Category: {`${item.category ? item.category.name : ""}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Group: {`${item.group ? item.group.name : ""}`}</ListItem>
                                                    {(item.subGroup) && (<ListItem button className={classes.nested}> Sub Group: {`${item.subGroup ? item.subGroup.name : ""}`}</ListItem>)}
                                                    <ListItem button className={classes.nested}> Arrival Date: {moment(item.arrivalDate).format('D MMMM YYYY')}</ListItem>
                                                    <ListItem button className={classes.nested}> Guest Invite Mode: {item.inviteMode}</ListItem>
                                                    <ListItem button className={classes.nested}> Guest Informed: {item.isInformed ? "Yes" : "No"}</ListItem>
                                                    <ListItem button className={classes.nested}> Guest Invited: {item.isInvited ? "Yes" : "No"}</ListItem>
                                                    <ListItem button className={classes.nested}> Vehicle Required: {item.vehicleRequired ? "Yes" : "No"}</ListItem>
                                                    <ListItem button className={classes.nested}> Hotel Required: {item.hotelRequired ? "Yes" : "No"}</ListItem>
                                                    <ListItem button className={classes.nested}> Print Over: {item.printOver ? "Yes" : "No"}</ListItem>
                                                    <ListItem button className={classes.nested}> Gifts: {item.gifts.map((item) => {
                                                        return (<span>{item.name}</span>)
                                                    })}</ListItem>
                                                    <ListItem button className={classes.nested}> Address: {`${item.address}`}</ListItem>
                                                    <ListItem button className={classes.nested}> Comments: {`${item.comments}`}</ListItem>
                                                </List>
                                            </Collapse>
                                        </List>

                                        {item.noOfPersons > 1 && (
                                            <List
                                                component="nav"
                                                subheader={<ListSubheader color="primary" component="div">Family Details</ListSubheader>}
                                            >
                                                <ListItem button selected={selectedIndex === 1} onClick={event => this.handleFamilyClick(event, 1)}>
                                                    <ListItemText inset primary="All Members" />
                                                    <IconButton onClick={() => this.setState({
                                                        guestEdit: false,
                                                        familyEdit: true,
                                                        travelEdit: false,
                                                        vehicleEdit: false,
                                                        hotelEdit: false,
                                                    })}>
                                                        <EditIcon color="primary" />
                                                    </IconButton>
                                                    {this.state.familyOpen ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={this.state.familyOpen} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {
                                                            this.props.guestFamiliesQuery.families && this.props.guestFamiliesQuery.families.map((item) => {
                                                                return (
                                                                    <ListItem key={item.id} button className={classes.nested}>
                                                                        <p>{item.firstName}</p>
                                                                    </ListItem>
                                                                )
                                                            })
                                                        }

                                                    </List>
                                                </Collapse>
                                            </List>
                                        )}
                                        {(item.status.name === "Yes") && (
                                            <List
                                                component="nav"
                                                subheader={<ListSubheader color="primary" component="div">Travel Details</ListSubheader>}
                                            >
                                                <div>
                                                    <ListItem button
                                                        selected={selectedIndex === 2} onClick={event => this.handleTravelClick(event, 2)}>
                                                        <ListItemText inset primary="Flight / Trains" />
                                                        <IconButton onClick={() => this.setState({
                                                            guestEdit: false,
                                                            familyEdit: false,
                                                            travelEdit: true,
                                                            vehicleEdit: false,
                                                            hotelEdit: false,
                                                        })}>
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                        {this.state.travelOpen ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>
                                                    <Collapse in={this.state.travelOpen} timeout="auto" unmountOnExit>
                                                        {
                                                            this.props.guestTransports.guestTransports && this.props.guestTransports.guestTransports.map((item) => {
                                                                return (
                                                                    <div>
                                                                        <List key={item.id} component="div" disablePadding>
                                                                            <b><ListItem button className={classes.nested}> Transport Details: {`${item.gTType.name}`}</ListItem></b>
                                                                            <ListItem button className={classes.nested}> Name: {`${item.transportDetailsId.name}`}</ListItem>
                                                                            <ListItem button className={classes.nested}> PNR:  {`${item.pnr}`}</ListItem>
                                                                            {(item.defaultTime) && (<ListItem button className={classes.nested}> Arrival Time: {moment(item.defaultTime).format('D MMMM YYYY')}</ListItem>)}
                                                                            <ListItem button className={classes.nested}> Transport:  {`${item.transportDetailsId.transportId.name === "Flights" ? "Flight" : "Train"}`}</ListItem>
                                                                            <ListItem button className={classes.nested}> Comments:  {`${item.comments}`}</ListItem>
                                                                        </List>
                                                                        <hr />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Collapse>
                                                </div>
                                            </List>
                                        )}

                                        {(item.vehicleRequired && item.status.name === "Yes") && (
                                            <List
                                                component="nav"
                                                subheader={<ListSubheader color="primary" component="div">Vehicle Details</ListSubheader>}
                                            >
                                                <div>
                                                    <ListItem button
                                                        selected={selectedIndex === 3} onClick={event => this.handleVehicleClick(event, 3)}
                                                    >
                                                        <ListItemText inset primary="Cabs" />
                                                        <IconButton onClick={() => this.setState({
                                                            guestEdit: false,
                                                            familyEdit: false,
                                                            travelEdit: false,
                                                            vehicleEdit: true,
                                                            hotelEdit: false,
                                                        })}>
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                        {this.state.vehicleOpen ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>
                                                    <Collapse in={this.state.vehicleOpen} timeout="auto" unmountOnExit>
                                                        {
                                                            this.props.guestVehicleDetailsQuery.guestVehicleDetails && this.props.guestVehicleDetailsQuery.guestVehicleDetails.map((item) => {
                                                                return (
                                                                    <List key={item.id} component="div" disablePadding>
                                                                        <ListItem button className={classes.nested}> Vehicle Name:  {`${item.vehicleTypeId? item.vehicleTypeId.name: ""}`}</ListItem>
                                                                        <ListItem button className={classes.nested}> Vehicle Number:  {`${item.vehicleNumberId? item.vehicleNumberId.number: ""}`}</ListItem>
                                                                        <ListItem button className={classes.nested}> Comments:  {`${item.comments}`}</ListItem>
                                                                    </List>
                                                                )
                                                            })
                                                        }
                                                    </Collapse>
                                                </div>
                                            </List>
                                        )}
                                        {(item.hotelRequired && item.status.name === "Yes") && (
                                            <List
                                                component="nav"
                                                subheader={<ListSubheader color="primary" component="div">Hotel Details</ListSubheader>}
                                            >
                                                <div>
                                                    <ListItem button
                                                        selected={selectedIndex === 4} onClick={event => this.handleHotelClick(event, 4)}
                                                    >
                                                        <ListItemText inset primary={"Hotel"} />
                                                        <IconButton onClick={() => this.setState({
                                                            guestEdit: false,
                                                            familyEdit: false,
                                                            travelEdit: false,
                                                            vehicleEdit: false,
                                                            hotelEdit: true,
                                                        })}>
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                        {this.state.hotelOpen ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>
                                                    <Collapse in={this.state.hotelOpen} timeout="auto" unmountOnExit>
                                                        {
                                                            this.props.guestHotelDetailQuery.guestHotelInformation && this.props.guestHotelDetailQuery.guestHotelInformation.map((item) => {
                                                                return (
                                                                    <List key={item.id} component="div" disablePadding>
                                                                        <ListItem button className={classes.nested}> Hotel Name:  {`${item.hotelRoomId.hotelId.name}`}</ListItem>
                                                                        <ListItem button className={classes.nested}> Room Number:  {`${item.roomNumber}`}</ListItem>
                                                                        <ListItem button className={classes.nested}> Check In:  {`${item.checkIn ? moment(item.checkIn).format('D MMMM YYYY') : ""}`}</ListItem>
                                                                        <ListItem button className={classes.nested}> Check Out:  {`${item.checkOut ? moment(item.checkOut).format('D MMMM YYYY') : ""}`}</ListItem>
                                                                        {/* {item.hotelRoomId.isPooled ? <ListItem button className={classes.nested}> Pooled With: {`${item.hotelRoomId.guestHotelDetailsId.guestId.map((item) => {
                                                                            return item.fullName
                                                                        })}`}</ListItem> : ""} */}
                                                                        <ListItem button className={classes.nested}> Comments:  {`${item.comments ? item.comments : ""}`}</ListItem>
                                                                    </List>
                                                                )
                                                            })
                                                        }
                                                    </Collapse>

                                                </div>

                                            </List>
                                        )}
                                    </div>
                                    <div className={classes.guestEdit}>
                                        <center><h3>Modify Guest Details</h3></center>
                                        {
                                            this.props.guestQuery.guest && this.props.guestQuery.guest.map((item) => {
                                                return (
                                                    <div key={item.id}>
                                                        <div>
                                                            {(guestEdit) && (
                                                                <GuestForm
                                                                    id={item.id}
                                                                    fullName={item.fullName}
                                                                    cardName={item.cardName}
                                                                    comments={item.comments}
                                                                    groupId={item.group ? item.group : ""}
                                                                    subGroupId={item.subGroup ? item.subGroup : ""}
                                                                    govtIdType={item.govtIDType ? item.govtIDType.id : ""}
                                                                    phone={item.phone}
                                                                    city={item.city}
                                                                    govtID={item.govtID ? item.govtID : ""}
                                                                    noOfPersons={item.noOfPersons}
                                                                    email={item.email}
                                                                    arrivalDate={item.arrivalDate}
                                                                    depatureDate={item.depatureDate ? item.depatureDate : new Date().toISOString()}
                                                                    statusId={item.status ? item.status.id : ""}
                                                                    categoryId={item.category ? item.category : ""}
                                                                    isInformed={item.isInformed}
                                                                    vehicleRequired={item.vehicleRequired}
                                                                    hotelRequired={item.hotelRequired}
                                                                    called={item.called ? item.called : false}
                                                                    guestQuery={this.guestQuery}
                                                                    modeOfInv={item.modeOfInvitation ? item.modeOfInvitation.id : ""}
                                                                    genderId={item.gender ? item.gender.id : ""}
                                                                    serialNo={item.serialNo}
                                                                    address={item.address}
                                                                    printOver={item.printOver}
                                                                    inviteMode={item.inviteMode}
                                                                    majorArea={item.majorArea ? item.majorArea : ""}
                                                                    minorArea={item.minorArea ? item.minorArea : ""}
                                                                    updatedBy={item.updatedBy ? item.updatedBy.name : ""}
                                                                    updatedByUserAt={item.updatedByUserAt ? item.updatedByUserAt : item.updatedAt}
                                                                />
                                                            )}
                                                        </div>
                                                        <div>
                                                            {(item.noOfPersons > 1 && familyEdit) && (
                                                                <div>
                                                                    <center><h3>Family Details</h3></center>
                                                                    <FamilyForm guestId={item.id}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {(item.status.name === "Yes" && travelEdit) && (
                                                                <div>
                                                                    <center><h3>Travel Details</h3></center>
                                                                    <TravelForm guestId={item.id}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {(vehicleEdit && (item.status.name === "Yes" && item.vehicleRequired === true)) && (
                                                                <div>
                                                                    <center> <h3>Vehcile Details</h3></center>
                                                                    <VehicleForm guestId={item.id} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {(hotelEdit && (item.status.name === "Yes" && item.hotelRequired === true)) && (
                                                                <div>
                                                                    <center><h3>Hotel Details</h3></center>
                                                                    <HotelForm guestId={item.id} noOfPersons={item.noOfPersons}
                                                                        subGroupId={item.subGroupId}
                                                                        groupId={item.groupId} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

const GUEST_TRANSPORT_DETAILS = gql`
  query guestTransports($guestId: ID!) {
                    guestTransports(guestId: $guestId) {
                        id
                        defaultTime
                        comments
                        gTType{
                            id
                            name
                        }
                transportDetailsId {
                    id
                        name
                transportId{
                    id 
                        name
                }
            }
                  guestId{
                    id
                      cardName
                }
                pnr
        }
    }
`

const GUEST_VEHICLE_DETAILS_QUERY = gql`
  query GuestVehicleDetailsQuery($guestId: ID!) {
    guestVehicleDetails(guestId: $guestId){
        id
        guestId{ 
            id 
          fullName
        }
        vehicleNumberId{
            id
            number
        }
        vehicleTypeId {
            id
            name
        }
        isPooling
        comments
    }
  }
`

const GUEST_HOTEL_DETAIL = gql`
  query guestHotelDetailQuery($guestId: ID!) {
    guestHotelInformation(guestId: $guestId){
                    id
                    
             guestId{
                    id 
                    fullName
                }
                hotelRoomId {
                    id 
                    number
                    isPooled
                    guestHotelDetailsId{
                        id
                        guestId{
                            id
                            fullName
                        }
                    }
                    hotelId{
                        id
                        name
                    }
                }
                roomNumber
                checkIn
                checkOut
                roomType
                isPooling
                comments
            }
          }
        `

const FAMILIES_QUERY = gql`
  query guestFamiliesQuery($guestId: ID!) {
                    families(guestId: $guestId) {
                    id, firstName, govtIDType {
                    id
            name
                },
                phone, govtID, pnr,
                isInformed, isInvited
            }
          }
        `

const GUEST_QUERY = gql`
  query GuestQuery($id: ID!) {
                    guest(id: $id) {
                    id, fullName, cardName, comments, phone,  govtID,
noOfPersons, email, arrivalDate, comments, email, isInformed,
vehicleRequired, hotelRequired, serialNo, inviteMode, printOver,
called,
depatureDate,
updatedBy{
    id
    name
},
updatedByUserAt,
updatedAt,
majorArea{
    id
    name
}, minorArea{
    id
    name
}, 
                    address,
                    gifts{
                        id
                        name
                    },
                    city{
                         id
                         name
                    }
                 status{
                    id
                    name
                },
                group{
                    id
                    name
                },
                subGroup{
                    id
                    name
                },
                gender{
                    id
                    name
                }
                category{
                    id
                    name
                },
                govtIDType {
                    id
                    name
                },
                modeOfInvitation{
                    id
                    name
                }
                hotelDetails{
                    id
                    hotelRoomId {
                    id
                    number
            }
        },
                vehicleDetails{
                    id
                }
                transportDetails{
                    id
                    transportDetailsId {
                    id
                }
                comments
              },
              hotelRequired
              vehicleRequired
    }
}
`

GuestDetail.propTypes = {
    classes: PropTypes.object,
};

const GuestDetailMutation = compose(
    graphql(GUEST_QUERY, {
        name: 'guestQuery',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(GUEST_HOTEL_DETAIL, {
        name: 'guestHotelDetailQuery',
        options: (props) => ({
            variables: {
                guestId: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(GUEST_VEHICLE_DETAILS_QUERY, {
        name: 'guestVehicleDetailsQuery',
        options: (props) => ({
            variables: {
                guestId: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(GUEST_TRANSPORT_DETAILS, {
        name: 'guestTransports',
        options: (props) => ({
            variables: {
                guestId: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(FAMILIES_QUERY, {
        name: 'guestFamiliesQuery',
        options: (props) => ({
            variables: {
                guestId: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
)(GuestDetail)

const GuestDetails = withStyles(styles)(GuestDetailMutation);

export default withApollo(GuestDetails);