import React, { Component } from "react";
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup, Spinner
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import Select from 'react-select';
import _ from 'lodash';
import Toast from '../../utils/Toast'

class EditNewCategoryPage extends Component {

    constructor(props) {
        super(props)
        this.handleEditChange = this.handleEditChange.bind(this)
        this.state = {
            name: this.props.name,
            id: this.props.id,
            events: [],
            selectedEvent: [],
            loading: false,
            open: false,
            message: "",
        };
    }

    componentWillMount() {
        this.updateState()
    }

    async updateState() {
        this.setState({ loading: true })
        const result = await this.props.client.query({
            query: CATEGORY_QUERY,
            variables: { id: this.props.id },
            options: {
                fetchPolicy: 'network-only',
            }
        })

        var events = await _.map(result.data.category).map((item) => {
            return item.events
        });
        var selectedEvent = []
        const selected = events.map((item, i)=> {
            for (let i in item) {
                console.log(item[i]);
                selectedEvent.push({
                    'label': item[i].name, 'value': item[i].id
                })
              }
        })

        this.setState({ selectedEvent, loading: false })
    }

    handleEditChange(event) {
        var name = ""
        name = event.target.value
        this.setState({ name: name });
    }



    render() {
        const { id, name, checkboxes, selectedEvent,  open, message, loading } = this.state
        return (
            <div className="form-content">
             <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        EDIT Category
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                                <form onSubmit={this.handleCategory}>
                                    <input type="hidden" value={id || ''} />
                                    <FormGroup
                                        label="Name"
                                        labelFor="text-input"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup id="text-input"
                                            value={name || ''}
                                            required="required"
                                            onChange={this.handleEditChange}
                                            placeholder="Name of category" />
                                    </FormGroup>
                                    <FormGroup>
                                        <h5>Select the applied events to this category</h5>
                                        <Select
                                            name="events"
                                            required
                                            isMulti
                                            options={this.props.eventsQuery.events && this.props.eventsQuery.events.map((item) => {
                                                return { 'value': item.id, 'label': item.name }
                                            })}
                                            defaultValue={selectedEvent}
                                            value={selectedEvent}
                                            clearable={false}
                                            onChange={(value) => this.setState({
                                                selectedEvent: value
                                            })}
                                        />
                                    </FormGroup>
                                    <div className={Classes.DIALOG_FOOTER}>
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <Button type="submit" intent={Intent.PRIMARY} >
                                                Update
                            </Button>
                                        </div>
                                    </div>
                                </form>
                    </Card>
                </div>
            </div>
        );
    }

    handleCategory = async e => {
        e.preventDefault()
        const { id, name } = this.state
        this.setState({ loading: true })
        var events = {}
        events = this.state.selectedEvent.map((item) => {
            return item.value
        });
        await this.props.editCategoryEventMutation({
            variables: { id }
        })
        await this.props.editCategoryMutation({
            variables: { id, name, events },
        }).then(async ({ data }) => {
            this.setState({
                loading: false, open: true,
                message: data.updateCategory.name + " " + "updated",
            })
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
        
    }
}

const CATEGORY_QUERY = gql`
  query singlecategoryQuery($id: ID!) {
    category(id: $id){
      id
      name
      events{
       id
       name
       }
    }
  }
`

const EVENTS_QUERY = gql`
  query eventsQuery {
    events {
      id
      name
    }
  }
`

const EDIT_CATEGORY_MUTATION = gql`
  mutation EditCategoryMutation($id: ID!, $name: String!, $events: [ID]) {
    updateCategory(id: $id, name: $name, events: $events) {
      id
      name
    }
  }
`
const EDIT_CATEGORY_EVENTS_MUTATION = gql`
  mutation EditCategoryEventMutation($id: ID!) {
    updateCategoryEvents(id: $id) {
      id
    }
  }
`
const EditCategoryWithMutation = compose(
    graphql(EDIT_CATEGORY_MUTATION, {
        name: 'editCategoryMutation', // name of the injected prop: this.props.editCategoryMutation...
    }),
    graphql(EDIT_CATEGORY_EVENTS_MUTATION, {
        name: 'editCategoryEventMutation',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    graphql(EVENTS_QUERY, {
        name: 'eventsQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        }
    }),
    graphql(CATEGORY_QUERY, {
        name: 'singlecategoryQuery',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
)(EditNewCategoryPage)

export default withApollo(EditCategoryWithMutation)