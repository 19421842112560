import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { AUTH_TOKEN } from '../constant'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  FormGroup, Intent, Button, Spinner,
  InputGroup,
  Tooltip,
} from "@blueprintjs/core"
import Toast from '../utils/Toast'
import { withStyles } from '@material-ui/core/styles'
import Logo from '../assets/icon.png'

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    margin: 30,
  },
  bigAvatar: {
    width: 146,
    height: 146,
  },
};

class LoginPage extends Component {
  state = {
    email: '',
    password: '',
    showPassword: false,
    loading: false,
    open: false,
    message: "",
  }

  handleLockClick = () => this.setState({ showPassword: !this.state.showPassword })

  render() {
    const { classes } = this.props;
    const { showPassword, open, message, loading } = this.state;

    const lockButton = (
      <Tooltip content={`${showPassword ? "Hide" : "Show"} Password`}>
        <Button
          icon={showPassword ? "unlock" : "lock"}
          intent={Intent.WARNING}
          minimal={true}
          onClick={this.handleLockClick}
        />
      </Tooltip>
    )

    return (
      <div className="pa4 flex justify-center bg-white">
        <div>
          <center>
            <Toast open={open} message={message} duration={5000} />
            {(loading) && (<Spinner intent="primary" size={50} />)}
            {/* <Avatar
              alt="KMS LOGO"
              src={Logo}
              className={classNames(classes.avatar, classes.bigAvatar)}
            /> */}
            <img src={Logo} alt="Logo" className={classNames(classes.avatar, classes.bigAvatar)} />
            <form onSubmit={this._login}>
              <FormGroup>
                <InputGroup id="text-input"
                  type="email"
                  large="true"
                  onChange={e => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  placeholder="Enter Email address" />

              </FormGroup>
              <FormGroup>
                <InputGroup
                  rightElement={lockButton}
                  placeholder="Enter password"
                  large="true"
                  type={showPassword ? "text" : "password"}
                  onChange={e => this.setState({ password: e.target.value })}
                  value={this.state.password}
                >
                </InputGroup>
              </FormGroup>
              <Button
                type="submit"
                intent="success" text="Login" />
            </form>
          </center>
        </div>
      </div>
    )
  }

  _login = async e => {
    e.preventDefault()
    const { email, password, } = this.state
    this.setState({ loading: true })
    this.props
      .loginMutation({
        variables: {
          email,
          password,
        },
      })
      .then(result => {
        const token = result.data.login.token

        this.props.refreshTokenFn &&
          this.props.refreshTokenFn({
            [AUTH_TOKEN]: token,
          })
        this.props.history.replace('/')
        window.location.reload()
      })
      .catch(errors => {
        this.setState({
          open: true,
          message: errors.message,
          loading: false
        })
      })
    await setTimeout(() => this.setState({ open: false }), 6000);
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const LOGIN_USER_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        name
        email
      }
    }
  }
`

const LoginWithMutation = graphql(LOGIN_USER_MUTATION, { name: 'loginMutation' })(
  withRouter(LoginPage),
)

export default withStyles(styles)(LoginWithMutation);
