import React, { Component } from "react";
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup, Spinner
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import Toast from '../../utils/Toast'

var gid = "";

class AddNewGroupPage extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubGroup = this.handleSubGroup.bind(this);
        this.handleGroup = this.handleGroup.bind(this);
        this.handleSubChange = this.handleSubChange.bind(this);
        this.state = {
            name: "",
            subName: "",
            loading: false,
            open: false,
            message: "",
            subGroups: [],
        };
    }
    handleChange(event) {
        this.setState({ name: event.target.value });
    }
    handleSubChange(event) {
        this.setState({ subName: event.target.value });
    }
    resetForm() {
        this.setState({
            subName: "",
            loading: false,
            open: false,
            message: "",
        });
    }
    async handleSubGroup(e) {
        e.preventDefault()
        const { subName, groupId } = this.state
        this.setState({ loading: true })
        await this.props.createSubGroupMutation({
            variables: { name: subName, groupId },
        }).then(async ({ data }) => {
            this.setState({
                loading: false, open: true,
                message: data.createSubGroup.name + " " + " Added",
            })
            await this.props.subGroupQuery.refetch({
                groupId
            });
            this.resetForm();
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }

    handleGroup = async e => {
        e.preventDefault()
        const { name } = this.state
        this.setState({ loading: true })
        await this.props.createGroupMutation({
            variables: { name },
        }).then(async ({ data }) => {
            gid = data.createGroup.id;
            this.setState({
                groupId: data.createGroup.id,
                loading: false, open: true,
                message: data.createGroup.name + " " + "Added",
            })
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }


    render() {
        const { open, message, loading, subGroups } = this.state
        return (
            <div className="form-content">
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        Add Group
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleGroup}>
                            <FormGroup
                                label="Name"
                                labelFor="text-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="text-input" value={this.state.name}
                                    required="required"
                                    onChange={this.handleChange}
                                    placeholder="Name of group" />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                        <hr />
                        <h3> Sub Groups: </h3>
                        {this.props.subGroupQuery.subGroups && this.props.subGroupQuery.subGroups.map(subGroup => {
                            const { id, name
                            } = subGroup;
                            return [
                                <li key={id}>{name.toString()}</li>]
                        })}
                        <hr />
                        <form onSubmit={this.handleSubGroup}>
                            <FormGroup
                                label="Sub Group Name"
                                labelFor="sub-input"
                            >
                                <InputGroup id="sub-input" value={this.state.subName}
                                    required="required"
                                    onChange={this.handleSubChange}
                                    placeholder="Name of Sub group" />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }
}

const SUBGROUP_QUERY = gql`
  query SubGroupQuery($groupId: ID!) {
    subGroups(groupId: $groupId) {
      id
      name
    }
  }
`

const CREATE_GROUP_MUTATION = gql`
  mutation CreateGroupMutation($name: String!) {
    createGroup(name: $name) {
      id
      name
    }
  }
`
const CREATE_SUBGROUP_MUTATION = gql`
  mutation CreateSUbGroupMutation($name: String!, $groupId: ID!) {
    createSubGroup(name: $name, groupId: $groupId) {
      id
      name
    }
  }
`

const CreateGroupWithMutation = compose(
    graphql(CREATE_GROUP_MUTATION, {
        name: 'createGroupMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(SUBGROUP_QUERY, {
        name: 'subGroupQuery',
        options: {
            variables: {
                groupId: gid
            },
            fetchPolicy: 'cache-and-network',
        }
    }),
    graphql(CREATE_SUBGROUP_MUTATION, {
        name: 'createSubGroupMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
)(AddNewGroupPage)

export default withApollo(CreateGroupWithMutation)