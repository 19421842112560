import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";

class EditNewTransportPage extends Component {

    constructor(props) {
        super(props)
        this.handleEditChange = this.handleEditChange.bind(this)
        this.state = {
            name: this.props.name,
            id: this.props.id,
        };
    }

    handleEditChange(event) {
        var name = ""
        name = event.target.value
        this.setState({ name: name });
    }
    render() {
        return (
            <div className="form-content">
              <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        EDIT Transport
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleTransport}>
                            <input type="hidden" value={this.state.id || ''} />
                            <FormGroup
                                label="Name"
                                labelFor="text-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="text-input"
                                    value={this.state.name || ''}
                                    required="required"
                                    onChange={this.handleEditChange}
                                    placeholder="Name of transport" />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Update
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

    handleTransport = async e => {
        e.preventDefault()
        const { id, name } = this.state
        console.log("Editing", this.state)
        await this.props.editTransportMutation({
            variables: { id, name },
        })
        this.props.goBack();
    }
}

const EDIT_TRANSPORT_MUTATION = gql`
  mutation EditTransportMutation($id: ID!, $name: String!) {
    updateTransport(id: $id, name: $name) {
      id
      name
    }
  }
`
const EditTransportWithMutation = graphql(EDIT_TRANSPORT_MUTATION, {
    name: 'editTransportMutation', // name of the injected prop: this.props.editTransportMutation...
})(EditNewTransportPage)

export default withRouter(EditTransportWithMutation)