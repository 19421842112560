import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";

const divStyle = {
  iconButton: {
    display: "inline-table",
  },
};

class CustomAddIcon extends React.Component {

  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <React.Fragment>
        <div style={divStyle.iconButton}>
          <IconButton  onClick={this.props.refresh}>
            <RefreshIcon color="primary"/>
          </IconButton>
        </div>
        <div style={divStyle.iconButton}>
          <IconButton onClick = {this.props.handler}>
            <AddIcon color="primary" />
          </IconButton>
        </div>
      </React.Fragment>
    );
  }

}

export default CustomAddIcon;