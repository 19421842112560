import React, { Component, Fragment } from 'react'
import Category from '../Category/Category';
import Group from '../Groups/Group';
import Hotel from '../Hotels/Hotel';
import Transport from '../Transports/Transport';
import Train from '../Trains/Train';
import Flight from '../Flights/Flight';
import Vehicle from '../Vehicles/Vehicle';
import CommonDetail from '../CommonDetails/CommonDetail';
import {
  NavLink,
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { AUTH_TOKEN } from '../../constant'
import { isTokenExpired } from '../../helper/jwtHelper'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
  return token ? (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  ) : (
      <Redirect to="/login" />
    )
}

class MasterDetails extends Component {
  constructor(props) {
    super(props)
    this.refreshTokenFn = this.refreshTokenFn.bind(this)

    this.state = {
      token: props.token,
      isAdmin: props.isAdmin
    }
  }

  refreshTokenFn(data = {}) {
    const token = data.AUTH_TOKEN

    if (token) {
      localStorage.setItem(AUTH_TOKEN, token)
    } else {
      localStorage.removeItem(AUTH_TOKEN)
    }

    this.setState({
      token: data.AUTH_TOKEN,
    })
  }

  bootStrapData() {
    try {
      const token = localStorage.getItem(AUTH_TOKEN)
      if (token !== null && token !== undefined) {
        const expired = isTokenExpired(token)
        if (!expired) {
          this.setState({ token: token })
        } else {
          localStorage.removeItem(AUTH_TOKEN)
          this.setState({ token: null })
        }
      }
    } catch (e) {
      console.log('')
    }
  }

  //verify localStorage check
  componentDidMount() {
    this.bootStrapData()
  }

  render() {

    return (
      <Router>
        <Fragment>
          {this.renderMasterLists()}
          {this.renderRoute()}
        </Fragment>
      </Router>
    )
  }

  renderMasterLists() {
    return (
      this.props.data &&
      this.props.data.me &&
      this.props.data.me.email &&
      this.state.token && (
        <div className="sidebar-menu">
          <ul className="bp3-menu bp3-elevation-1">
            <li className="bp3-menu-header"><h6 className="bp3-heading">Master Details</h6></li>
            <NavLink exact={true} to="/masterdetail/group/">
              <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-social-media">Groups</button></li>
            </NavLink>
            <NavLink exact={true} to="/masterdetail/category/">
              <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-comparison">Category</button></li>
            </NavLink>
            {(this.props.data.me.isAdmin) && (
              <NavLink exact={true} to="/masterdetail/commondetails/">
                <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-calendar">Common Details</button></li>
              </NavLink>
            )}
            {(this.props.data.me.isAdmin) && (
              <NavLink exact={true} to="/masterdetail/transport/">
              <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-unknown-vehicle">Transport Types</button></li>
              </NavLink>
            )}
             <li className="bp3-menu-header"><h6 className="bp3-heading">Travel Details</h6></li>
             <NavLink exact={true} to="/masterdetail/flight/">
            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-airplane">Flights</button></li>
            </NavLink>
            <NavLink exact={true} to="/masterdetail/train/">
            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-train">Trains</button></li>
            </NavLink>
            <NavLink exact={true} to="/masterdetail/vehicle/">
            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-drive-time">Vehicles</button></li>
            </NavLink>
            <NavLink exact={true} to="/masterdetail/hotel/">
            <li><button intent="primary" type="button" className="bp3-menu-item bp3-intent-primary bp3-icon-home">Hotels</button></li>
            </NavLink>
          </ul>
        </div>
      )
    )
  }

  renderRoute() {
    return (
      <div className="fl w-100 pl4 pr4">
        <div className="main-content">
          <Switch>
            <Route
              path="/masterdetail/group"
              component={Group}
            />
            <Route
              path="/masterdetail/category"
              component={Category}
            />
            <Route
              path="/masterdetail/hotel"
              component={Hotel}
            />
            <Route
              path="/masterdetail/train"
              component={Train}
            />
            <Route
              path="/masterdetail/flight"
              component={Flight}
            />
            <Route
              path="/masterdetail/Vehicle"
              component={Vehicle}
            />
            <ProtectedRoute
              token={this.state.token}
              path="/masterdetail/commondetails"
              component={CommonDetail}
            />
            <ProtectedRoute
              token={this.state.token}
              path="/masterdetail/transport"
              component={Transport}
            />
          </Switch>
        </div>
      </div>
    )
  }
}


const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      name
      isAdmin
    }
  }
`

export default graphql(ME_QUERY, {
  options: {
    errorPolicy: 'all',
  },
})(MasterDetails)

