import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup, TextArea, Spinner
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'
import { DatePicker } from 'material-ui-pickers'
import Toast from '../../utils/Toast'

var vehicleDetailsId = ""

class AddNewVehiclePage extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleVehicleTypeChange = this.handleVehicleTypeChange.bind(this);
        this.handleVehicleType = this.handleVehicleType.bind(this);
        this.handleVehicle = this.handleVehicle.bind(this);
        this.state = {
            id: "",
            name: "",
            mobile: "",
            comments: "",
            vTName: "",
            totalCount: "",
            VTcomments: "",
            vehicleDetailsId: "",
            vehicleTypes: [],
            loading: false,
            open: false,
            message: "",
            sequence: "",
        };
    }

    resetForm() {
        this.setState({
            id: "",
            name: "",
            mobile: "",
            comments: "",
            vTName: "",
            totalCount: "",
            VTcomments: "",
            vehicleTypes: [],
            loading: false,
            open: false,
            message: "",
            sequence: "",
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleVehicleTypeEdit = (item) => {
        this.setState({
            id: item.id,
            loading: false,
            open: false,
            message: "",
            vTName: item.name,
            totalCount: item.totalCount,
            VTcomments: item.comments,
        })
    }


    handleVehicleTypeChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    async handleVehicleType(e) {
        e.preventDefault()
        const { id, vTName, totalCount, VTcomments, vehicleDetailsId, sequence } = this.state
        this.setState({ loading: true })
        if (vehicleDetailsId === "") {
            this.setState({
                loading: false, open: true,
                message: "Please add or save operator first",
            })
        }
        if (vehicleDetailsId !== "") {
            if (id) {
                await this.props.editVehicleTypeMutation({
                    variables: { id, name: vTName, comments: VTcomments, vehicleDetailsId },
                }).then(async ({ data }) => {
                    this.setState({
                        loading: false, open: true,
                        message: data.updateVehicleType.name + " " + " Added",
                    })
                    await this.props.vehicleTypesQuery.refetch({
                        vehicleDetailsId
                    });
                    this.resetForm();
                }).catch(error => {
                    this.setState({
                        open: true,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
            } else {
                await this.props.createVehicleTypeMutation({
                    variables: { name: vTName, totalCount, comments: VTcomments, vehicleDetailsId },
                }).then(async ({ data }) => {
                    const vehicleTypeId = await data.createVehicleType.id;
                    for (var i = 1; i <= totalCount; i++) {
                        var number = sequence + i
                        if (vehicleTypeId) {
                            await this.props.createVehicleNumberMutation({
                                variables: {
                                    vehicleTypeId,
                                    number
                                },
                            }).then(({ data }) => {
                                this.setState({
                                    loading: false, open: true,
                                    message: data.createVehicleNumbers.number + " " + " Added",
                                })
                            }).catch(error => {
                                console.log("error", error)
                            })
                        }
                    }

                    await this.props.vehicleTypesQuery.refetch({
                        vehicleDetailsId
                    });
                    this.resetForm();
                }).catch(error => {
                    this.setState({
                        open: true,
                        message: "Error occured " + error,
                        loading: false
                    })
                })
            }
        }
        await setTimeout(() => this.setState({ open: false }), 6000);
    }

    handleVehicle = async e => {
        e.preventDefault()
        const { name, mobile, comments } = this.state
        this.setState({ loading: true })
        await this.props.createVehicleMutation({
            variables: { name, mobile, comments },
        }).then(async ({ data }) => {
            vehicleDetailsId = data.createVehicleDetails.id;
            this.setState({
                vehicleDetailsId: data.createVehicleDetails.id,
                loading: false, open: true,
                message: data.createVehicleDetails.name + " " + "Added",
            })
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }

    render() {
        const { id, name, mobile, comments, vTName, totalCount, VTcomments, vehicleDetailsId, open, message, loading, sequence } = this.state
        return (
            <div className="form-content">
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        ADD Vehicle
                    </p>
                </center>
                <div>
                    <Card className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleVehicle}>
                            <FormGroup
                                label="Operator Name"
                                labelFor="opname-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="opname-input" value={name}
                                    required="required"
                                    name="name"
                                    onChange={this.handleChange}
                                    placeholder="Operator Name" />
                            </FormGroup>
                            <FormGroup
                                label="Phone"
                                labelFor="mobile-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="mobile-input" value={mobile}
                                    required="required"
                                    name="mobile"
                                    onChange={this.handleChange}
                                    placeholder="Mobile Number" />
                            </FormGroup>
                            <FormGroup
                                label="Comments"
                                labelFor="comments-input"
                                className="switchControl"
                            >
                                <TextArea
                                    className="bp3-fill"
                                    large={true}
                                    intent={Intent.PRIMARY}
                                    onChange={event => this.setState({ comments: event.target.value })}
                                    value={comments}
                                />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                        <center><h4>{id ? "Edit" : "Add"} Vehicle Types</h4></center>
                        {
                            this.props.vehicleTypesQuery.vehicleTypes && this.props.vehicleTypesQuery.vehicleTypes.map((item) => {
                                return (
                                    <ul className="bp3-list-unstyled">
                                        <li key={item.id}>
                                            <div className="families"> {item.name} -- {item.totalCount} -- {item.availableCount}
                                                <IconButton className="action-icons" onClick={() => this.handleVehicleTypeEdit(item)}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                <IconButton className="action-icons" onClick={() => this.handleConfirmDelete(item.id)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        <hr />
                        <form onSubmit={this.handleVehicleType}>
                            <FormGroup
                                label="Vehicle Tpye"
                                labelFor="vtype-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="vtype-input" value={vTName}
                                    required="required"
                                    name="vTName"
                                    onChange={this.handleVehicleTypeChange}
                                    placeholder="Type of Vehicle eg: Innovo, Dezire..." />
                            </FormGroup>
                            {(!id) && (
                                <div>
                                    <FormGroup
                                        label="Number Sequence"
                                        labelFor="num-input"
                                    >
                                        <InputGroup id="num-input" value={sequence}
                                            name="sequence"
                                            required
                                            onChange={this.handleChange}
                                            placeholder="Enter Sequence to generated vehicle numbers" />
                                    </FormGroup>

                                    <FormGroup
                                        label="Total Count"
                                        labelFor="total-input"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup
                                            id="totalcount-input"
                                            required
                                            value={totalCount}
                                            name="totalCount"
                                            placeholder="Enter Total Capacity of cab"
                                            onChange={this.handleVehicleTypeChange} />
                                    </FormGroup>
                                </div>
                            )}
                            <FormGroup
                                label="Comments"
                                labelFor="comments-input"
                                className="switchControl"
                            >
                                <TextArea
                                    className="bp3-fill"
                                    large={true}
                                    intent={Intent.PRIMARY}
                                    onChange={event => this.setState({ VTcomments: event.target.value })}
                                    value={VTcomments}
                                />
                            </FormGroup>

                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="button" onClick={this.resetForm} intent={Intent.WARNING} >
                                        Reset
                                                     </Button>
                                    {id ? <Button type="submit" intent={Intent.PRIMARY} >
                                        Update
                                                     </Button> :
                                        <Button type="submit" intent={Intent.PRIMARY} >
                                            ADD
                                                     </Button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

}

const CREATE_VEHICLE_MUTATION = gql`
  mutation CreateVehicleMutation($name: String!, $mobile: String!, $comments: String!) {
    createVehicleDetails(name: $name, mobile: $mobile,
        comments: $comments
     ) {
      id
      name
    }
  }
`
const CREATE_VEHICLE_TYPE_MUTATION = gql`
  mutation CreateVehicleTypes($name: String!, $totalCount: Int, $vehicleDetailsId: ID!, $comments: String!) {
    createVehicleType(name: $name, totalCount: $totalCount,
        vehicleDetailsId: $vehicleDetailsId, comments: $comments
     ) {
      id
      name
    }
  }
`
//Create Vehicle Number Muatation
const CREATE_VEHICLE_NUMBER_MUTATION = gql`
  mutation CreateVehicleNumbers($number: String!, $vehicleTypeId: ID ) {
    createVehicleNumbers(number: $number, vehicleTypeId: $vehicleTypeId) {
      id
      number
    }
  }
`

const VEHICLE_TYPES_QUERY = gql`
  query VehicleTypesQuery($vehicleDetailsId: ID!) {
    vehicleTypes(vehicleDetailsId: $vehicleDetailsId) {
      id
      name
      totalCount
      availableCount
      comments
    }
  }
`

const EDIT_VEHICLE_TYPE_MUTATION = gql`
  mutation EditVehicleTypes($id: ID!, $name: String!, $comments: String!) {
    updateVehicleType(id: $id, name: $name,
       comments: $comments
     ) {
      id
      name
    }
  }
`

const CreateVehicletWithMutation = compose(
    graphql(CREATE_VEHICLE_MUTATION, {
        name: 'createVehicleMutation', // name of the injected prop: this.props.createVehicleMutation...
    }),
   
    graphql(VEHICLE_TYPES_QUERY, {
        name: 'vehicleTypesQuery',
        options: {
            variables: {
                vehicleDetailsId
            },
            fetchPolicy: 'cache-and-network',
        }
    }),
    graphql(EDIT_VEHICLE_TYPE_MUTATION, {
        name: 'editVehicleTypeMutation', // name of the injected prop: this.props.editVehicleMutation...
    }),
    graphql(CREATE_VEHICLE_TYPE_MUTATION, {
        name: 'createVehicleTypeMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(CREATE_VEHICLE_NUMBER_MUTATION, {
        name: 'createVehicleNumberMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
)(AddNewVehiclePage)

export default withRouter(CreateVehicletWithMutation)