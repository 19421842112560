import React, { Component, Fragment } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import Select from 'react-select';
import _, { mapKeys } from 'lodash'
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import {
    Button, Classes, Intent, Card, Elevation, ControlGroup, HTMLSelect,
    FormGroup, InputGroup, Radio, RadioGroup, Spinner, TextArea
} from "@blueprintjs/core";
import { DateTimePicker } from 'material-ui-pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FamilyForm from "./FamilyForm";
import TravelForm from "./TravelForm";
import VehicleForm from "./VehicleForm";
import HotelForm from "./HotelForm";
import Toast from '../../../utils/Toast'

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit,
    },
});

class GuestForm extends Component {
    constructor(props) {
        super(props)
        this.handleSubGroupChange = this.handleSubGroupChange.bind(this)
        this.handleCategoryChange = this.handleCategoryChange.bind(this)
        this.toggleCheckbox = this.toggleCheckbox.bind(this)
        this.state = {
            isFamily: false,
            vehicleRequired: false,
            hotelRequired: false,
            fullName: "", cardName: "",
            comments: "", groupId: "", govtIdType: "",
            phone: "", cityId: "", govtID: "", noOfPersons: 1, email: "",
            arrivalDate: "", depatureDate: "",
            statusId: "", categoryId: "", isInformed: false, guestId: "",
            loading: false,
            open: false,
            message: "",
            guestAttending: false,
            subGroups: [],
            subGroupId: "",
            events: [],
            statusTrue: false,
            invitedBy: "",
            modeofInvId: "",
            genderId: "",
            serialNo: "",
            printOver: false,
            majorAreaId: "",
            minorAreaId: "",
            gifts: [],
            address: "",
            inviteMode: "",
            genders: [],
            vskpCity: "",
            called: false
        }
    }

    componentWillMount() {
        this.updateState()
        this.state.gifts = new Set();
    }

    async updateState() {

        const city = await this.props.client.query({
            query: CITY_QUERY,
            options: {
                fetchPolicy: 'network-only',
            }
        })

        const result = await this.props.client.query({
            query: STATUS_QUERY,
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var statusTrue = _.result(_.find(result.data.attending, function (item) {
            return item.name === "Yes";
        }), true);

        var statusId = _.result(_.find(result.data.attending, function (item) {
            return item.name === "Don't Know";
        }), 'id');

        var VCity = await _.map(city.data.cities).map((item) => {
            return item
        }).map((sg) => {
            if (sg.name === "VISAKHAPATNAM") {
                return sg.id
            }
        })
        var vskpCity = _.compact(VCity).toString();
        console.log("vskpCity", vskpCity)
        // var vskpCity = _.city(_.find(result.data.attending, function (item) {
        //     return item.name === "VISAKHAPATNAM";
        // }), 'id');

        this.setState({ statusId, loading: false, statusTrue, vskpCity })
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    // async handleModeOfInvitation(event) {
    //     this.setState({ modeofInvId: event.target.value });
    //     console.log("invited", this.state.modeofInvId)
    //     const invitedByPerson = await this.props.client.query({
    //         query: MODE_OF_INVITATION,
    //         options: {
    //             fetchPolicy: 'network-only',
    //         }
    //     })

    //     var InvitedByTrue = _.result(_.find(invitedByPerson.data.modeofInv, function (item) {
    //         return item.id === event.target.value;
    //     }), true);
    //     console.log("invited", InvitedByTrue)
    //     console.log("mmodeinvited", invitedByPerson)
    //     this.setState({ invitedByTrue: InvitedByTrue })
    // }

    handleHtmlSelectChange = (event) => {
        this.setState({ govtIdType: event.target.value });
    };

    handleSwitch = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    reloadPage() {
        window.location.reload()
    }

    async handleSubGroupChange(e) {
        const groupId = e.value
        this.setState({ groupId: e, subGroupId: [] });
        const result = await this.props.client.query({
            query: SUB_GROUP_QUERY,
            variables: { groupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var subGroups = await _.map(result.data.subGroups).map((item) => {
            return item
        }).map((sg) => {
            return { 'value': sg.id, 'label': sg.name }
        })

        this.setState({ subGroups })
    }

    async handleCategoryChange(e) {
        this.setState({ categoryId: e });
        const result = await this.props.client.query({
            query: EVENTS_CATEGORY_QUERY,
            variables: { id: e.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var events = await _.map(result.data.category).map((item) => {
            return item.events
        })

        var allEvents = []
        const selected = events.map((item, i) => {
            for (let i in item) {
                console.log(item[i]);
                allEvents.push({
                    'label': item[i].name, 'value': item[i].id
                })
            }
        })

        this.setState({ events: allEvents })
    }

    toggleCheckbox = item => {
        var checkboxes = this.state.gifts;

        if (checkboxes.has(item.id)) {
            checkboxes.delete(item.id);
        } else {
            checkboxes.add(item.id);
        }
        this.setState({
            gifts: checkboxes
        })
    }

    handleSelectChange(values) {
        // send selection options array to state
        this.setState({ ...this.state, values });
        // pull selected values into array
        const vals = _.map(values, 'value');
        // join array into comma separated string for redux form
        this.props.onChange(vals.join(','));
    }

    render() {
        const { classes } = this.props;
        const { id, fullName, cardName, comments, groupId, govtIdType, loading, hotelRequired,
            phone, cityId, govtID, noOfPersons, email, arrivalDate, statusId, categoryId, isInformed, guestId,
            vehicleRequired, open, message, subGroups, subGroupId, events, statusTrue,
            modeofInvId, genderId, serialNo, inviteMode, address, majorAreaId, minorAreaId, printOver,
            vskpCity, depatureDate, called
        } = this.state
        return (
            <div>
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <div className="form-container">
                    <Card className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleGuest} autocomplete="off">
                            <input type="hidden" value={id} />
                            <div className="form-groups">
                                <FormGroup
                                    label="Guest Name"
                                    labelFor="fullName-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="fullName-input" value={fullName}
                                        required="required"
                                        name="fullName"
                                        autoComplete="new-name"
                                        onChange={this.handleChange}
                                        placeholder="Guest Name" />
                                </FormGroup>
                                <FormGroup
                                    label="Card Name"
                                    labelFor="cardName-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="cardName-input" value={cardName}
                                        name="cardName"
                                        autoComplete="new-cname"
                                        onChange={this.handleChange}
                                        placeholder="Card Name of guest" />
                                </FormGroup>

                            </div>

                            <div className="form-groups">
                                <FormGroup
                                    label="Serial Number"
                                    labelFor="serial-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="serial-input" value={serialNo}
                                        required="required"
                                        name="serialNo"
                                        autoComplete="new-snname"
                                        onChange={this.handleChange}
                                        placeholder="serial Number" />
                                </FormGroup>
                                <FormGroup
                                    label="phone"
                                    labelFor="phone-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="phone-input" value={phone}
                                        required="required"
                                        name="phone"
                                        autoComplete="new-phone"
                                        onChange={this.handleChange}
                                        placeholder="Phone number" />
                                </FormGroup>

                            </div>
                            <div className="form-groups">
                                <FormGroup
                                    label="No Of Persons"
                                    labelFor="noOfPersons-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup id="noOfPersons-input" value={noOfPersons || ""}
                                        required="required"
                                        name="noOfPersons"
                                        autoComplete="new-persons"
                                        onChange={this.handleChange}
                                        placeholder="No Of Persons" />
                                </FormGroup>
                            </div>
                            <div className="form-groups">
                                <RadioGroup
                                    label="Gender"
                                    inline={true}
                                    onChange={event => this.setState({ genderId: event.target.value })}
                                    selectedValue={genderId}
                                    required="required"
                                >
                                    {this.props.genderQuery.genders && this.props.genderQuery.genders.map((item) => {
                                        return (
                                            <Radio key={item.id} label={item.name} value={item.id} />
                                        )
                                    })}
                                </RadioGroup>
                            </div>
                            <div className="form-groups">
                                <RadioGroup
                                    label="Status"
                                    inline={true}
                                    onChange={event => this.setState({ statusId: event.target.value })}
                                    selectedValue={statusId}
                                    required="required"
                                >
                                    {this.props.statusQuery.attending && this.props.statusQuery.attending.map((item) => {
                                        return (
                                            <Radio key={item.id} label={item.name} value={item.id} />
                                        )
                                    })}
                                </RadioGroup>
                            </div>
                            <FormGroup
                                label="City"
                                labelFor="city-input"
                            >
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="city"
                                    value={cityId}
                                    onChange={(e) => { e !== null && this.setState({ cityId: e }) }}
                                    options={this.props.cityQuery.cities && this.props.cityQuery.cities.map((item) => {
                                        return { 'value': item.id, 'label': item.name }
                                    })}
                                />
                            </FormGroup>
                            {(cityId.value === vskpCity) && (<div className="form-selects">
                                <FormGroup
                                    label="Minor Area"
                                    labelFor="miarea-input"
                                >
                                    <Select
                                        className="basic-single select-control "
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="minorArea"
                                        value={minorAreaId}
                                        onChange={(e) => { e !== null && this.setState({ minorAreaId: e }) }}
                                        options={this.props.areasQuery.areas && this.props.areasQuery.areas.map((item) => {
                                            return { 'value': item.id, 'label': item.name }
                                        })}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Major Area"
                                    labelFor="maarea-input"
                                >
                                    <Select
                                        className="basic-single select-control "
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="majorArea"
                                        value={majorAreaId}
                                        onChange={(e) => { e !== null && this.setState({ majorAreaId: e }) }}
                                        options={this.props.areasQuery.areas && this.props.areasQuery.areas.map((item) => {
                                            return { 'value': item.id, 'label': item.name }
                                        })}
                                    />
                                </FormGroup>
                            </div>)}
                            <div className="form-groups">
                                <FormGroup
                                    label="Email"
                                    labelFor="email-input"
                                >
                                    <InputGroup
                                        className="formControl"
                                        id="email-input"
                                        value={email}
                                        name="email"
                                        autoComplete="new-email"
                                        placeholder="Enter Email"
                                        onChange={this.handleChange} />
                                </FormGroup>

                            </div>
                            <div className="form-groups">
                                <FormGroup
                                    label="Arrival Date"
                                    labelFor="arrivalDate-input"
                                >
                                    <DateTimePicker
                                        clearable
                                        value={arrivalDate}
                                        onChange={(e) => { e !== null && this.setState({ arrivalDate: e.toJSON() }) }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Depature Date"
                                    labelFor="depaturedate-input"
                                >
                                    <DateTimePicker
                                        clearable
                                        value={depatureDate}
                                        onChange={(e) => { e !== null && this.setState({ depatureDate: e.toJSON() }) }}
                                    />
                                </FormGroup>
                            </div>
                            <FormGroup
                                label="Government ID"
                                labelFor="govtID-input"
                            >
                                <ControlGroup>
                                    <HTMLSelect
                                        options={this.props.govtIdTypesQuery.govtIdTypes && this.props.govtIdTypesQuery.govtIdTypes.map((item) => {
                                            return { 'label': item.name, 'value': item.id }
                                        })}
                                        value={govtIdType}
                                        onChange={this.handleHtmlSelectChange}
                                    />
                                    <InputGroup
                                        id="govtID-input"
                                        value={govtID}
                                        name="govtID"
                                        autoComplete="new-govtId"
                                        placeholder="Enter identity number"
                                        onChange={this.handleChange} />
                                </ControlGroup>

                            </FormGroup>
                            <div className="form-selects">
                                <FormGroup
                                    label="Group"
                                    labelFor="group-input"
                                >
                                    <Select
                                        className="basic-single select-control"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="group"
                                        value={groupId}
                                        onChange={(e) => { this.handleSubGroupChange(e) }}
                                        options={this.props.groupQuery.groups && this.props.groupQuery.groups.map((item) => {
                                            return { 'value': item.id, 'label': item.name }
                                        })}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Sub Group"
                                    labelFor="subgroup-input"
                                >
                                    <Select
                                        className="basic-single select-control"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        name="subGroup"
                                        value={subGroupId}
                                        onChange={(e) => { e !== null && this.setState({ subGroupId: e }) }}
                                        options={subGroups}
                                    />
                                </FormGroup>
                            </div>
                            <FormGroup
                                label="Category"
                                labelFor="category-input"
                            >
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="category"
                                    value={categoryId}
                                    onChange={(e) => { this.handleCategoryChange(e) }}

                                    options={this.props.categoryQuery.categories && this.props.categoryQuery.categories.map((item) => {
                                        return { 'value': item.id, 'label': item.name }
                                    })}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Events"
                                labelFor="events-input"
                            >
                                {events && events.map((item) => {
                                    return (
                                        <Chip label={item.label} color="primary" className={classes.chip} />
                                    )
                                })}

                            </FormGroup>
                            <FormGroup>
                                <h4>Select Gifts</h4>
                                {this.props.giftsQuery.gifts && this.props.giftsQuery.gifts.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    key={item.id}
                                                    checked={item.checked}
                                                    onChange={() => this.toggleCheckbox(item)}
                                                    value={item.id}
                                                    color="primary"
                                                />
                                            }
                                            label={item.name}
                                        />
                                    )
                                }
                                )}
                            </FormGroup>
                            <div className="switch-groups">
                                <FormGroup
                                    label="Vehicle Required"
                                    labelFor="hotel-input"
                                    className="switchControl"
                                >
                                    <Switch
                                        checked={vehicleRequired}
                                        onChange={this.handleSwitch('vehicleRequired')}
                                        value="vehicleRequired"
                                        color="primary"
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Hotel Required"
                                    labelFor="hotel-input"
                                    className="switchControl"
                                >
                                    <Switch
                                        checked={hotelRequired}
                                        onChange={this.handleSwitch('hotelRequired')}
                                        value="hotelRequired"
                                        color="primary"
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Print Over"
                                    labelFor="printOver-input"
                                    className="switchControl"
                                >
                                    <Switch
                                        checked={printOver}
                                        onChange={this.handleSwitch('printOver')}
                                        value="printOver"
                                        color="primary"
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-groups">
                                <FormGroup
                                    label="Guest Called"
                                    labelFor="called-input"
                                    className="switchControl"
                                >
                                    <Switch
                                        checked={called}
                                        onChange={this.handleSwitch('called')}
                                        value="called"
                                        color="primary"
                                    />
                                </FormGroup>

                                <FormGroup
                                    label="Guest Invited"
                                    labelFor="invited-input"
                                    className="switchControl"
                                >
                                    <Switch
                                        checked={isInformed}
                                        onChange={this.handleSwitch('isInformed')}
                                        value="isInformed"
                                        color="primary"
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-groups">
                                <FormGroup
                                    label="Invite Mode"
                                    labelFor="invMode-input"
                                >
                                    <InputGroup id="invMode-input" value={inviteMode || ""}
                                        name="inviteMode"
                                        autoComplete="new-invode"
                                        onChange={this.handleChange}
                                        placeholder="Guest should be invited" />
                                </FormGroup>
                            </div>
                            {(isInformed) && (
                                <div className="form-groups">
                                    <RadioGroup
                                        label="Mode Of Invitation"
                                        inline={true}
                                        onChange={event => this.setState({ modeofInvId: event.target.value })}
                                        selectedValue={modeofInvId}
                                        required="required"
                                    >
                                        {this.props.modeOfInvitation.modeofInv && this.props.modeOfInvitation.modeofInv.map((item) => {
                                            return (
                                                <Radio key={item.id} label={item.name} value={item.id} />
                                            )
                                        })}
                                    </RadioGroup>
                                </div>
                            )}
                            <div className="form-groups">
                                <FormGroup
                                    label="Address"
                                    labelFor="address-input"
                                    className="switchControl"
                                >
                                    <TextArea
                                        className="bp3-fill"
                                        large={true}
                                        intent={Intent.PRIMARY}
                                        onChange={event => this.setState({ address: event.target.value })}
                                        value={address}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Comments"
                                    labelFor="comments-input"
                                    className="switchControl"
                                >
                                    <TextArea
                                        className="bp3-fill"
                                        large={true}
                                        intent={Intent.PRIMARY}
                                        onChange={event => this.setState({ comments: event.target.value })}
                                        value={comments}
                                    />
                                </FormGroup>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                </Button>
                                </div>
                            </div>
                        </form>
                    </Card>

                </div>
                {(noOfPersons > 1 && (guestId !== "")) && (
                    <div>
                        <center><h3>Family Details</h3></center>
                        <FamilyForm guestId={guestId} />
                    </div>
                )}
                {(statusTrue && (guestId !== "")) && (
                    <div>
                        <center><h3>Travel Details</h3></center>
                        <TravelForm guestId={guestId} />
                    </div>
                )}
                {((statusTrue && vehicleRequired === true) && (guestId !== "")) && (
                    <div>
                        <center> <h3>Vehcile Details</h3></center>
                        <VehicleForm guestId={guestId} />
                    </div>
                )}
                {((statusTrue && hotelRequired === true) && (guestId !== "")) && (
                    <div>
                        <center><h3>Hotel Details</h3></center>
                        <HotelForm guestId={guestId} noOfPersons={noOfPersons}
                        subGroupId={subGroupId}
                        groupId={groupId}
                        />
                    </div>
                )}
                <center>
                    <Button
                        icon="refresh"
                        intent={Intent.WARNING} large
                        onClick={this.reloadPage.bind(this)}
                    >
                        Reset
                </Button>
                </center>
            </div>
        )
    }

    handleGuest = async e => {
        e.preventDefault()
        var { fullName, cardName, groupId, phone, cityId, govtID, isInformed, noOfPersons,
            statusId, govtIdType, categoryId, comments, arrivalDate, genderId, serialNo,
            hotelRequired, vehicleRequired, email, subGroupId, modeofInvId, gifts,
            printOver, majorAreaId, minorAreaId, address, inviteMode, depatureDate, called
        } = this.state
        const allGifts = [...gifts];
        this.setState({ loading: true })
        if (govtIdType === "") {
            var govtIdType = _.result(_.find(this.props.govtIdTypesQuery.govtIdTypes, function (item) {
                return item.name === "SELECT";
            }), 'id');
        }
        if (statusId === "") {
            var statusId = _.result(_.find(this.props.statusQuery.attending, function (item) {
                return item.name === "Don't Know";
            }), 'id');

        }

        if (statusId) {
            var statusTrue = _.result(_.find(this.props.statusQuery.attending, function (item) {
                return item.name === "Yes";
            }), 'id');
            if (statusId === statusTrue) {
                this.setState({ statusTrue: true })
            }
        }


        if (arrivalDate === "") {
            arrivalDate = new Date().toISOString()
        }
        if (depatureDate === "") {
            depatureDate = new Date().toISOString()
        }
        var updatedByUserAt = new Date().toISOString()
        var city = cityId.value
        var subGroup = subGroupId.value
        var group = groupId.value
        var category = categoryId.value
        var majorArea = majorAreaId.value
        var minorArea = minorAreaId.value
        await this.props.createGuestMutation({
            variables: {
                fullName, cardName, groupId: group, phone, city, isInformed, govtID,
                govtIdType, noOfPersons, statusId, categoryId: category, comments, arrivalDate, depatureDate,
                hotelRequired, vehicleRequired, email, subGroupId: subGroup, modeOfInvitation: modeofInvId,
                genderId, serialNo, gifts: allGifts, printOver, majorAreaId: majorArea, minorAreaId: minorArea, address, inviteMode,
                updatedByUserAt, called
            },
        }).then(({ data }) => {
            this.setState({
                guestId: data.createGuest.id, loading: false, open: true,
                message: "Guest " + data.createGuest.fullName + "created",
            })
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }
}


const CREATE_GUEST_MUTATION = gql`
  mutation CreateGuestMutation(
    $fullName: String!, $cardName: String!, $groupId: ID, $arrivalDate: DateTime, 
    $phone: String, $city: ID, $govtID: String, $govtIdType: ID, $comments: String,
     $noOfPersons: Int!, $statusId: ID!, $categoryId: ID, $isInformed: Boolean!,
     $hotelRequired: Boolean!, $vehicleRequired: Boolean!, $email: String, $subGroupId: ID,
     $modeOfInvitation: ID, $genderId: ID, $serialNo: String, $depatureDate: DateTime,
     $gifts: [ID], $printOver: Boolean!, $majorAreaId: ID, $minorAreaId: ID, $address: String,
     $inviteMode: String, $updatedByUserAt: DateTime, $called: Boolean,
     ) {
    createGuest(fullName: $fullName, cardName: $cardName, groupId: $groupId,
        phone: $phone, city: $city, govtID: $govtID, noOfPersons: $noOfPersons,
        statusId: $statusId, categoryId: $categoryId, arrivalDate: $arrivalDate,
        isInformed: $isInformed, govtIdType: $govtIdType, comments: $comments,
        hotelRequired: $hotelRequired, vehicleRequired: $vehicleRequired,
        email: $email, subGroupId: $subGroupId, modeOfInvitation: $modeOfInvitation,
        genderId: $genderId, serialNo: $serialNo,
        gifts: $gifts, printOver: $printOver,
        majorAreaId: $majorAreaId, minorAreaId: $minorAreaId,
        address: $address, inviteMode: $inviteMode,
        depatureDate: $depatureDate,
        updatedByUserAt: $updatedByUserAt,
        called: $called
      ) {
      id
       fullName,
       cardName, 
       group{
           id
           name
       }, 
       phone, 
       city {
           id
           name
       }
       modeOfInvitation{
           id
           name
       }
       hotelRequired,
       vehicleRequired,
       isInformed, 
       comments
       govtID,
       govtIDType{
        id
        name
       }, 
       noOfPersons, 
       status{
           id
           name
       }, 
       category{
           id
           name
       }
    }
  }
`

const GIFTS_QUERY = gql`
  query giftsQuery {
    gifts {
      id
      name
    }
  }
`

const AREAS_QUERY = gql`
  query areasQuery {
    areas {
      id
      name
    }
  }
`

const GROUP_QUERY = gql`
  query GroupQuery {
    groups {
      id
      name
    }
  }
`
const MODE_OF_INVITATION = gql`
  query ModeOfInvitation {
    modeofInv {
      id
      name
    }
  }
`

const GENDER_QUERY = gql`
     query GenderQuery{
        genders{
            id
            name
        }
    }
 `

const SUB_GROUP_QUERY = gql`
  query SubGroupQuery($groupId: ID!) {
    subGroups(groupId: $groupId) {
      id
      name
    }
  }
`
const EVENTS_CATEGORY_QUERY = gql`
  query EventsCategoryQuery($id: ID!) {
    category(id: $id) {
      id
      name
      events{
          id
          name
      }
    }
  }
`

const CITY_QUERY = gql`
  query CityQuery {
    cities {
      id
      name
    }
  }
`

const GOVTIDTYPES_QUERY = gql`
  query govtIdTypesQuery {
    govtIdTypes {
      id
      name
    }
  }
`
const STATUS_QUERY = gql`
  query StatusQuery {
    attending {
      id
      name
    }
  }
`
const CATEGORY_QUERY = gql`
  query CategoryQuery {
    categories {
      id
      name
    }
  }
`

const AddGuestMutation = compose(
    graphql(GROUP_QUERY, {
        name: 'groupQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(GIFTS_QUERY, {
        name: 'giftsQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(AREAS_QUERY, {
        name: 'areasQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(GENDER_QUERY, {
        name: 'genderQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(MODE_OF_INVITATION, {
        name: 'modeOfInvitation',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(CITY_QUERY, {
        name: 'cityQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(CATEGORY_QUERY, {
        name: 'categoryQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(GOVTIDTYPES_QUERY, {
        name: 'govtIdTypesQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(STATUS_QUERY, {
        name: 'statusQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(CREATE_GUEST_MUTATION, {
        name: 'createGuestMutation',
        // name of the injected prop: this.props.createGuestMutation...
    }),
)(GuestForm)

const GuestAddForm = withStyles(styles)(AddGuestMutation);

export default withApollo(GuestAddForm);