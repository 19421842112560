import React, { Component, Fragment } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import _, { mapKeys } from 'lodash'
import Select from 'react-select';
import {
    Button, Classes, Intent, Card, Elevation, InputGroup,
    FormGroup, Spinner, TextArea, ControlGroup, HTMLSelect,
} from "@blueprintjs/core";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { DateTimePicker } from 'material-ui-pickers';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toast from '../../../utils/Toast'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 400,
    },
});

class HotelForm extends Component {
    constructor(props) {
        super(props)
        this.handleHotelEdit = this.handleHotelEdit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.changeHotelDate = this.changeHotelDate.bind(this);
        this.getHotelRooms = this.getHotelRooms.bind(this);
        this.selectRoom = this.selectRoom.bind(this);
        this.handleSubGroupChange = this.handleSubGroupChange.bind(this);
        this.toogleGroups = this.toogleGroups.bind(this);
        this.handleDelete = this.handleDelete.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.toogleRooms = this.toogleRooms.bind(this)

        this.state = {
            guestId: this.props.guestId,
            noOfPersons: this.props.noOfPersons,
            id: "",
            hotelRoomId: "",
            roomNumber: "",
            roomType: "",
            comments: "",
            checkIn: Date.now(),
            checkOut: Date.now(),
            open: false,
            message: "",
            loading: false,
            isPooling: false,
            hotelDetails: [],
            hotelId: "",
            hotelRooms: [],
            roomDetails: [],
            groupId: this.props.groupId,
            subGroupId: this.props.subGroupId,
            subGroups: [],
            guestHotelDetails: [],
            pooledGuestHotelDetails: [],
            allGuestHotelDetails: [],
            guestHotelDetailsId: "",
            pooledGuestName: [],
            pooledGuestRoomNumber: "",
            pooledGuestCount: "",
            hid: "",
            guestIntialRoomId: "",
            enableEdit: false,
            randomhotelRoomId: "",
            showGroups: false,
            showRooms: false,
        }
    }

    async componentDidMount() {
        const HotelDetails = await this.props.client.query({
            query: HOTEL_DETAILS_QUERY,
            variables: { guestId: this.props.guestId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var GetHotelDetails = await _.map(HotelDetails.data.guestHotelDetails).map((item) => {
            return item.hotelRoomId
        }).map((sg) => {
            return sg.hotelId.id
        })[0]

        var hotelRoomID = await _.map(HotelDetails.data.guestHotelDetails).map((item) => {
            return item.hotelRoomId
        }).map((sg) => {
            return sg.id
        })[0]


        const hotelId = GetHotelDetails;
        if (hotelId !== undefined) {
            const pooledGuestDetails = await this.props.client.query({
                query: POOL_GUEST_HOTEL_DETAILS,
                variables: { hotelId },
                options: {
                    fetchPolicy: 'network-only',
                }
            })
            var guestHotelDetails = await _.map(pooledGuestDetails.data.pooledGuestDetails).map((item) => {
                return item
            }).map((sg) => {
                if (sg.hotelRoomId.id === hotelRoomID) {
                    return {
                        'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                        'noOfPersons': sg.guestId.noOfPersons, 'id': sg.guestId.id,
                    }
                }
            })
            var hotelRoomID = await _.map(pooledGuestDetails.data.pooledGuestDetails).map((item) => {
                return item
            }).map((sg) => {
                if (sg.hotelRoomId.id === hotelRoomID) {
                    return sg.hotelRoomId.id
                    }
            })
            const hotelRandomRoomId = await hotelRoomID.filter((obj) => obj);
            const guestIntialRoomId = await _.sample(hotelRandomRoomId);
            var guestHDetails = guestHotelDetails.filter((obj) => obj);
            this.setState({
                pooledGuestHotelDetails: guestHDetails,
                guestIntialRoomId,
            })
        }
    }

    handleSwitch = name => event => {
        this.resetForm();
        this.setState({ [name]: event.target.checked });
    };

    async handleSubGroupChange(e) {
        const subGroupId = e.value
        const groupId = this.state.groupId.value;
        this.setState({ subGroupId: e, guestHotelDetails: [] });
        const result = await this.props.client.query({
            query: GUEST_HOTEL_DETAILS,
            variables: { groupId, subGroupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })

        var guestHotelDetails = await _.map(result.data.pooledGuestWithGroups).map((item) => {
            return item
        }).map((sg) => {
            if (sg.hotelRoomId.hotelId.id === this.state.hotelId.value) {
                return {
                    'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                    'noOfPersons': sg.guestId.noOfPersons, 'id': sg.guestId.id,
                }
            }
        })
        var guestHDetails = guestHotelDetails.filter((obj) => obj);
        this.clearGuestSelect()
        this.setState({ guestHotelDetails: guestHDetails })
    }


    async handleGroupChange(e) {
        const groupId = e.value
        this.setState({ groupId: e, subGroupId: [] });
        const result = await this.props.client.query({
            query: SUB_GROUP_QUERY,
            variables: { groupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var subGroups = await _.map(result.data.subGroups).map((item) => {
            return item
        }).map((sg) => {
            return { 'value': sg.id, 'label': sg.name }
        })
        this.setState({ subGroups })

        const guestHotDetails = await this.props.client.query({
            query: GUEST_HOTEL_DETAILS,
            variables: { groupId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var guestHotelDetails = await _.map(guestHotDetails.data.pooledGuestWithGroups).map((item) => {
            return item
        }).map((sg) => {
            if (sg.hotelRoomId.hotelId.id === this.state.hotelId.value) {
                return {
                    'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                    'noOfPersons': sg.guestId.noOfPersons, 'id': sg.guestId.id,
                }
            }
        })
        var guestHDetails = guestHotelDetails.filter((obj) => obj);
        this.clearGuestSelect()
        this.setState({ guestHotelDetails: guestHDetails })
    }

    async handleDelete(id) {
        const hotelRoomId = this.state.hotelRoomId.value ? this.state.hotelRoomId.value: this.state.guestIntialRoomId
        var isPooled = ""
        if(this.state.pooledGuestHotelDetails.length > 1) {
            isPooled = true
        } else {
            isPooled = false
        }
        console.log("isPooled", isPooled)
        await this.props.deleteGuestHotelMutation({
            variables: { id },
        }).then(async (data) => {
            await this.props.editRoom({
                variables: {
                    id: hotelRoomId,
                    isSingle: false,
                    isPooled,
                },
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            // window.location.reload()
        })
        this.props.guestHotelDetails.refetch()
    }

    handleConfirmDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.handleDelete(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    handleHotelEdit = async (item) => {
        this.resetForm();
        this.setState({
            enableEdit: true
        })
        const gId = item.guestId.group ? item.guestId.group.id : "";
        const sgid = item.guestId.subGroup ? item.guestId.subGroup.id : "";
        const sgs = await this.props.client.query({
            query: SUB_GROUP_QUERY,
            variables: { groupId: gId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var subGroups = await _.map(sgs.data.subGroups).map((item) => {
            return item
        }).map((sg) => {
            return { 'value': sg.id, 'label': sg.name }
        })

        this.setState({ subGroups })

        const result = await this.props.client.query({
            query: GUEST_HOTEL_DETAILS,
            variables: { groupId: gId, subGroupId: this.state.subGroupId || sgid },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var guestHotelDetails = await _.map(result.data.pooledGuestWithGroups).map((item) => {
            return item
        }).map((sg) => {
            return {
                'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                'noOfPersons': sg.guestId.noOfPersons
            }
        })
        await this.setState({ guestHotelDetails, allGuestHotelDetails: result.data.pooledGuestWithGroups })

        var group = item.guestId.group ? item.guestId.group : "";
        var groupId = mapKeys(group, (value, key) => {
            if (key === 'name') return 'label';
            if (key === 'id') return 'value';
            if (key === '__typename') return 'type';
            return key;
        });

        var subGroup = item.guestId.subGroup ? item.guestId.subGroup : "";
        var subGroupId = mapKeys(subGroup, (value, key) => {
            if (key === 'name') return 'label';
            if (key === 'id') return 'value';
            if (key === '__typename') return 'type';
            return key;
        });

        var hotel = item.hotelRoomId.hotelId ? item.hotelRoomId.hotelId : "";
        var hotelId = mapKeys(hotel, (value, key) => {
            if (key === 'name') return 'label';
            if (key === 'id') return 'value';
            if (key === '__typename') return 'type';
            return key;
        });

        var guestHDetails = item.guestId ? item.guestId : "";
        var guestHotelDetailsId = mapKeys(guestHDetails, (value, key) => {
            if (key === 'fullName') return 'label';
            if (key === 'id') return 'value';
            if (key === '__typename') return 'type';
            return key;
        });

        var hotelRoom = item.hotelRoomId ? item.hotelRoomId : "";
        var hotelRoomId = mapKeys(hotelRoom, (value, key) => {
            if (key === 'number') return 'label';
            if (key === 'id') return 'value';
            if (key === '__typename') return 'type';
            return key;
        });

        await this.setState({
            ...this.state,
            id: item.id,
            hotelId,
            hotelRoomId,
            guestIntialRoomId: hotelRoomId,
            guestId: this.props.guestId,
            roomNumber: item.roomNumber,
            checkIn: item.checkIn,
            checkOut: item.checkOut,
            roomType: item.roomType,
            isPooling: item.isPooling,
            comments: item.comments,
            groupId,
            subGroupId,
            guestHotelDetailsId,
        })

        const pooledGuestDetails = await this.props.client.query({
            query: POOL_GUEST_HOTEL_DETAILS,
            variables: { hotelId: hotelId.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var guestHotelDetails = await _.map(pooledGuestDetails.data.pooledGuestDetails).map((item) => {
            return item
        }).map((sg) => {
            if (sg.hotelRoomId.id === hotelRoomId.value) {
                return {
                    'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                    'noOfPersons': sg.guestId.noOfPersons, 'id': sg.guestId.id,
                }
            }
        })

        var guestHDetails = guestHotelDetails.filter((obj) => obj);
        this.setState({
            roomDetails: pooledGuestDetails.data.pooledRooms,
            pooledGuestHotelDetails: guestHDetails
        })
        await this.changeHotelDate(hotelId)
    }

    clearGuestSelect() {
        this.setState({
            guestHotelDetailsId: "",
            hotelRoomId: "",
            pooledGuestName: "",
            pooledGuestRoomNumber: "",
            pooledGuestCount: "",
            roomNumber: "",
        })
    }

    async handleGuestHotelDetailsChange(e) {
        const ghDetails = await this.props.client.query({
            query: POOL_GUEST_HOTEL_DETAILS,
            variables: { hotelId: this.state.hotelId.value },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var guestHotelDetails = await _.map(ghDetails.data.pooledGuestDetails).map((item) => {
            return item
        }).map((sg) => {
            if (e !== null) {
                if (sg.hotelRoomId.id === e.value) {
                    return {
                        'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                        'noOfPersons': sg.guestId.noOfPersons, 'id': sg.guestId.id,
                    }
                }
            }
        })
        var guestHDetails = guestHotelDetails.filter((obj) => obj);
        this.setState({ pooledGuestHotelDetails: guestHDetails })
        if (e !== null) {
            this.setState({
                guestHotelDetailsId: e,
                hotelRoomId: e,
                pooledGuestName: e.label,
                pooledGuestRoomNumber: e.roomNumber,
                pooledGuestCount: e.noOfPersons,
                roomNumber: e.roomNumber,
            })
        } else {
            this.setState({
                guestHotelDetailsId: "",
                hotelRoomId: "",
                pooledGuestName: "",
                pooledGuestRoomNumber: "",
                pooledGuestCount: "",
                roomNumber: "",
            })
        }
    }

    async getHotelRooms() {
        const hotelId = this.state.hotelId.value;
        var hotelRooms = []
        const result = await this.props.client.query({
            query: ROOMS,
            variables: { hotelId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        const hRS = result.data.rooms
        const hotelRandomId = hRS.map(item => {
            return item.id
        })
        const hotelRooms = hRS.map(item => {
            return { 'label': item.number, 'value': item.id }
        })
        const randomhotelRoomId = await _.sample(hotelRandomId);
        this.setState({ hotelRooms, randomhotelRoomId });
    }

    resetForm() {
        this.setState({
            ...this.state,
            guestId: this.props.guestId,
            id: "",
            hotelRoomId: "",
            roomNumber: "",
            roomType: "",
            comments: "",
            isPooling: false,
            checkIn: Date.now(),
            checkOut: Date.now(),
            hotelId: "",
            roomDetails: [],
            groupId: "",
            subGroupId: "",
            subGroups: [],
            guestHotelDetails: [],
            guestHotelDetailsId: "",
            guestIntialRoomId: "",
            pooledGuestHotelDetails: []
        })
    }

    toogleGroups() {
        if (!this.state.showGroups) {
            this.setState({ showGroups: true })
        } else {
            this.setState({ showGroups: false })
        }

    }

    toogleRooms() {
        if (!this.state.showRooms) {
            this.setState({ showRooms: true })
        } else {
            this.setState({ showRooms: false })
        }

    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };


    async selectRoom(e) {
        var hotelRoomId = ""
        if (this.state.showRooms) {
            hotelRoomId = e.value
            console.log("hotelRoomId from rooms", e)
        } else {
            hotelRoomId = this.state.randomhotelRoomId
        }
        console.log("hotelRoomId", hotelRoomId)
        console.log("value", e.value)
        console.log("randomhotelRoomId", this.state.randomhotelRoomId)
        const result = await this.props.client.query({
            query: ROOM_DETAILS,
            variables: { id: hotelRoomId },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        const roomDetails = result.data.roomDetails
        const roomNumber = result.data.roomDetails.map((item) => {
            return item.number
        })[0];

        if (this.state.showRooms) {
            this.setState({ hotelRoomId: e, roomDetails, roomNumber })
        } else {
            this.setState({ hotelRoomId: this.state.randomhotelRoomId, roomDetails, roomNumber })
        }

    }

    async changeHotel(e) {
        const isPooling = this.state.isPooling;
        this.resetForm();
        this.setState({ isPooling })
        this.changeHotelDate(e)

    }

    async changeHotelDate(e) {

        const id = e.value;
        this.setState({ loading: true })
        const result = await this.props.client.query({
            query: HOTEL_DETAIL,
            variables: { id },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        const hotelDetails = result.data.hotelDetail
        const checkOut = hotelDetails.map((item) => {
            return item.checkOut
        })[0];
        const checkIn = hotelDetails.map((item) => {
            return item.checkIn
        })[0];

        await this.setState({
            checkIn: checkIn,
            checkOut: checkOut,
            hotelId: e,
            loading: false
        })
        await this.getHotelRooms()

        const ghDetails = await this.props.client.query({
            query: POOL_GUEST_HOTEL_DETAILS,
            variables: { hotelId: id },
            options: {
                fetchPolicy: 'network-only',
            }
        })
        var guestHotelDetails = await _.map(ghDetails.data.pooledGuestDetails).map((item) => {
            return item
        }).map((sg) => {
            return {
                'value': sg.hotelRoomId.id, 'label': sg.guestId.fullName, 'roomNumber': sg.hotelRoomId.number,
                'noOfPersons': sg.guestId.noOfPersons, 'id': sg.guestId.id,
            }
        })
        this.setState({ guestHotelDetails })
        console.log("this.state.hotelRoomId.value", this.state.hotelRoomId)
        if ((!this.state.isPooling && this.state.hotelRoomId.value === undefined) && this.state.guestIntialRoomId !== null) {
            console.log("coming here even though already hotel is attached");
            await this.selectRoom(this.state.hotelId)
        }

    }

    render() {
        const { classes } = this.props;
        const { id, comments, open, message, hotelRoomId, checkIn, checkOut, groupId, subGroupId, subGroups, randomhotelRoomId,
            allGuestHotelDetails, pooledGuestCount, pooledGuestName, pooledGuestRoomNumber, noOfPersons, enableEdit, showGroups,
            loading, isPooling, roomNumber, roomType, hotelId, hotelRooms, roomDetails, guestHotelDetails, showRooms,
            pooledGuestHotelDetails,
            guestHotelDetailsId } = this.state
        return (
            <div>
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <div className="form-container">

                    <Card className="card-global" elevation={Elevation.TWO}>
                        <h4>Guest Hotel Details</h4>
                        {
                            this.props.guestHotelDetails.guestHotelDetails && this.props.guestHotelDetails.guestHotelDetails.map((item) => {
                                return (
                                    <ul key={item.id} className="bp3-list-unstyled">
                                        <li key={item.id}>
                                            <div className="families"> {item.hotelRoomId.number}
                                                <IconButton className="action-icons" onClick={() => this.handleConfirmDelete(item.id)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                                <IconButton className="action-icons" onClick={() => this.handleHotelEdit(item)}>
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        <hr />
                        {(pooledGuestHotelDetails.length !== 0 && !isPooling) && (
                            <div>
                                <h3>Pool Guest List:</h3>
                                <div>
                                    <Paper className={classes.root}>
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Guest Name</TableCell>
                                                    <TableCell numeric>No Of Persons</TableCell>
                                                    <TableCell numeric>Room Number</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pooledGuestHotelDetails && pooledGuestHotelDetails.map((item, index) => {
                                                    return (
                                                        <TableRow key={item.id}>
                                                            <TableCell component="th" scope="row">
                                                                {item.label}
                                                            </TableCell>
                                                            <TableCell numeric>{item.noOfPersons}</TableCell>
                                                            <TableCell numeric>{item.roomNumber}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </div>
                            </div>

                        )}
                        {((this.props.guestHotelDetails.guestHotelDetails && this.props.guestHotelDetails.guestHotelDetails.length <= 0) || enableEdit) && (
                            <form onSubmit={this.handleHotelDetails} autocomplete="off">
                                <FormGroup
                                    label="Pooling"
                                    labelFor="pooling-input"
                                    className="switchControl"
                                >
                                    <Switch
                                        checked={isPooling}
                                        onChange={this.handleSwitch('isPooling')}
                                        value="isPooling"
                                        color="primary"
                                    />
                                </FormGroup>
                                <div className="form-selects">
                                    <FormGroup
                                        label="Select Hotel"
                                        labelFor="hotelDetails-input"
                                    >
                                        <Select
                                            className="basic-single select-control"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="group"
                                            value={hotelId}
                                            onChange={(e) => this.changeHotel(e)}
                                            options={this.props.hotelDetailsQuery.hotelDetailses && this.props.hotelDetailsQuery.hotelDetailses.map(item => {
                                                return { 'value': item.id, 'label': item.name }
                                            })}
                                        />


                                        <FormHelperText>Select from master hotel details</FormHelperText>
                                        {((randomhotelRoomId === undefined && !isPooling) && hotelId !== "") && (<p style={{ color: 'red', marginTop: 5 }}>
                                            No Rooms available in this hotel, please select another hotel
                                    </p>)}
                                    </FormGroup>
                                    {(!isPooling && hotelId !== "") && (
                                        <div className={Classes.DIALOG_FOOTER}>
                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                <Button style={{ marginTop: 30 }} small icon="chevron-down" minimal onClick={this.toogleRooms} intent={Intent.WARNING} >Show Rooms </Button>
                                            </div>
                                        </div>
                                    )}
                                    {(isPooling && hotelId !== "") && (
                                        <div className={Classes.DIALOG_FOOTER}>
                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                <Button style={{ marginTop: 30 }} small icon="chevron-down" minimal onClick={this.toogleGroups} intent={Intent.WARNING} >Toogle Groups </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {(isPooling) && (
                                    <div>
                                        {(showGroups) && (
                                            <div className="form-selects">
                                                <FormGroup
                                                    label="Group"
                                                    labelFor="group-input"
                                                >
                                                    <Select
                                                        className="basic-single select-control"
                                                        classNamePrefix="select"
                                                        isSearchable={true}
                                                        name="group"
                                                        value={groupId}
                                                        onChange={(e) => { this.handleGroupChange(e) }}
                                                        options={this.props.groupQuery.groups && this.props.groupQuery.groups.map((item) => {
                                                            return { 'value': item.id, 'label': item.name }
                                                        })}
                                                    />
                                                </FormGroup>
                                                <FormGroup
                                                    label="Sub Group"
                                                    labelFor="subgroup-input"
                                                >
                                                    <Select
                                                        className="basic-single select-control"
                                                        classNamePrefix="select"
                                                        isSearchable={true}
                                                        name="subGroup"
                                                        value={subGroupId}
                                                        onChange={(e) => this.handleSubGroupChange(e)}
                                                        options={subGroups}
                                                    />
                                                </FormGroup>
                                            </div>
                                        )}
                                        <FormGroup
                                            label="Select Guest"
                                            labelFor="sguest-input"
                                        >
                                            <Select
                                                className="basic-single select-control"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                isClearable={true}
                                                name="guestHotelDetails"
                                                value={guestHotelDetailsId}
                                                onChange={(e) => { this.handleGuestHotelDetailsChange(e) }}
                                                options={guestHotelDetails}
                                            />
                                        </FormGroup>

                                    </div>
                                )}
                                {(guestHotelDetails.length === 0 && isPooling) && (hotelId !== "") && (
                                    <p style={{ color: 'orange', marginTop: 5 }}> Guests are not available</p>
                                )}
                                {(showRooms) && (
                                    <FormGroup
                                        label="Select Room"
                                        labelFor="rooms-input"
                                    >
                                        <Select
                                            className="basic-single select-control"
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            name="guestHotelDetails"
                                            value={hotelRoomId}
                                            onChange={(e) => this.selectRoom(e)}
                                            options={hotelRooms}
                                        />

                                        <FormHelperText>Select Room Number</FormHelperText>
                                    </FormGroup>
                                )}
                                {(!isPooling || guestHotelDetails.length === 0) && (
                                    <div>
                                        <h3> Hotel Details </h3>
                                        <p>
                                            {roomDetails && roomDetails.map((item, index) => {
                                                return (
                                                    <div>
                                                        <p> Hotel: {item.hotelId.name.toString()}  </p>
                                                        <p> Room Number: {item.number.toString()}</p>
                                                        <p> Hotel Address: {item.hotelId.address ? item.hotelId.address.toString() : "N/A"}</p>
                                                    </div>
                                                )
                                            })}
                                        </p>
                                    </div>
                                )}

                                {(pooledGuestHotelDetails.length !== 0 && isPooling) && (hotelId !== "") && (
                                    <div>
                                        <h3>Pool Guest List:</h3>
                                        <div>
                                            <Paper className={classes.root}>
                                                <Table className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Guest Name</TableCell>
                                                            <TableCell numeric>No Of Persons</TableCell>
                                                            <TableCell numeric>Room Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {pooledGuestHotelDetails && pooledGuestHotelDetails.map((item, index) => {
                                                            return (
                                                                <TableRow key={item.id}>
                                                                    <TableCell component="th" scope="row">
                                                                        {item.label}
                                                                    </TableCell>
                                                                    <TableCell numeric>{item.noOfPersons}</TableCell>
                                                                    <TableCell numeric>{item.roomNumber}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Paper>
                                        </div>
                                    </div>

                                )}
                                <div style={{ marginTop: 20 }}>
                                    <FormGroup
                                        label="Room Number"
                                        labelFor="roomNumber-input"
                                    >
                                        <InputGroup
                                            className="formControl"
                                            id="roomNumber-input"
                                            value={roomNumber}
                                            disabled
                                            name="roomNumber"
                                            placeholder="Selected Room Number"
                                            onChange={this.handleChange} />
                                    </FormGroup>
                                </div>
                                <FormGroup
                                    label="Room Type"
                                    labelFor="roomType-input"
                                >
                                    <InputGroup
                                        className="formControl"
                                        id="roomType-input"
                                        value={roomType}
                                        name="roomType"
                                        placeholder="Enter Room Type"
                                        onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup
                                    label="Check In"
                                    labelFor="checkIn-input"
                                >
                                    <DateTimePicker
                                        clearable
                                        value={checkIn}
                                        onChange={(e) => { e !== null && this.setState({ checkIn: e.toJSON() }) }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Check Out"
                                    labelFor="checkOut-input"
                                >
                                    <DateTimePicker
                                        clearable
                                        value={checkOut}
                                        onChange={(e) => { e !== null && this.setState({ checkOut: e.toJSON() }) }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Comments"
                                    labelFor="hotel-input"
                                    className="switchControl"
                                >
                                    <TextArea
                                        className="bp3-fill"
                                        large={true}
                                        intent={Intent.PRIMARY}
                                        onChange={event => this.setState({ comments: event.target.value })}
                                        value={comments}
                                    />
                                </FormGroup>

                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        {id ? <Button type="submit" intent={Intent.PRIMARY} >
                                            Update
                                        </Button> :
                                            <Button type="submit" intent={Intent.PRIMARY} >
                                                Save
                                    	</Button>
                                        }
                                    </div>
                                </div>
                            </form>
                        )}
                    </Card>
                </div>
            </div>
        )
    }

    handleHotelDetails = async e => {
        e.preventDefault()
        this.setState({ loading: true })
        var { id, comments, hotelRoomId, checkIn, checkOut, randomhotelRoomId,
            guestId, isPooling, roomNumber, roomType, guestIntialRoomId } = this.state
        if (!isPooling) {
            hotelRoomId = hotelRoomId.value ? hotelRoomId.value : randomhotelRoomId
        } else {
            hotelRoomId = hotelRoomId.value ? hotelRoomId.value : randomhotelRoomId
        }
        if (id) {
            await this.props.editGuestHotelMutation({
                variables: {
                    id,
                    comments, hotelRoomId,
                    isPooling, roomNumber, roomType,
                    checkIn, checkOut,
                },
            }).then(async (data) => {
                if (isPooling) {
                    const id = hotelRoomId;
                    await this.props.editRoom({
                        variables: {
                            id,
                            isPooled: true
                        },
                    }).catch(error => {
                        this.setState({
                            open: true,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                }
                const id = hotelRoomId;
                if (!isPooling && id !== guestIntialRoomId) {
                    const changedHotelRoomid = guestIntialRoomId
                    await this.props.editRoom({
                        variables: {
                            id: changedHotelRoomid,
                            isSingle: false
                        },
                    }).catch(error => {
                        this.setState({
                            open: true,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                } else {
                    await this.props.editRoom({
                        variables: {
                            id,
                            isSingle: true
                        },
                    }).catch(error => {
                        this.setState({
                            open: true,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                }
                this.resetForm();
                this.props.guestHotelDetails.refetch()
                this.setState({
                    loading: false, open: true,
                    message: "Hotel Details Updated",
                })
                window.location.reload()
            }).catch(error => {
                this.setState({
                    open: true, loading: false,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 2000);
        } else {
            await this.props.createGuestHotelMutation({
                variables: {
                    comments, hotelRoomId, checkIn, checkOut,
                    guestId, isPooling, roomNumber, roomType
                },
            }).then(async (data) => {
                if (isPooling) {
                    const id = hotelRoomId;
                    await this.props.editRoom({
                        variables: {
                            id,
                            isPooled: true
                        },
                    }).catch(error => {
                        this.setState({
                            open: true,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                } else {
                    const id = hotelRoomId;
                    await this.props.editRoom({
                        variables: {
                            id,
                            isSingle: true
                        },
                    }).catch(error => {
                        this.setState({
                            open: true,
                            message: "Error occured " + error,
                            loading: false
                        })
                    })
                }
                this.resetForm();
                this.props.guestHotelDetails.refetch()
                this.setState({
                    loading: false, open: true,
                    message: "Hotel Details Updated",
                })
                window.location.reload()
            }).catch(error => {
                this.setState({
                    open: true, loading: false,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 2000);
        }

    }
}


const CREATE_GUEST_HOTEL_MUTATION = gql`
          mutation createGuestHotelMutation(
            $guestId: ID!,
            $checkIn: DateTime, $checkOut: DateTime,
            $comments: String, $hotelRoomId: ID!, $isPooling: Boolean!,
            $roomNumber: String!, $roomType: String
     ){
    createGuestHotelDetails(guestId: $guestId, comments: $comments,
        hotelRoomId: $hotelRoomId, isPooling: $isPooling,
        roomNumber: $roomNumber, roomType: $roomType, checkIn: $checkIn, checkOut: $checkOut
      ) {
      id
    }
  }
`

const EDIT_GUEST_HOTEL_MUTATION = gql`
          mutation editGuestHotelMutation(
              $id: ID!,
              $checkIn: DateTime, $checkOut: DateTime,
            $comments: String, $hotelRoomId: ID!, $isPooling: Boolean!,
            $roomNumber: String!, $roomType: String
     ){
    updateGuestHotelDetails(id: $id, comments: $comments,
        hotelRoomId: $hotelRoomId, isPooling: $isPooling,
        roomNumber: $roomNumber, roomType: $roomType, checkIn: $checkIn, checkOut: $checkOut
      ) {
      id
      hotelRoomId{
          id
          number
      }
    }
  }
`
const EDIT_ROOM = gql`
          mutation editRoom(
              $id: ID!,
              $isPooled: Boolean
              $isSingle: Boolean
        )   
        {
            updateRoom(id: $id, isPooled: $isPooled, isSingle: $isSingle) {
            id
         }
  }
`
const GROUP_QUERY = gql`
  query GroupQuery {
    groups {
      id
      name
    }
  }
`
const SUB_GROUP_QUERY = gql`
  query SubGroupQuery($groupId: ID!) {
    subGroups(groupId: $groupId) {
      id
      name
    }
  }
`

const POOL_GUEST_HOTEL_DETAILS = gql`
query pooledGuestDetails($hotelId: ID!) {
    pooledGuestDetails(hotelId: $hotelId){
      id
    roomNumber
    hotelRoomId{
        id
        number
        isPooled
        hotelId{
            id
            name
        }
    }
      guestId{
          id
        fullName
        noOfPersons
        group{
          id
          name
        }
        subGroup{
          id
          name
        }
      }
    }
  }
`

const GUEST_HOTEL_DETAILS = gql`
query pooledGuestWithGroups($groupId: ID, $subGroupId: ID){
  pooledGuestWithGroups(groupId: $groupId, subGroupId: $subGroupId){
      id
      roomNumber
    hotelRoomId{
        id
        number
        isPooled
        hotelId{
            id
            name
        }
    }
      guestId{
          id
        fullName
        noOfPersons
        group{
          id
          name
        }
        subGroup{
          id
          name
        }
      }
    }
  }
`

const HOTEL_DETAILS = gql`
  query HotelDetailsQuery {
    hotelDetailses{
        id
        name
        address
        totalRooms
        availableRooms
        checkIn
        checkOut
    }
  }
`
const ROOM_DETAILS = gql`
 query roomDetailsQuery($id: ID!) {
    roomDetails(id: $id){ 
        id
        number
        isPooled
        hotelId{
            id
            name
        }
        guestHotelDetailsId{
            id
            roomNumber
            guestId{
                id
                fullName
                noOfPersons
                group{
                    id
                    name
                }
            }
         }
        }
    }
`
const POOLED_ROOMS = gql`
  query pooledRoomsQuery($hotelId: ID!) {
    pooledRooms(hotelId: $hotelId){
        id
        hotelId{
            id
            name
        }
        number
        isPooled
        guestHotelDetailsId{
            id
            roomNumber
            guestId{
                id
                fullName
                noOfPersons
                group{
                    id
                    name
                }
            }
        }
    }
  }
`

const ROOMS = gql`
  query roomsQuery($hotelId: ID!) {
    rooms(hotelId: $hotelId){
        id
        hotelId{
            id
            name
        }
        number
        isPooled
    }
  }
`

const HOTEL_DETAIL = gql`
  query HotelDetail($id: ID!) {
    hotelDetail(id: $id){
        id
        name
        address
        totalRooms
        availableRooms
        checkIn
        checkOut
    }
  }
`
const HOTEL_DETAILS_QUERY = gql`
  query GuestHotelDetails($guestId: ID!) {
    guestHotelDetails(guestId: $guestId){
        id
        guestId{ 
            id 
          fullName
          noOfPersons
          group{
              id
              name
          }
          subGroup{
              id
              name
          }
        }
        hotelRoomId { 
            id
            number
        hotelId{
            id
            name
        }
        }
        roomNumber
        isPooling
        checkIn
        checkOut
        roomType
        comments
    }
  }
`

const DELETE_HOTEL_GUEST_MUTATION = gql`
  mutation deleteGuestHotel($id: ID!) {
    deleteGuestHotelDetails(id: $id) {
        id
    }
}
`

HotelForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

const HotelFormMutation = compose(
    graphql(HOTEL_DETAILS, {
        name: 'hotelDetailsQuery',
        options: {
            fetchPolicy: 'network-only',
        },
    }),
    graphql(HOTEL_DETAILS_QUERY, {
        name: 'guestHotelDetails',
        options: (props) => ({
            variables: {
                guestId: props.guestId
            },
            fetchPolicy: 'network-only',
        }),
    }),
    graphql(GROUP_QUERY, {
        name: 'groupQuery',
        options: {
            fetchPolicy: 'network-only',
        },
    }),
    graphql(EDIT_ROOM, {
        name: 'editRoom',
        options: {
            refetchQueries: ['hotelDetailsQuery', 'guestHotelDetails',]
        }
    }),
    graphql(EDIT_GUEST_HOTEL_MUTATION, {
        name: 'editGuestHotelMutation',
        options: {
            refetchQueries: ['hotelDetailsQuery', 'guestHotelDetails']
        }
        // name of the injected prop: this.props.createHotelMutation...
    }),
    graphql(CREATE_GUEST_HOTEL_MUTATION, {
        name: 'createGuestHotelMutation',
        options: {
            refetchQueries: ['hotelDetailsQuery', 'guestHotelDetails',]
        }
        // name of the injected prop: this.props.createHotelMutation...
    }),
    graphql(DELETE_HOTEL_GUEST_MUTATION, {
        name: 'deleteGuestHotelMutation', // name of the injected prop: this.props.deleteGroupMutation...
    }),
)(HotelForm)

const HotelFormStyles = withStyles(styles)(HotelFormMutation);

export default withApollo(HotelFormStyles)