import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup
} from "@blueprintjs/core"
import BackIcon from "@material-ui/icons/ArrowBack"
import moment from 'moment'
import { DateTimePicker } from 'material-ui-pickers'

class EditNewFlightPage extends Component {

    constructor(props) {
        super(props)
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleFlight = this.handleFlight.bind(this);
        
        this.state = {
            name: this.props.name,
            id: this.props.id,
            number: this.props.number,
            source: this.props.source,
            destination: this.props.destination,
            startDate: this.props.startDate,
            arrivalDate: this.props.arrivalDate,
            mobile: this.props.mobile,
            transportId: this.props.transportId,
        };
    }

    handleDateChange = (date) => {
        this.setState({ checkOut: date });
    }

    handleValueChange = (_valueAsNumber, valueAsString) => {
        this.setState({ totalRooms: valueAsString });
    };

    handleEditChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFlight = async e => {
        e.preventDefault()
        const {id, name, number, source, destination, startDate, arrivalDate, mobile} = this.state
        await this.props.editFlightMutation({
            variables: {id, name, number, source, destination, startDate, arrivalDate, mobile},
        })
        this.props.goBack();
    }

    render() {
        const { name, number, source, destination, startDate, arrivalDate, mobile, } = this.state
        return (
            <div className="form-content">
              <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        EDIT Flight
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                    <form onSubmit={this.handleFlight}>
                    <input type="hidden" value={this.state.id} />
                            <FormGroup
                                label="Name"
                                labelFor="name-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="name-input" value={name}
                                    required="required"
                                    name="name"
                                    onChange={this.handleEditChange}
                                    placeholder="Name of Flight" />
                            </FormGroup>
                            <FormGroup
                                label="Number"
                                labelFor="number-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="number-input" value={number}
                                    required="required"
                                    name="number"
                                    onChange={this.handleEditChange}
                                    placeholder="Flight Number" />
                            </FormGroup>
                            <FormGroup
                                label="Source"
                                labelFor="source-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="source-input" value={source}
                                    required="required"
                                    name="source"
                                    onChange={this.handleEditChange}
                                    placeholder="Source" />
                            </FormGroup>
                            <FormGroup
                                label="Destination"
                                labelFor="destination-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="destination-input" value={destination}
                                    required="required"
                                    name="destination"
                                    onChange={this.handleEditChange}
                                    placeholder="Destination" />
                            </FormGroup>
                            <FormGroup
                                label="Contact Number"
                                labelFor="mobile-input"
                                labelInfo="(required)"
                            >
                                <InputGroup
                                    id="mobile-input"
                                    value={mobile}
                                    name="mobile"
                                    placeholder="Enter a number..."
                                    onChange={this.handleEditChange} />
                            </FormGroup>
                            <FormGroup
                                label="Start Date"
                                labelFor="startDate-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={startDate}
                                    onChange={(e) => { this.setState({ startDate: moment(e).toJSON() }) }}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Arrival Date"
                                labelFor="arrivalDate-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={arrivalDate}
                                    onChange={(e) => { this.setState({ arrivalDate: moment(e).toJSON() }) }}
                                />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

    
}

const EDIT_FLIGHT_MUTATION = gql`
  mutation EditFlightMutation($id: ID!, $name: String!, $number: String!, $source: String!, $destination: String!, $startDate: DateTime!, $arrivalDate: DateTime!, $mobile: String) {
    updateTransportDetails(id: $id, name: $name, number: $number,
    source: $source, destination: $destination, startDate: $startDate, 
    arrivalDate: $arrivalDate, mobile: $mobile
     ) {
      id
      name
      number
      source
      destination
      startDate
      arrivalDate
      mobile
      transportId {
        id
      }
    }
  }
`
const EditFlightWithMutation = graphql(EDIT_FLIGHT_MUTATION, {
    name: 'editFlightMutation', // name of the injected prop: this.props.editFlightMutation...
})(EditNewFlightPage)

export default withRouter(EditFlightWithMutation)