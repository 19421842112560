import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { graphql, withApollo, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import { AUTH_TOKEN } from '../constant'
import {
  FormGroup, H1, Intent, Button,
  InputGroup,
  Tooltip,
} from "@blueprintjs/core"

class AddNewUser extends Component {
  state = {
    email: '',
    password: '',
    name: '',
    isAdmin: false,
    showPassword: false,
    data: [],
  }
  handleData = data => {
    this.setState({ data })
    console.log("data", this.state.data)
  }

  handleLockClick = () => this.setState({ showPassword: !this.state.showPassword })

  render() {
    const { showPassword } = this.state;

    const lockButton = (
      <Tooltip content={`${showPassword ? "Hide" : "Show"} Password`}>
        <Button
          icon={showPassword ? "unlock" : "lock"}
          intent={Intent.WARNING}
          minimal={true}
          onClick={this.handleLockClick}
        />
      </Tooltip>
    )

    return (
      <div className="pa4 flex justify-center bg-white">
        <div>
          <center>
            <h4>Add new user to manage the system</h4>
            <form onSubmit={this._addNewUser}>
              <FormGroup>
                <InputGroup id="name-input"
                  type="text"
                  large="true"
                  onChange={e => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  placeholder="Enter Name" />
              </FormGroup>

              <FormGroup>
                <InputGroup id="email-input"
                  type="email"
                  large="true"
                  onChange={e => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  placeholder="Enter Email address" />

              </FormGroup>

              <FormGroup>
                <InputGroup
                  rightElement={lockButton}
                  placeholder="Enter password"
                  large="true"
                  type={showPassword ? "text" : "password"}
                  onChange={e => this.setState({ password: e.target.value })}
                  value={this.state.password}
                >
                </InputGroup>
              </FormGroup>
              <Button
                disabled={
                  !this.state.email || !this.state.name || !this.state.password
                }
                type="submit"
                intent="success" text="Add User" />
            </form>
          </center>
        </div>
      </div>
    )
  }

  _addNewUser = async e => {
    e.preventDefault()
    const { email, name, password, isAdmin } = this.state
    const result = await this.props.AddNewUserMutation({
      variables: {
        name,
        email,
        password,
        isAdmin
      },
    })
    window.location.reload()
  }
}


const AddNewUser_MUTATION = gql`
  mutation AddNewUserMutation($email: String!, $password: String!, $name: String!, $isAdmin: Boolean!) {
    addNewUser(email: $email, hashword: $password, name: $name, isAdmin: $isAdmin) {
      token
      user {
        id
        name
        email
        isAdmin
      }
    }
  }
`
const CreateGroupWithMutation = compose(
  graphql(AddNewUser_MUTATION, { name: 'AddNewUserMutation' }),
)(AddNewUser)

export default withApollo(CreateGroupWithMutation)