import React, { Component } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MUIDataTable from "mui-datatables";
import { Spinner } from "@blueprintjs/core";
import CustomAddIcon from '../../constants/CustomAddIcon'
import CustomToolbarSelect from '../../constants/CustomToolbarSelect'
import { confirmAlert } from 'react-confirm-alert'
import AddNewVehiclePage from './AddNewVehiclePage'
import EditNewVehiclePage from './EditNewVehiclePage'
import moment from 'moment'

const columns = [
  {
    name: "Name",
    key: "name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Mobile",
    key: "mobile",
    options: {
      filter: true,
      sort: true,
      display: true,
    }
  },
  {
    name: "Comments",
    key: "comments",
    options: {
      filter: true,
      sort: true,
      display: true,
    }
  },
  {
    name: "Actions",
    key: "action",
    options: {
      filter: false,
      sort: false,
    }
  },
];


class Vehicle extends Component {

  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
    this.goBack = this.goBack.bind(this)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isVehicle: false,
      isVehicleEditing: false,
      id: "",
      name: "",
      mobile: "",
      comments: "",
    }
  }

  goBack() {
    this.props.vehicleQuery.refetch()
    this.setState({
      isVehicle: false,
      isVehicleEditing: false,
    })
  }

  refresh() {
    this.props.vehicleQuery.refetch()
  }

  handleEdit(name, id, mobile, comments) {
    this.setState({
      isVehicleEditing: true,
      id: id,
      name: name,
      mobile: mobile,
      comments: comments
    })
  }

  async handleDelete(id) {
    await this.props.deleteVehicleMutation({
      variables: { id },
    })
    this.props.vehicleQuery.refetch()
  }

  handleConfirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      isVehicle: true
    })
  }

  render() {
    const { name, id, mobile, comments } = this.state
    if (this.props.vehicleQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      <div>
        {this.state.isVehicle ?
          <AddNewVehiclePage goBack={this.goBack} />
          :
          this.state.isVehicleEditing ?
            <EditNewVehiclePage id={id} name={name}
              mobile={mobile}
              comments={comments}
              goBack={this.goBack} />
            :
            <MUIDataTable
              title={"Vehicles"}
              data={this.props.vehicleQuery.vehicles && this.props.vehicleQuery.vehicles.map(vehicle => {
                const { name, id, mobile, comments } = vehicle;
                return [name.toString(),
                mobile.toString(),
                comments.toString(),
                <CustomToolbarSelect
                  handleEdit={() => this.handleEdit(name, id, mobile, comments
                  )}
                  handleDelete={() => this.handleConfirmDelete(id)}
                />];
              })}
              columns={columns}
              options={{
                filter: true,
                selectableRows: false,
                filter: false,
                filterType: 'dropdown',
                responsive: 'stacked',
                rowsPerPage: 5,
                customToolbar: () => <CustomAddIcon title="ADD Vehicle" refresh={this.refresh} handler={this.handler} />
              }}
            />
        }
      </div>
    )
  }
}
const DELETE_VEHICLE_MUTATION = gql`
  mutation DeleteVehicleMutation($id: ID!) {
    deleteVehicleDetails(id: $id) {
      id
    }
  }
`
const VEHICLE_QUERY = gql`
  query vehicles{
    vehicles {
      id
      name
      mobile
     comments
    }
  }
`

export default compose(
  graphql(VEHICLE_QUERY, {
    name: 'vehicleQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(DELETE_VEHICLE_MUTATION, {
    name: 'deleteVehicleMutation', // name of the injected prop: this.props.deleteVehicleMutation...
  }),
)(Vehicle)