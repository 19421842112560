import React, { Component } from "react";
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup, Spinner
} from "@blueprintjs/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BackIcon from "@material-ui/icons/ArrowBack";
import Toast from '../../utils/Toast'

class AddNewCategoryPage extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleCategory = this.handleCategory.bind(this);
        
        this.state = {
            name: "",
            loading: false,
            open: false,
            message: "",
            checkboxes: [],
        };
    }
    componentWillMount = () => {
        this.state.checkboxes = new Set();
    }

    resetForm() {
        this.setState({
            name: "",
            loading: false,
            open: false,
            message: "",
        });
    }

    toggleCheckbox = item => {
        var checkboxes = this.state.checkboxes;

        if (checkboxes.has(item.id)) {
            checkboxes.delete(item.id);
        } else {
            checkboxes.add(item.id);
        }
        this.setState({
            checkboxes
        })
    }

    handleChange(event) {
        this.setState({ name: event.target.value });
    }

    async handleCategory(e) {
        e.preventDefault()
        this.setState({ loading: true })
        const { name, checkboxes } = this.state
        const events = [...checkboxes];
        await this.props.createCategoryMutation({
            variables: { name, events },
        }).then(({ data }) => {
            this.setState({
                loading: false, open: true,
                message: data.createCategory.name + " " + "Added",
            })
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
        this.resetForm();
    }

    render() {
        const { open, message, loading } = this.state
        return (
            <div className="form-content">
             <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        ADD Category
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleCategory}>
                            <FormGroup
                                label="Name"
                                labelFor="text-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="text-input" value={this.state.name}
                                    required="required"
                                    onChange={this.handleChange}
                                    placeholder="Name of category" />
                            </FormGroup>
                            <FormGroup>
                                <h5>Select the applied events to this category</h5>
                                {this.props.eventsQuery.events && this.props.eventsQuery.events.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    key={item.id}
                                                    checked={item.checked}
                                                    onChange={() => this.toggleCheckbox(item)}
                                                    value={item.id}
                                                    color="primary"
                                                />
                                            }
                                            label={item.name}
                                        />
                                    )
                                }
                                )}
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

   
}

const EVENTS_QUERY = gql`
  query eventsQuery {
    events {
      id
      name
    }
  }
`
const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateCategoryMutation($name: String!, $events: [ID]) {
    createCategory(name: $name, events: $events) {
      id
      name
    }
  }
`
const CreateCategoryWithMutation = compose(
    graphql(CREATE_CATEGORY_MUTATION, {
        name: 'createCategoryMutation', // name of the injected prop: this.props.createCategoryMutation...
    }),
    graphql(EVENTS_QUERY, {
        name: 'eventsQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        }
    }),
)(AddNewCategoryPage)

export default withApollo(CreateCategoryWithMutation)