import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MUIDataTable from 'mui-datatables'
import { Spinner } from "@blueprintjs/core"
import AddNewCategoryPage from './AddNewCategoryPage'
import EditNewCategoryPage from './EditNewCategoryPage'
import CustomAddIcon from '../../constants/CustomAddIcon'
import CustomToolbarSelect from '../../constants/CustomToolbarSelect'
import { confirmAlert } from 'react-confirm-alert'


const columns = [
  {
    name: "Name",
    key: "name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Actions",
    key: "action",
    options: {
      filter: false,
      sort: false,
    }
  },
];

class Category extends Component {
  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
    this.goBack = this.goBack.bind(this)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isCategory: false,
      isCategoryEditing: false,
      id: "",
      name: ""
    }
  }

  goBack() {
    this.props.categoryQuery.refetch()
    this.setState({
      isCategory: false,
      isCategoryEditing: false,
    })
  }

  refresh() {
    this.props.categoryQuery.refetch()
  }

  handleEdit(name, id) {
    this.setState({
      isCategoryEditing: true,
      id: id,
      name: name,
    })
  }

  async handleDelete(id) {
    await this.props.deleteCategoryMutation({
      variables: { id },
    })
    this.props.categoryQuery.refetch()
  }

  handleConfirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      isCategory: true
    })
  }


  render() {
    const { id, name } = this.state
    if (this.props.categoryQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      <div>
        {this.state.isCategory ?
          <AddNewCategoryPage goBack={this.goBack} />
          :
          this.state.isCategoryEditing ?
            <EditNewCategoryPage id={id} name={name} goBack={this.goBack} />
            :
            <MUIDataTable
              title={"Categories"}
              data={this.props.categoryQuery.categories && this.props.categoryQuery.categories.map(category => {
                const { name, id, events } = category;
                return [name.toString(), <CustomToolbarSelect
                  handleEdit={() => this.handleEdit(name, id )}
                  handleDelete={() => this.handleConfirmDelete(id)}
                />];
              })}
              columns={columns}
              options={{
                filter: true,
                selectableRows: false,
                filter: false,
                filterType: 'dropdown',
                responsive: 'stacked',
                rowsPerPage: 5,
                customToolbar: () => <CustomAddIcon title="ADD Category" refresh={this.refresh} handler={this.handler} />
              }}
            />
        }
      </div>
    )

  }
}

const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategoryMutation($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`

const CATEGORY_QUERY = gql`
  query CategoryQuery {
    categories {
      id
      name
      events{
       id
       name
       }
    }
  }
`
export default compose(
  graphql(CATEGORY_QUERY, {
    name: 'categoryQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(DELETE_CATEGORY_MUTATION, {
    name: 'deleteCategoryMutation', // name of the injected prop: this.props.deleteCategoryMutation...
  }),
)(Category)

