import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import moment from 'moment'
import { DateTimePicker } from 'material-ui-pickers'

class AddNewTrainPage extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            name: "",
            number: 0,
            source: "",
            destination: "",
            startDate: Date.now(),
            arrivalDate: Date.now(),
            mobile: "",
            transportId: ""
        };
    }

    handleDateChange = (date) => {
        this.setState({ checkOut: date });
    }

    handleValueChange = (_valueAsNumber, valueAsString) => {
        this.setState({ totalRooms: valueAsString });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        const { name, number, source, destination, startDate, arrivalDate, mobile, } = this.state
        return (
            <div className="form-content">
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        ADD Train
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleTrain}>
                            <FormGroup
                                label="Name"
                                labelFor="name-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="name-input" value={name}
                                    required="required"
                                    name="name"
                                    onChange={this.handleChange}
                                    placeholder="Name of Train" />
                            </FormGroup>
                            <FormGroup
                                label="Number"
                                labelFor="number-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="number-input" value={number}
                                    required="required"
                                    name="number"
                                    onChange={this.handleChange}
                                    placeholder="Train Number" />
                            </FormGroup>
                            <FormGroup
                                label="Source"
                                labelFor="source-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="source-input" value={source}
                                    required="required"
                                    name="source"
                                    onChange={this.handleChange}
                                    placeholder="Source" />
                            </FormGroup>
                            <FormGroup
                                label="Destination"
                                labelFor="destination-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="destination-input" value={destination}
                                    required="required"
                                    name="destination"
                                    onChange={this.handleChange}
                                    placeholder="Destination" />
                            </FormGroup>
                            <FormGroup
                                label="Contact Number"
                                labelFor="mobile-input"
                                labelInfo="(required)"
                            >
                                <InputGroup
                                    id="mobile-input"
                                    value={mobile}
                                    name="mobile"
                                    placeholder="Enter a number..."
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup
                                label="Start Date"
                                labelFor="startDate-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={startDate}
                                    onChange={(e) => { this.setState({ startDate: moment(e).toJSON() }) }}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Arrival Date"
                                labelFor="arrivalDate-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={arrivalDate}
                                    onChange={(e) => { this.setState({ arrivalDate: moment(e).toJSON() }) }}
                                />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

    handleTrain = async e => {
        e.preventDefault()
        var Id = await this.props.transportQuery.transports.map(train => {
            if (train.name === "Trains") {
                return train.id
            }
        }).filter(function (element) {
            return element !== undefined;
        }).toString();
        this.setState({
            transportId: Id
        })
        const { name, number, source, destination, startDate, arrivalDate, mobile, transportId } = this.state
        await this.props.createTrainMutation({
            variables: { name, number, source, destination, startDate, arrivalDate, mobile, transportId },
        })
        this.props.goBack();
    }
}

const TRANSPORT_QUERY = gql`
  query TransportQuery {
    transports {
      id
      name
    }
  }
`

const CREATE_TRAIN_MUTATION = gql`
  mutation CreateTrainMutation($name: String!, $number: String!, $source: String!, $destination: String!, $startDate: DateTime!, $arrivalDate: DateTime!, $mobile: String, $transportId: String!) {
    createTransportDetails(name: $name, number: $number,
    source: $source, destination: $destination, startDate: $startDate, 
    arrivalDate: $arrivalDate, mobile: $mobile, transportId: $transportId
     ) {
      id
      name
      number
      source
      destination
      startDate
      arrivalDate
      mobile
      transportId {
        id
      }
    }
  }
`

const CreateTraintWithMutation = compose(
    graphql(TRANSPORT_QUERY, {
        name: 'transportQuery',
        options: {
            fetchPolicy: 'cache-and-network',
        },
    }),
    graphql(CREATE_TRAIN_MUTATION, {
        name: 'createTrainMutation', // name of the injected prop: this.props.createTrainMutation...
    }),
)(AddNewTrainPage)

export default withRouter(CreateTraintWithMutation)