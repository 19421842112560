import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Spinner } from "@blueprintjs/core";
import MaterialTable from 'material-table'
import moment from 'moment'

class DepartureDetails extends Component {

    constructor(props) {
        super(props)
        this.refresh = this.refresh.bind(this)

        this.state = {
            arrivalDetails: []
        }
    }


    refresh() {
        this.props.guestQuery.refetch()
    }

    render() {
        if (this.props.guestQuery.loading) {
            return (
                <div className="flex w-100 h-100 items-center justify-center pt7">
                    <Spinner intent="primary" size={100} />
                </div>
            )
        }

        return (
            <div>
                <MaterialTable
                    columns={[
                        { title: 'Serial No', field: 'serialNo' },
                        { title: 'Guest Name', field: 'fullName' },
                        { title: 'Card Name', field: 'cardName' },
                        { title: 'Spouse Name', field: 'family' },
                        { title: 'Mobile', field: 'mobile' },
                        { title: 'No Of Person', field: 'noOfPersons' },
                        { title: 'City', field: 'city' },
                        { title: 'Email', field: 'email' },
                        { title: 'Group', field: 'group' },
                        { title: 'Sub Group', field: 'subGroup' },
                        { title: 'Category', field: 'category' },
                        { title: 'Print Over', field: 'printOver' },
                        { title: 'Address', field: 'address' },
                        { title: 'Invite Mode', field: 'inviteMode' },
                        { title: 'Guest Called', field: 'isCalled' },
                        { title: 'Guest Informed', field: 'isInformed' },
                        { title: 'Govt ID Type', field: 'govtIDType' },
                        { title: 'Govt ID', field: 'govtID' },
                        { title: 'Status', field: 'status' },
                        { title: 'Travel Type', field: 'travelType' },
                        { title: 'PNR', field: 'pnr' },
                        { title: 'Travel Name', field: 'flightName' },
                        { title: 'Travel Number', field: 'number' },
                        { title: 'Start Date', field: 'startDate', type: 'date' },
                        { title: 'Arrival Date', field: 'arrivalDate', type: 'date' },
                        { title: 'Source', field: 'source' },
                        { title: 'Destination', field: 'destination' },
                        { title: 'Travel Contact', field: 'tmobile' },
                        { title: 'Comments', field: 'comments' },

                    ]}
                    data={this.props.guestQuery.guestDepartureDetails && this.props.guestQuery.guestDepartureDetails.map(gtdetails => {
                        const { pnr, comments, transportDetailsId, guestId } = gtdetails;
                        return {
                            serialNo: guestId.serialNo ? guestId.serialNo : "",
                            fullName: guestId.fullName ? guestId.fullName : "",
                            cardName: guestId.cardName ? guestId.cardName : "",
                            family: guestId.family ? guestId.family.map((item) => item.firstName) : "",
                            mobile: guestId.phone ? guestId.phone : "",
                            noOfPersons: guestId.noOfPersons ? guestId.noOfPersons : "",
                            city: guestId.city ? guestId.city.name : "",
                            email: guestId.email ? guestId.email : "",
                            group: guestId.group ? guestId.group.name : "",
                            subGroup: guestId.subGroup ? guestId.subGroup.name : "",
                            category: guestId.category ? guestId.category.name : "",
                            printOver: guestId.printOver === true ? "Yes" : "No",
                            address: guestId.address ? guestId.address : "",
                            inviteMode: guestId.inviteMode ? guestId.inviteMode : "",
                            isCalled: guestId.isCalled === true ? "Yes" : "No",
                            isInformed: guestId.isInformed === true ? "Yes" : "No",
                            govtIDType: guestId.govtIDType ? guestId.govtIDType.name : "",
                            govtID: guestId.govtID ? guestId.govtID : "",
                            status: guestId.status ? guestId.status.name : "",
                            travelType: transportDetailsId.transportId.name,
                            pnr: pnr ? pnr : "",
                            flightName: transportDetailsId.name ? transportDetailsId.name : "",
                            number: transportDetailsId.number ? transportDetailsId.number : "",
                            startDate: transportDetailsId.startDate ? moment(transportDetailsId.startDate).format("YYYY-MM-DD hh:mm:ss A") : "",
                            arrivalDate: transportDetailsId.arrivalDate ? moment(transportDetailsId.arrivalDate).format("YYYY-MM-DD hh:mm:ss A") : "",
                            source: transportDetailsId.source ? transportDetailsId.source : "",
                            destination: transportDetailsId.destination ? transportDetailsId.destination : "",
                            tmobile: transportDetailsId.mobile ? transportDetailsId.mobile : "",
                            comments: comments ? comments : "",
                        }
                    })}
                    options={{
                        columnsButton: true,
                        exportButton: true,
                        filtering: true,
                        paging: true,
                        pageSize: 10,
                        showEmptyDataSourceMessage: true,
                        pageSizeOptions: [10, 25, 100, 250],
                        showEmptyDataSourceMessage: true
                    }}
                    localization={{
                        emptyDataSourceMessage: 'No records to display'
                    }}
                    title={"Guest Departure Details"}
                />
            </div>
        )
    }
}


const GUEST_QUERY = gql`
  query GuestQuery {
    guestDepartureDetails{
    id
    pnr
    comments
    guestId{
      id
      serialNo
      fullName
      cardName
      phone
      noOfPersons
      address
      email
      printOver
      govtID
      isInvited
      inviteMode
      called
      govtIDType{
        id
        name
      }
      status{
        id
        name
      }
      gender{
        id
        name
      }
      family{
        firstName
        phone
      }
      group{
        id
        name
      }
      subGroup{
        id
        name
      }
      category{
        id
        name
      }
      city{
        id
        name
      }
      gifts{
        id
        name
      }
    }
    gTType{
      id
      name
    }
    transportDetailsId{
      id
      name
      number
      arrivalDate
      startDate
      source
      destination
      mobile
      transportId{
        id
        name
      }
    }
  }
  }
`

export default compose(
    graphql(GUEST_QUERY, {
        name: 'guestQuery',
        options: () => ({
            variables: { orderBy: "updatedAt_DESC" },
            fetchPolicy: 'cache-and-network',
        }),
    }),
)(DepartureDetails)

