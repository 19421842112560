import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Spinner } from "@blueprintjs/core";
import MaterialTable from 'material-table'
import moment from 'moment'

class HotelDetails extends Component {

    constructor(props) {
        super(props)
        this.refresh = this.refresh.bind(this)

        this.state = {
            arrivalDetails: []
        }
    }


    refresh() {
        this.props.guestHotelQuery.refetch()
    }

    render() {
        if (this.props.guestHotelQuery.loading) {
            return (
                <div className="flex w-100 h-100 items-center justify-center pt7">
                    <Spinner intent="primary" size={100} />
                </div>
            )
        }

        return (
            <div>
                <MaterialTable
                    columns={[
                        { title: 'Serial No', field: 'serialNo' },
                        { title: 'Room Type', field: 'roomType' },
                        { title: 'Guest Name', field: 'fullName' },
                        { title: 'Spouse Name', field: 'family' },
                        { title: 'Mobile', field: 'mobile' },
                        { title: 'No Of Person', field: 'noOfPersons' },
                        { title: 'City', field: 'city' },
                        { title: 'Group', field: 'group' },
                        { title: 'Sub Group', field: 'subGroup' },
                        { title: 'Category', field: 'category' },
                        { title: 'Pooling', field: 'pooling' },
                        // { title: 'Govt ID Type', field: 'govtIDType' },
                        // { title: 'Govt ID', field: 'govtID' },
                        { title: 'Hotel', field: 'hotelName' },
                        { title: 'Check In', field: 'checkIn', type: 'date' },
                        { title: 'Check Out', field: 'checkOut', type: 'date' },
                        { title: 'Comments', field: 'comments' },

                    ]}
                    data={this.props.guestHotelQuery.allGuestHotelDetails && this.props.guestHotelQuery.allGuestHotelDetails.map(gtdetails => {
                        const { pnr, comments, roomType, isPooling, hotelRoomId, checkIn, checkOut, guestId } = gtdetails;
                        return {
                            serialNo: guestId.serialNo ? guestId.serialNo : "",
                            roomType: roomType ? roomType : "",
                            fullName: guestId.fullName ? guestId.fullName : "",
                            family: guestId.family ? guestId.family.map((item) => {
                                return item.firstName
                            }
                            ) : "",
                            mobile: guestId.phone ? guestId.phone : "",
                            noOfPersons: guestId.noOfPersons ? guestId.noOfPersons : "",
                            city: guestId.city ? guestId.city.name : "",
                            group: guestId.group ? guestId.group.name : "",
                            subGroup: guestId.subGroup ? guestId.subGroup.name : "",
                            category: guestId.category ? guestId.category.name : "",
                            pooling: isPooling ? "Yes" : "No",
                            // govtIDType: guestId.govtIDType ? guestId.govtIDType.name : "",
                            // govtID: guestId.govtID ? guestId.govtID : "",
                            // status: guestId.status ? guestId.status.name : "",
                            // travelType: transportDetailsId.transportId.name,
                            hotelName: hotelRoomId? hotelRoomId.hotelId.name: "",
                            checkIn: checkIn ? moment(checkIn).format("YYYY-MM-DD hh:mm:ss A") : "",
                            checkOut: checkOut ? moment(checkOut).format("YYYY-MM-DD hh:mm:ss A") : "",
                            comments: comments ? comments : "",
                        }
                    })}
                    options={{
                        columnsButton: true,
                        exportButton: true,
                        filtering: true,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 25, 100, 250],
                        showEmptyDataSourceMessage: true
                    }}
                    localization={{
                        emptyDataSourceMessage: 'No records to display'
                    }}
                    title={"Guest Hotel Details"}
                />
            </div>
        )
    }
}


const GUEST_HOTEL_QUERY = gql`
  query GuestHotelQuery {
    allGuestHotelDetails{
        id
        guestId{ 
            id 
            serialNo
          fullName
          family{ 
              id
              firstName
          }
          noOfPersons
          phone
          group{
              id
              name
          }
          category{
              id
              name
          }
          subGroup{
              id
              name
          }
        }
        hotelRoomId { 
            id
            number
        hotelId{
            id
            name
        }
        }
        roomNumber
        roomType
        isPooling
        checkIn
        checkOut
        roomType
        comments
      }
  }
`

export default compose(
    graphql(GUEST_HOTEL_QUERY, {
        name: 'guestHotelQuery',
        options: () => ({
            fetchPolicy: 'network-only',
        }),
    }),
)(HotelDetails)

