import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import MUIDataTable from "mui-datatables";
import { Spinner } from "@blueprintjs/core";
import CustomAddIcon from '../../constants/CustomAddIcon'
import CustomToolbarSelect from '../../constants/CustomToolbarSelect'
import { confirmAlert } from 'react-confirm-alert'
import AddNewGroupPage from './AddNewGroupPage'
import EditNewGroupPage from './EditNewGroupPage'

const columns = [
  {
    name: "Name",
    key: "name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "Actions",
    key: "action",
    options: {
      filter: false,
      sort: false,
    }
  },
];


class Group extends Component {

  constructor(props) {
    super(props)
    this.handler = this.handler.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
    this.goBack = this.goBack.bind(this)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isGroup: false,
      isGroupEditing: false,
      id: "",
      name: ""
    }
  }

  goBack() {
    this.props.groupQuery.refetch()
    this.setState({
      isGroup: false,
      isGroupEditing: false,
    })
  }

  refresh() {
    this.props.groupQuery.refetch()
  }

  handleEdit(name, id) {
    this.setState({
      isGroupEditing: true,
      id: id,
      name: name,
    })
  }

  async handleDelete(id) {
    await this.props.deleteGroupMutation({
      variables: { id },
    })
    this.props.groupQuery.refetch()
  }

  handleConfirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      isGroup: true
    })
  }

  render() {
    const { id, name } = this.state
    if (this.props.groupQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <Spinner intent="primary" size={100} />
        </div>
      )
    }

    return (
      <div>
      {this.state.isGroup ?
        <AddNewGroupPage goBack={this.goBack} />
        :
        this.state.isGroupEditing ?
          <EditNewGroupPage id={id} name={name} goBack={this.goBack} />
          :
      <MUIDataTable
        title={"Groups"}
        data={this.props.groupQuery.groups && this.props.groupQuery.groups.map(group => {
          const { name, id } = group;
          return [name.toString(), <CustomToolbarSelect
            handleEdit={() => this.handleEdit(name, id)}
            handleDelete={() => this.handleConfirmDelete(id)}
          />];
        })}
        columns={columns}
        options={{
          filter: true,
          selectableRows: false,
          filter: false,
          filterType: 'dropdown',
          responsive: 'stacked',
          rowsPerPage: 5,
          customToolbar: () => <CustomAddIcon title="ADD Group" refresh={this.refresh} handler={this.handler} />
        }}
      />
      }
      </div>
    )
  }
}
const DELETE_GROUP_MUTATION = gql`
  mutation DeleteGroupMutation($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`
const GROUP_QUERY = gql`
  query GroupQuery {
    groups {
      id
      name
    }
  }
`
export default compose(
  graphql(GROUP_QUERY, {
    name: 'groupQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(DELETE_GROUP_MUTATION, {
    name: 'deleteGroupMutation', // name of the injected prop: this.props.deleteGroupMutation...
  }),
)(Group)

