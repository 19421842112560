import React, { Component } from "react"
import { withRouter } from 'react-router-dom'
import { graphql, withApollo, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button, Intent, Classes, FormGroup, InputGroup,
    Card, Elevation, TextArea, NumericInput, Spinner
} from "@blueprintjs/core"
import BackIcon from "@material-ui/icons/ArrowBack"
import moment from 'moment'
import { DateTimePicker } from 'material-ui-pickers'

class AddNewHotelPage extends Component {
    constructor(props) {
        super(props)
        this.handleHotel = this.handleHotel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            name: "",
            id: "",
            checkIn: new Date(),
            checkOut: new Date(),
            totalRooms: 0,
            address: "",
            canSubmit: false,
            email: "",
            sequence: "",
            loading: false,
        };
    }

    handleDateChange = (date) => {
        this.setState({ checkOut: date });
    }

    handleValueChange = (_valueAsNumber, valueAsString) => {
        this.setState({ totalRooms: valueAsString });
    };

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleHotel = async e => {
        e.preventDefault()
        this.setState({
            loading: true
        })
        const { name, checkIn, checkOut, totalRooms, address, email, sequence } = this.state
        console.log("State", this.state)
        await this.props.createHotelMutation({
            variables: { name, checkIn, checkOut, totalRooms, address, email },
        }).then(async ({ data }) => {
            const hotelId = await data.createHotelDetails.id;
            console.log("Hotel ID", + hotelId);
            for (var i = 1; i <= totalRooms; i++) {
                var number = sequence+i
                if (hotelId) {
                    await this.props.createRoomMutation({
                        variables: {
                            hotelId,
                            number
                        },
                    }).then(({ data }) => {
                        const rid = data.createRoom.id;
                        const number = data.createRoom.number;
                        console.log("Room", rid + " " + "Number" + "" + number)
                    }).catch(error => {
                        console.log("error", error)
                    })
                }
            }
        }).catch(error => {
            console.log("error", error)
        })
        this.setState({ loading: false})
        this.props.goBack();
    }

    render() {
        const { name, totalRooms, address, checkOut, checkIn, email, sequence, loading } = this.state
        return (
            <div className="form-content">
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <center>
                    <p className="master-title">
                        ADD Hotel
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleHotel}>
                            <FormGroup
                                label="Name"
                                labelFor="name-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="name-input" value={name}
                                    required="required"
                                    name="name"
                                    onChange={this.handleChange}
                                    placeholder="Name of Hotel" />
                            </FormGroup>
                            <FormGroup
                                label="Email"
                                labelFor="email-input"
                            >
                                <InputGroup id="email-input" value={email}
                                    name="email"
                                    onChange={this.handleChange}
                                    placeholder="Email of Hotel" />
                            </FormGroup>
                            <FormGroup
                                label="Address"
                                labelFor="address-input"
                                labelInfo="(required)"
                            >
                                <TextArea
                                    large={true}
                                    name="address"
                                    intent={Intent.PRIMARY}
                                    onChange={this.handleChange}
                                    value={address}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Rooms"
                                labelFor="room-input"
                                labelInfo="(required)"
                            >
                                <NumericInput
                                    value={totalRooms}
                                    name="totalrooms"
                                    placeholder="Enter a number..."
                                    onValueChange={this.handleValueChange} />
                            </FormGroup>
                            <FormGroup
                                label="Room Sequence"
                                labelFor="rs-input"
                            >
                                <InputGroup id="rs-input" value={sequence}
                                    name="sequence"
                                    required
                                    onChange={this.handleChange}
                                    placeholder="Enter Sequence to generated room numbers" />
                            </FormGroup>
                            
                            <FormGroup
                                label="Check In"
                                labelFor="checkin-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={checkIn}
                                    onChange={(e) => { this.setState({ checkIn: moment(e).toJSON() }) }}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Check Out"
                                labelFor="checkout-input"
                                labelInfo="(required)"
                            >
                                <DateTimePicker
                                    clearable
                                    showTodayButton
                                    value={checkOut}
                                    onChange={(e) => { this.setState({ checkOut: moment(e).toJSON() }) }}
                                />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card >
                </div >
            </div >
        );
    }
}

const CREATE_ROOM_MUTATION = gql`
  mutation CreateRoomMutation($number: String!, $hotelId: ID!) {
    createRoom( hotelId: $hotelId, number: $number) {
      id
      number
      hotelId{
          id
          name
      }
    }
  }
`

const CREATE_HOTEL_MUTATION = gql`
  mutation CreateHotelMutation($name: String!,
    $checkIn: DateTime!, $checkOut: DateTime!, $totalRooms: Int!, $address: String!, $email: String) {
    createHotelDetails( name: $name, checkIn: $checkIn, checkOut: $checkOut, totalRooms: $totalRooms, address: $address, email: $email) {
      id
      name
      checkIn
      checkOut
      totalRooms
      address
      email
    }
  }
`
const CreateHotelWithMutation = compose(
    graphql(CREATE_HOTEL_MUTATION, {
        name: 'createHotelMutation', // name of the injected prop: this.props.createHotelMutation...
    }),
    graphql(CREATE_ROOM_MUTATION, {
        name: 'createRoomMutation', // name of the injected prop: this.props.createRoomMutation...
    }),

)(AddNewHotelPage)

export default withApollo(CreateHotelWithMutation)