import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { graphql, compose } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup, Spinner
} from "@blueprintjs/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirmAlert } from 'react-confirm-alert'
import BackIcon from "@material-ui/icons/ArrowBack";
import Toast from '../../utils/Toast'

class EditNewGroupPage extends Component {

    constructor(props) {
        super(props)
        this.handleEditChange = this.handleEditChange.bind(this)
        this.handleEditSubChange = this.handleEditSubChange.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.resetForm = this.resetForm.bind(this)
        
        this.state = {
            id: "",
            name: this.props.name,
            groupId: this.props.id,
            sid: "",
            subName: "",
            loading: false,
            open: false,
            message: "",
        };
    }

    handleEditChange(event) {
        var name = ""
        name = event.target.value
        this.setState({ name });
    }
    handleEditSubChange(event) {
        var subName = ""
        subName = event.target.value
        this.setState({ subName });
    }

    handleSubGroupEdit = (item) => {
        this.setState({
            groupId: this.props.id,
            id: item.id,
            subName: item.name,
            loading: false,
            open: false,
            message: "",
        })
    }

    resetForm() {
        this.setState({
            id: "",
            subName: "",
            loading: false,
            open: false,
            message: "",
        });
    }
    
    handleConfirmDelete = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.handleDelete(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async handleDelete(id) {
        this.setState({ loading: true })
        await this.props.deleteSubGroup({
            variables: { id },
        })
        const groupId = this.state.groupId
        await this.props.subGroupQuery.refetch({
            groupId
        }).then(async ({ data }) => {
            this.setState({
                loading: false, open: true,
                message: "Sub Group Deleted",
            })
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }

    render() {
        const { open, message, loading, id } = this.state
        return (
            <div className="form-content">
                <Toast open={open} message={message} duration={5000} />
                {(loading) && (<Spinner intent="primary" size={50} />)}
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        Edit Group
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleGroup}>
                            <input type="hidden" value={this.state.id || ''} />
                            <FormGroup
                                label="Name"
                                labelFor="text-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="text-input"
                                    value={this.state.name || ''}
                                    required="required"
                                    onChange={this.handleEditChange}
                                    placeholder="Name of group" />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Update
                            </Button>
                                </div>
                            </div>
                        </form>
                        <hr />
                        <center><h4>{id ? "Edit" : "Add"} Sub Group</h4></center>
                        {
                            this.props.subGroupQuery.subGroups &&  this.props.subGroupQuery.subGroups.map((item) => {
                                return (
                                    <ul className="bp3-list-unstyled">
                                        <li key={item.id}>
                                            <div className="families"> {item.name} 
                                                    <IconButton className="action-icons" onClick={() => this.handleSubGroupEdit(item)}>
                                                        <EditIcon  color="primary" />
                                                    </IconButton>
                                                    <IconButton className="action-icons"   onClick={() => this.handleConfirmDelete(item.id)}>
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                        
                        <form onSubmit={this.handleSubGroup}>
                            <InputGroup type="hidden" id="id-input" value={id}
                                name="id" />
                            <FormGroup
                                label="Sub Group Name"
                                labelFor="sub-input"
                            >
                                <InputGroup id="sub-input" value={this.state.subName}
                                    required="required"
                                    onChange={this.handleEditSubChange}
                                    placeholder="Name of Sub group" />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="button" onClick={this.resetForm} intent={Intent.WARNING} >
                                        Reset
                                                     </Button>
                                    {id ? <Button type="submit" intent={Intent.PRIMARY} >
                                        Update
                                                     </Button> :
                                        <Button type="submit" intent={Intent.PRIMARY} >
                                            ADD
                                                     </Button>
                                    }
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

    handleGroup = async e => {
        e.preventDefault()
        const { groupId, name } = this.state
        await this.props.editGroupMutation({
            variables: { id: groupId, name },
        }).then(async ({ data }) => {
            this.setState({
                loading: false, open: true,
                message: data.updateGroup.name + " Updated",
            })
            await this.props.subGroupQuery.refetch({
                groupId
            });
        }).catch(error => {
            this.setState({
                open: true,
                message: "Error occured " + error,
                loading: false
            })
        })
        await setTimeout(() => this.setState({ open: false }), 6000);
    }

    handleSubGroup = async e => {
        e.preventDefault()
        const { subName, groupId, id } = this.state
        this.setState({ loading: true })
        if (id) {
            await this.props.editSubGroupMutation({
                variables: { id, name: subName },
            }).then(async ({ data }) => {
                this.setState({
                    loading: false, open: true,
                    message: data.updateSubGroup.name + " Updated",
                })
                await this.props.subGroupQuery.refetch({
                    groupId
                });
                this.resetForm();
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 6000);
        } else {
            await this.props.createSubGroupMutation({
                variables: { name: subName, groupId },
            }).then(async ({ data }) => {
                this.setState({
                    loading: false, open: true,
                    message: data.createSubGroup.name + " " + " Added",
                })
                await this.props.subGroupQuery.refetch({
                    groupId
                });
                this.resetForm();
            }).catch(error => {
                this.setState({
                    open: true,
                    message: "Error occured " + error,
                    loading: false
                })
            })
            await setTimeout(() => this.setState({ open: false }), 6000);
        }
    }
}

const DELETE_SUB_GROUP = gql`
  mutation DeleteSubGroup($id: ID!) {
       deleteSubGroup(id: $id) {
        id
    }
}
`

const SUBGROUP_QUERY = gql`
  query SubGroupQuery($groupId: ID!) {
    subGroups(groupId: $groupId) {
      id
      name
    }
  }
`

const CREATE_SUBGROUP_MUTATION = gql`
  mutation CreateSUbGroupMutation($name: String!, $groupId: ID!) {
    createSubGroup(name: $name, groupId: $groupId) {
      id
      name
    }
  }
`
const EDIT_GROUP_MUTATION = gql`
  mutation EditGroupMutation($id: ID!, $name: String!) {
    updateGroup(id: $id, name: $name) {
      id
      name
    }
  }
`

const EDIT_SUB_GROUP_MUTATION = gql`
  mutation EditSubGroupMutation($id: ID!, $name: String!) {
    updateSubGroup(id: $id, name: $name) {
      id
      name
    }
  }
`

const EditGroupWithMutation = compose(
    graphql(EDIT_GROUP_MUTATION, {
        name: 'editGroupMutation', // name of the injected prop: this.props.editGroupMutation...
    }),
    graphql(EDIT_SUB_GROUP_MUTATION, {
        name: 'editSubGroupMutation', // name of the injected prop: this.props.editSubGroupMutation...
    }),
    graphql(CREATE_SUBGROUP_MUTATION, {
        name: 'createSubGroupMutation',
        // name of the injected prop: this.props.createGroupMutation...
    }),
    graphql(DELETE_SUB_GROUP, {
        name: 'deleteSubGroup', // name of the injected prop: this.props.deleteFamilyMutation...
        options: {
            refetchQueries: ['subGroupQuery']
        }
    }),
    graphql(SUBGROUP_QUERY, {
        name: 'subGroupQuery',
        options: (props) => ({
            variables: {
                groupId: props.id
            },
            fetchPolicy: 'cache-and-network',
        }),
    }),
)(EditNewGroupPage)

export default withRouter(EditGroupWithMutation)