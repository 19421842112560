import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import { graphql, compose, withApollo } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Elevation, Blockquote } from "@blueprintjs/core";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import LogRocket from 'logrocket';
import _ from 'lodash'

const styles = {
  card: {
    minWidth: 250,
    maxWidth: 300,
    display: 'inline-block',
    margin: '20px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontSize: '45px',
    fontWeight: 'bold',
  },
};


class Dashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      arrivalDetails: []
    }
  }
  async componentDidMount() {
    const userDetails = await this.props.client.query({
      query: USER,
      options: {
        fetchPolicy: 'network-only',
      }
    })
    var user = userDetails.data.me
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,

      // Add your own custom user variables here, ie:
      subscriptionType: 'pro'
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <h1>Dashboard</h1>
        <div>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="primary" gutterBottom>
                Total Guests
            </Typography>
              <Typography className={classes.pos} color="primary">
                {this.props.guestQuery.guests && (this.props.guestQuery.guests.map((item) => {
                  return item
                }).length)}
              </Typography>
            </CardContent>
            <CardActions>
              <NavLink to="/guest"><Button size="small">Add More Guests</Button></NavLink>
            </CardActions>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="primary" className={classes.title} gutterBottom>
                Arriving Guests
            </Typography>
              <Typography className={classes.pos} color="primary">
                {(this.props.guestQuery.guests && (this.props.guestQuery.guests.map((item) => {
                  if (item.status.name === "Yes") {
                    return item.id
                  }
                }).filter(function (e) { return e }).length))}
              </Typography>
            </CardContent>
            <CardActions>
              <NavLink to="/guest"><Button size="small">Invite More Guests</Button></NavLink>
            </CardActions>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="primary" className={classes.title} gutterBottom>
                Guest Informed
            </Typography>
              <Typography className={classes.pos} color="primary">
                {(this.props.guestQuery.guests && (this.props.guestQuery.guests.map((item) => {
                  if (item.isInformed === true) {
                    return item.id
                  }
                }).filter(function (e) { return e }).length))}

              </Typography>
            </CardContent>
            <CardActions>
              <NavLink to="/guest"> <Button size="small">Notify Guests</Button></NavLink>
            </CardActions>
          </Card>
          {/* <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Total Vehicles
            </Typography>
              <Typography className={classes.pos} color="textSecondary">
                500
            </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Check Status</Button>
            </CardActions>
          </Card> */}
        </div>
      </Fragment>
    )
  }
}


const AllGuestList = gql`
   query GuestQuery($orderBy: GuestOrderByInput) {
    guests(orderBy: $orderBy) {
        id,
       isInformed, 
       status{
           id
           name
       }, 
    }
  }
`

const USER = gql`
   query user{
    me{ 
      id
      name
      email
  }
}`


const DashboardStyles = withStyles(styles)(Dashboard);

const DashboardMutation = compose(
  graphql(AllGuestList, {
    name: 'guestQuery',
    variables: { orderBy: "createdAt_DESC" },
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
  graphql(USER, {
    name: 'userQuery',
    options: {
      fetchPolicy: 'cache-and-network',
    },
  }),
)(DashboardStyles)

export default withApollo(DashboardMutation);
