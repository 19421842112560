import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import {
    Button,
    Classes, Intent, Card, Elevation,
    FormGroup, InputGroup
} from "@blueprintjs/core";
import BackIcon from "@material-ui/icons/ArrowBack";

class AddNewTransportPage extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            name: "",
        };
    }
    handleChange(event) {
        this.setState({ name: event.target.value });
    }
    render() {
        return (
            <div className="form-content">
                <BackIcon className="back-icon" onClick={this.props.goBack} />
                <center>
                    <p className="master-title">
                        ADD Transport
                    </p>
                </center>
                <div>
                    <Card interactive={true} className="card-global" elevation={Elevation.TWO}>
                        <form onSubmit={this.handleTransport}>
                            <FormGroup
                                label="Name"
                                labelFor="text-input"
                                labelInfo="(required)"
                            >
                                <InputGroup id="text-input" value={this.state.name}
                                    required="required"
                                    onChange={this.handleChange}
                                    placeholder="Name of transport" />
                            </FormGroup>
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button type="submit" intent={Intent.PRIMARY} >
                                        Save
                            </Button>
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
            </div>
        );
    }

    handleTransport = async e => {
        e.preventDefault()
        const { name } = this.state
        console.log("Name", this.state.name)
        await this.props.createTransportMutation({
            variables: { name },
        })
        this.props.goBack();
    }
}

const CREATE_TRANSPORT_MUTATION = gql`
  mutation CreateTransportMutation($name: String!) {
    createTransport(name: $name) {
      id
      name
    }
  }
`
const CreateTransportWithMutation = graphql(CREATE_TRANSPORT_MUTATION, {
    name: 'createTransportMutation', // name of the injected prop: this.props.createTransportMutation...
})(AddNewTransportPage)

export default withRouter(CreateTransportWithMutation)